import { TextLink } from '@energiebespaarders/symbols';
import { Color, FontSize, Right } from '@energiebespaarders/symbols/helpers';
import { AnswerType, OmniformQuestion, QuestionAnswerType } from '~/hooks/omniform/types';
import { WithFormState } from '~/hooks/omniform/useOmniform';
import { IntakeTextVariables, QKeyGeneral } from '../CustomerIntake/types';

const questionWithHelpers = [
  QKeyGeneral.HouseGasConsumption,
  QKeyGeneral.HouseElectricityConsumption,
  QKeyGeneral.HouseGasConsumptionMonthlyCost,
  QKeyGeneral.HouseElectricityConsumptionMonthlyCost,
] as string[];

export const renderInputHelper = (
  question: WithFormState<OmniformQuestion>,
  onAnswer: (
    questionKey: string,
    answerType: QuestionAnswerType.Number,
    answer: AnswerType<QuestionAnswerType.Number>,
    skipNetwork?: boolean,
  ) => void,
  textVariables: IntakeTextVariables,
) => {
  if (!questionWithHelpers.includes(question.key)) return <></>;

  let handleClick: () => void = () => null;
  let helperText = '';
  const helperId = `${question.key}-helper`;

  switch (question.key) {
    case QKeyGeneral.HouseGasConsumption:
      if (!textVariables.gasConsumption) break;
      helperText = 'Inschatting overnemen';
      handleClick = () =>
        onAnswer(
          question.key,
          question.answerType as QuestionAnswerType.Number,
          textVariables.gasConsumption!,
        );
      break;
    case QKeyGeneral.HouseElectricityConsumption:
      if (!textVariables.electricityConsumption) break;
      helperText = 'Inschatting overnemen';
      handleClick = () =>
        onAnswer(
          question.key,
          question.answerType as QuestionAnswerType.Number,
          textVariables.electricityConsumption!,
        );
      break;
    case QKeyGeneral.HouseGasConsumptionMonthlyCost:
      if (!textVariables.gasConsumptionMonthlyCost) break;
      helperText = 'Inschatting overnemen';
      handleClick = () =>
        onAnswer(
          question.key,
          question.answerType as QuestionAnswerType.Number,
          textVariables.gasConsumptionMonthlyCost!,
        );
      break;
    case QKeyGeneral.HouseElectricityConsumptionMonthlyCost:
      if (!textVariables.electricityConsumptionMonthlyCost) break;
      helperText = 'Inschatting overnemen';
      handleClick = () =>
        onAnswer(
          question.key,
          question.answerType as QuestionAnswerType.Number,
          textVariables.electricityConsumptionMonthlyCost!,
        );
      break;
  }

  return (
    <FontSize size={7}>
      <Right block>
        <Color c="grayDark">
          <TextLink onClick={handleClick} id={helperId}>
            <u>{helperText}</u>
          </TextLink>
        </Color>
      </Right>
    </FontSize>
  );
};
