import { findOmniformResponse_findOmniformResponse } from '~/types/generated/findOmniformResponse';
import { submitOmniformResponse_submitOmniformResponse } from '~/types/generated/submitOmniformResponse';
import {
  getOmniform_getOmniform,
  getOmniform_getOmniform_flow,
  getOmniform_getOmniform_questions,
  getOmniform_getOmniform_questions_options,
} from '../../types/generated/getOmniform';
import {
  saveOmniformResponse_saveOmniformResponse,
  saveOmniformResponse_saveOmniformResponse_answers,
} from '../../types/generated/saveOmniformResponse';
import {
  startOmniformResponse_startOmniformResponse,
  startOmniformResponse_startOmniformResponse_answers,
  startOmniformResponse_startOmniformResponse_responder,
} from '../../types/generated/startOmniformResponse';
import { UserType } from '../../types/graphql-global-types';
import { OmniformFlow } from './flows/omniform-flow';
import {
  InputOption,
  Omniform,
  OmniformAnswer,
  OmniformQuestion,
  OmniformResponse,
  QuestionAnswerType,
} from './types';

const ResponderUserType: Record<
  startOmniformResponse_startOmniformResponse_responder['__typename'],
  UserType
> = {
  Lead: UserType.lead,
  Customer: UserType.customer,
  Operator: UserType.operator,
  InstallerAgent: UserType.installerAgent,
  PartnerAgent: UserType.partnerAgent,
} as const;

export function decodeUserType(
  typename: startOmniformResponse_startOmniformResponse_responder['__typename'],
): UserType {
  return ResponderUserType[typename];
}

export function decodeQuestionOption(
  option: getOmniform_getOmniform_questions_options,
): InputOption<QuestionAnswerType> {
  const res = { label: option.label };
  switch (option.__typename) {
    case 'BooleanOption':
      return { ...res, value: option.booleanValue };
    case 'DateOption':
      return { ...res, value: option.dateValue };
    case 'FloatOption':
      return { ...res, value: option.floatValue };
    case 'StringOption':
      return { ...res, value: option.stringValue };
  }
}

export function decodeQuestion(question: getOmniform_getOmniform_questions): OmniformQuestion {
  return {
    ...question,
    options: question.options?.map(o => decodeQuestionOption(o)),
    answerType: question.answerType as unknown as QuestionAnswerType,
  };
}

export function decodeOmniformFlow<TFlowData extends Record<string, any>>(
  flow: getOmniform_getOmniform_flow | null,
): OmniformFlow<TFlowData> | undefined {
  if (!flow) return undefined;
  return new OmniformFlow({
    nodes: [
      ...flow.nodes.map(node => ({
        ...node,
        data: node.data.__typename === 'OmniformStatement' ? node.data : decodeQuestion(node.data),
      })),
    ],
    edges: [...flow.edges],
  });
}

export function decodeOmniform<TFlowData extends Record<string, any>>(
  form: getOmniform_getOmniform | undefined | null,
): Omniform<OmniformFlow<TFlowData>> | undefined {
  if (!form) return undefined;
  return {
    ...form,
    questions: form.questions.map(decodeQuestion),
    flow: decodeOmniformFlow<TFlowData>(form.flow),
  };
}

export function decodeOmniformAnswer(
  answer:
    | startOmniformResponse_startOmniformResponse_answers
    | saveOmniformResponse_saveOmniformResponse_answers,
): OmniformAnswer {
  switch (answer.__typename) {
    case 'OmniformBooleanAnswer':
      return { ...answer, answer: answer.booleanAnswer };
    case 'OmniformDateAnswer':
      return { ...answer, answer: answer.dateAnswer };
    case 'OmniformNumericAnswer':
      return { ...answer, answer: answer.numericAnswer };
    case 'OmniformStringAnswer':
      return { ...answer, answer: answer.textAnswer };
    case 'OmniformBooleanArrayAnswer':
      return { ...answer, answer: [...(answer.booleanArrayAnswer ?? [])] };
    case 'OmniformDateArrayAnswer':
      return { ...answer, answer: [...(answer.dateArrayAnswer ?? [])] };
    case 'OmniformNumericArrayAnswer':
      return { ...answer, answer: [...(answer.numericArrayAnswer ?? [])] };
    case 'OmniformStringArrayAnswer':
      return { ...answer, answer: [...(answer.stringArrayAnswer ?? [])] };
  }
}

export function decodeOmniformResponse(
  response:
    | startOmniformResponse_startOmniformResponse
    | saveOmniformResponse_saveOmniformResponse
    | findOmniformResponse_findOmniformResponse
    | submitOmniformResponse_submitOmniformResponse
    | undefined
    | null,
): OmniformResponse | undefined {
  if (!response) return undefined;
  return { ...response, answers: response.answers.map(decodeOmniformAnswer) };
}
