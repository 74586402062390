import { gql, useQuery } from '@apollo/client';
import { Box, Container, Spinner } from '@energiebespaarders/symbols';
import { Center } from '@energiebespaarders/symbols/helpers';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useKeyPress, useLocalStorage } from 'react-use';
import config from '~/config';
import { AnswerType, QuestionAnswerType } from '~/hooks/omniform/types';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import useGAEvent from '~/hooks/useGAEvent';
import { useMe } from '~/hooks/useMe';
import { usePersonalEnergyPrices } from '~/hooks/usePersonalEnergyPrices';
import {
  getSelfIntakeHouseData,
  getSelfIntakeHouseDataVariables,
} from '~/types/generated/getSelfIntakeHouseData';
import { me_me_Customer, me_me_Lead } from '~/types/generated/me';
import { roundAmount } from '~/utils/rounding';
import StyledBackgroundCard, { randomNumber } from '../Omniform/BackgroundCard';
import Omniform from '../Omniform/Omniform';
import { OmniformSharedProps } from '../Omniform/OmniformContent';
import PageLoading from '../PageLoading';
import SelfServiceIntakeDevTools from '../SelfServiceIntake/SelfServiceIntakeDevTools';
import { CustomerIntakeInfoContext } from './CustomerIntakeInfoContext';
import IntakeAuthCard from './IntakeAuthCard';
import { IntakeExitContext } from './IntakeExitContext';
import { IntakeTextVariablesProvider } from './IntakeTextVariablesContext';
import WelcomeCard from './IntakeWelcomeCard';
import YourHomeCard from './YourHomeCard';
import { IntakeFlowData, IntakeTextVariables, QKeyCustomerIntake, QKeyGeneral } from './types';
import { determineIntakeExitScenario } from './useExitScenarios';

const GET_SELF_INTAKE_HOUSE_DATA = gql`
  query getSelfIntakeHouseData($houseId: ID!) {
    house(id: $houseId) {
      id
      constructionYear
      type
      area
      walls {
        cavity
      }
      crawlspace {
        compartments
      }
      energy {
        gasConsumption
        electricityConsumption
        electricityProduction
      }
      situation {
        id
        consumption {
          gas
          electricity
        }
      }
    }
  }
`;

type IntroState = 'welkom' | 'woning' | 'login' | 'start';

interface CustomerIntakeControllerProps {
  formStyle?: OmniformSharedProps<any>['formStyle'];
  submitText?: string;
  textVariables?: Record<string, string>;
}

/** This component handles all the logic for fetching and controlling the data, cards and Omniforms required for the self-intake flow */
const CustomerIntakeController: React.FC<CustomerIntakeControllerProps> = ({
  formStyle,
  submitText,
  textVariables: initialTextVariables,
}) => {
  const { me } = useMe<me_me_Lead | me_me_Customer>();
  const router = useRouter();
  const { personalEnergyPrices } = usePersonalEnergyPrices();
  const sendGAEvent = useGAEvent();
  const [cmdPressed] = useKeyPress('Meta');

  const [textVariables, setTextVariables] = useState<IntakeTextVariables>(
    initialTextVariables ?? {},
  );
  const [flowData, setFlowData] = useState<IntakeFlowData>({
    answers: {} as Record<string, unknown>,
    constructionYear: 0,
    houseType: 0,
    houseId: '',
  });
  const [shouldUpdateFlowData, setShouldUpdateFlowData] = useState(true);

  const getVariablesFromHouse = useCallback(
    (houseData: getSelfIntakeHouseData) => {
      const {
        constructionYear,
        situation: { consumption },
        energy,
      } = houseData.house;
      const gasConsumption = roundAmount(consumption.gas ?? energy.gasConsumption, 100);
      const electricityConsumption = roundAmount(
        consumption.electricity ?? energy.electricityConsumption - energy.electricityProduction,
        100,
      );
      const gasConsumptionMonthlyCost = roundAmount(
        (gasConsumption * personalEnergyPrices.gas) / 12,
        1,
      );
      const electricityConsumptionMonthlyCost = roundAmount(
        (electricityConsumption * personalEnergyPrices.electricity) / 12,
        1,
      );

      return {
        constructionYear,
        gasConsumption,
        electricityConsumption,
        gasConsumptionMonthlyCost,
        electricityConsumptionMonthlyCost,
      };
    },
    [personalEnergyPrices.electricity, personalEnergyPrices.gas],
  );

  const { activeHouseId } = useActiveHouseId();
  const { data: houseData, loading: houseLoading } = useQuery<
    getSelfIntakeHouseData,
    getSelfIntakeHouseDataVariables
  >(GET_SELF_INTAKE_HOUSE_DATA, {
    skip: !activeHouseId,
    variables: { houseId: activeHouseId },
    onCompleted: data => {
      setTextVariables(prev => ({ ...prev, ...getVariablesFromHouse(data) }));
      setShouldUpdateFlowData(true);
    },
  });

  const handleUpdateTextVariables = (
    questionKey: string,
    answer: AnswerType<QuestionAnswerType>,
  ) => {
    if (questionKey === QKeyGeneral.HouseGasConsumption) {
      const gasConsumptionMonthlyCost = roundAmount(
        ((answer as number) * personalEnergyPrices.gas) / 12,
        1,
      );
      setTextVariables({ ...textVariables, gasConsumptionMonthlyCost });
    }
    if (questionKey === QKeyGeneral.HouseElectricityConsumption) {
      const electricityConsumptionMonthlyCost = roundAmount(
        ((answer as number) * personalEnergyPrices.electricity) / 12,
        1,
      );
      setTextVariables({ ...textVariables, electricityConsumptionMonthlyCost });
    }
  };

  const getUpdatedFlowData = (
    currentFlowData: IntakeFlowData,
    questionKey: string,
    answer: AnswerType<QuestionAnswerType>,
  ) => ({
    ...currentFlowData,
    answers: { ...currentFlowData.answers, [questionKey]: answer },
  });

  useEffect(() => {
    if (houseData && shouldUpdateFlowData) {
      const { constructionYear } = getVariablesFromHouse(houseData);
      setFlowData({
        answers: {} as Record<string, unknown>,
        constructionYear,
        houseType: houseData.house.type,
        houseId: activeHouseId,
      });
      setShouldUpdateFlowData(false);
    }
  }, [activeHouseId, getVariablesFromHouse, houseData, shouldUpdateFlowData]);

  // INTAKE INTRO CARDS
  const [customerIntakeInfo] = useContext(CustomerIntakeInfoContext);
  const [introState, setIntroState] = useState<IntroState>('welkom');
  useEffect(() => {
    if (!activeHouseId) return;
    // Set the intro state based on the hash in the URL on load
    const hash = window.location.hash;
    if (introState !== 'woning' && hash === '#woning') setIntroState('woning');
    // Also set the intro state to login if the user is already logged in, the IntakeAuthCard will handle the rest
    if (introState !== 'login' && (hash === '#login' || hash === '#start')) setIntroState('login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [addressLoaded] = useLocalStorage<boolean>('addressLoaded', false);
  const [addressPreloaded, setAddressPreloaded] = useState(
    !!customerIntakeInfo?.submittedOn || !!addressLoaded,
  );
  const [, setExitScenario] = useContext(IntakeExitContext);

  const goToWelcome = useCallback(() => {
    setIntroState('welkom');
    window.location.hash = '';
  }, []);
  const goToHomeCard = useCallback(() => {
    setIntroState('woning');
    window.location.hash = '#woning';
  }, []);
  const goToAuth = useCallback(() => {
    setIntroState('login');
    window.location.hash = '#login';
  }, []);
  const goToQuestions = useCallback(() => {
    setIntroState('start');
    window.location.hash = '';
  }, []);

  const handleNext = useCallback(
    (answers: Partial<Record<QKeyCustomerIntake, any>>) => {
      const exit = determineIntakeExitScenario(answers, flowData.constructionYear || 0);
      setExitScenario(exit);
    },
    [flowData.constructionYear, setExitScenario],
  );

  // We only want to send the events once (per session), so we store that in local storage
  const [beginEventSent, setBeginEventSent] = useLocalStorage('intakeBeginEventSent', false);
  const [completeEventSent, setCompleteEventSent] = useLocalStorage(
    'intakeCompleteEventSent',
    false,
  );
  const sendIntakeBeginEvent = useCallback(() => {
    void sendGAEvent('intake_begin', { house_id: activeHouseId, method: 'omniform' });
    setBeginEventSent(true);
  }, [activeHouseId, sendGAEvent, setBeginEventSent]);

  const sendIntakeCompleteEvent = useCallback(() => {
    if (completeEventSent) return;
    void sendGAEvent('intake_complete', { house_id: activeHouseId, method: 'omniform' });
    setCompleteEventSent(true);
  }, [activeHouseId, completeEventSent, sendGAEvent, setCompleteEventSent]);

  useEffect(() => {
    if (me?.__typename !== 'Customer') return;
    if (!addressPreloaded) setAddressPreloaded(true);
    if (!beginEventSent) sendIntakeBeginEvent();
  }, [addressPreloaded, beginEventSent, introState, me?.__typename, sendIntakeBeginEvent]);

  if (activeHouseId && (!houseData?.house || houseLoading)) return <PageLoading />;

  const cardOrder: IntroState[] = ['welkom', 'woning', 'login', 'start'];

  return (
    <IntakeTextVariablesProvider textVariables={textVariables}>
      <Box style={{ position: 'relative' }} mb={8}>
        {introState === 'welkom' || !me ? (
          <WelcomeCard onNext={goToHomeCard} />
        ) : introState === 'woning' ? (
          <YourHomeCard
            onPrev={goToWelcome}
            onNext={goToAuth}
            hideLoading={addressPreloaded || !!addressLoaded}
          />
        ) : introState === 'login' ? (
          <IntakeAuthCard onPrev={goToHomeCard} onLoginSuccess={goToQuestions} />
        ) : introState === 'start' ? (
          <Omniform<IntakeFlowData>
            formKey="self-intake-complete"
            onSubmit={sendIntakeCompleteEvent}
            submitText={submitText || 'Voltooien'}
            formStyle={formStyle || 'questionCards'}
            initialFlowData={flowData}
            updateFlowData={getUpdatedFlowData}
            textVariables={textVariables}
            onAnswer={handleUpdateTextVariables}
            onPrevious={() => setExitScenario(undefined)}
            onPreviousFromStart={goToHomeCard}
            onNext={handleNext}
          />
        ) : (
          <Center block>
            <Spinner />
          </Center>
        )}
        {introState !== 'start' && (
          <>
            <StyledBackgroundCard
              key={`${introState}-bg-1`}
              bgColor="rgb(250, 250, 250)"
              $depth={1}
              animation="none"
              $randomizer={
                (randomNumber(1) > 0.5 ? 1 : -1) * randomNumber(cardOrder.indexOf(introState))
              }
            />
            <StyledBackgroundCard
              key={`${introState}-bg-2`}
              bgColor="rgb(250, 250, 250)"
              $depth={2}
              animation="none"
              $randomizer={
                (randomNumber(1) > 0.5 ? 1 : -1) * randomNumber(cardOrder.indexOf(introState))
              }
            />
            <StyledBackgroundCard
              key={`${introState}-bg-3`}
              bgColor="rgb(250, 250, 250)"
              $depth={3}
              animation="none"
              $randomizer={
                (randomNumber(1) > 0.5 ? 1 : -1) * randomNumber(cardOrder.indexOf(introState))
              }
            />
          </>
        )}
      </Box>
      {!config.isProduction && cmdPressed && me.__typename === 'Customer' && (
        <Container size="sm" pt={5}>
          <SelfServiceIntakeDevTools onSubmit={() => router.push('/intake/indicaties')} />
        </Container>
      )}
    </IntakeTextVariablesProvider>
  );
};

export default CustomerIntakeController;
