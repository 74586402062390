import { useQuery } from '@apollo/client';
import { HOUSE_TYPES_NL } from '@energiebespaarders/constants';
import { useIsMobile } from '@energiebespaarders/hooks';
import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  Icon,
  Image,
  Placeholder,
  TextLink,
  theme,
} from '@energiebespaarders/symbols';
import { Bold, Center, Heading, Large, Small } from '@energiebespaarders/symbols/helpers';
import { CheckCircle, Pencil, Spinner } from '@energiebespaarders/symbols/icons/solid';
import {
  BuildingApartment,
  Certificate,
  ClockCounterClockwise,
  Crop,
  House,
} from '@phosphor-icons/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useInterval, useKeyPressEvent, useLocalStorage } from 'react-use';
import { useIntercom } from 'react-use-intercom';
import config from '~/config';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import { ADDRESS_CARD } from '~/queries/house';
import {
  addressCard,
  addressCardVariables,
  addressCard_house,
  addressCard_house_address,
} from '~/types/generated/addressCard';
import { getMapsCenter } from '~/utils/maps';
import RVOLogo from 'public/img/logos/RVO.svg';
import KadasterLogo from 'public/img/logos/kadaster.svg';
import AHNLogo from 'public/img/logos/ahn.svg';
import OmniformCard, { OmniformPrevArrow } from '../Omniform/OmniformCard';
import AddressInitForm from '../RequestPackage/AddressInitForm';
import UpdateHousePropertiesForm from './UpdateHousePropertiesForm';

const NoAddressYet: React.FC = () => {
  const isMobile = useIsMobile();
  const [selectedHouseId, setSelectedHouseId] = useState('');
  const { activeHouseId, setActiveHouseId } = useActiveHouseId();

  useEffect(() => {
    if (activeHouseId !== selectedHouseId) setActiveHouseId(selectedHouseId);
  }, [activeHouseId, selectedHouseId, setActiveHouseId]);

  return (
    <Flex flexWrap="wrap" alignItems="center" p={isMobile ? 4 : 8}>
      <Box width={1} textAlign="center">
        <Heading heading="h2" style={{ lineHeight: 1 }}>
          Vul je adres in om te beginnen
        </Heading>
      </Box>
      <Box width={1}>
        <Container size="md">
          <AddressInitForm
            selectedHouseId={selectedHouseId}
            setSelectedHouseId={setSelectedHouseId}
            buttonBgColor="yellow"
            buttonColor="grayBlack"
            // buttonIcon
          />
        </Container>
      </Box>
    </Flex>
  );
};

const AddressBox: React.FC<{ address?: addressCard_house_address }> = ({ address }) => {
  const { number, suffix, street, zip, city } = address || {};
  return address ? (
    <Card
      style={{ display: 'inline-block' }}
      bgColor="grayLighter"
      borderColor="grayLight"
      animation="none"
      shadow="none"
      mt={3}
      px={5}
    >
      <div>
        {street} {number} {suffix}
      </div>
      <div>
        {zip} {city}
      </div>
    </Card>
  ) : (
    <Placeholder height="40px" />
  );
};

interface LoadingHouseDataProps {
  address?: addressCard_house_address;
  dataLoading: boolean;
  onFinish: () => void;
}

const steps = [
  { text: 'Adres', image: KadasterLogo, alt: 'Kadaster' },
  { text: 'Woningtype', image: KadasterLogo, alt: 'Kadaster' },
  { text: 'Bouwjaar', image: RVOLogo, alt: 'RVO' },
  { text: 'Energielabel', image: RVOLogo, alt: 'RVO' },
  { text: 'Oppervlaktes', image: AHNLogo, alt: 'AHN' },
];

const LoadingHouseData: React.FC<LoadingHouseDataProps> = ({ address, dataLoading, onFinish }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const interval = config.isDeveloping ? 250 : 1000;
  const [countdown, setCountdown] = useState<number>(interval);
  const decrementCountdown = () => setCountdown(Math.max(0, countdown - interval));
  useInterval(decrementCountdown, interval);

  useEffect(() => {
    if (countdown === 0) {
      if (currentStep > steps.length && !dataLoading) onFinish();
      else if (currentStep <= steps.length) {
        setCurrentStep(currentStep + 1);
        setCountdown(interval);
      }
    }
  }, [countdown, dataLoading, onFinish, currentStep, interval]);

  return (
    <Center block>
      <Box width={1} px={1} mb={5}>
        <Card key={steps[Math.min(steps.length - 1, currentStep - 1)].alt} shadow="none">
          <Image
            src={steps[Math.min(steps.length - 1, currentStep - 1)].image}
            alt={steps[Math.min(steps.length - 1, currentStep - 1)].alt}
            style={{ width: '100%', height: '120px' }}
          />
        </Card>
      </Box>

      <Heading heading="h2" fontSize={4}>
        Gegevens over de woning ophalen...
      </Heading>

      {steps.map((step, index) => (
        <Flex key={`house-data-step-${index}`} flexWrap="wrap" justifyContent="center" mx={-1}>
          <Box width="2em" px={1}>
            <Icon
              icon={currentStep <= index + 1 ? Spinner : CheckCircle}
              animation={currentStep <= index + 1 ? 'spin' : 'none'}
              fill="green"
              style={{
                animationIterationCount: 'infinite',
                animationTimingFunction: 'linear',
                animationDuration: '1s',
              }}
            />
          </Box>

          <Box px={1} mb={1}>
            {step.text} {currentStep === index + 1 ? 'checken...' : 'gecheckt'}
          </Box>
        </Flex>
      ))}

      <AddressBox address={address} />
    </Center>
  );
};

interface YourHomeContentProps {
  house: addressCard_house;
  onNext: () => void;
}

const YourHomeContent: React.FC<YourHomeContentProps> = ({ house, onNext }) => {
  const isMobile = useIsMobile();
  const { setActiveHouseId } = useActiveHouseId();
  const [isEditingHouseProps, setIsEditingHouseProps] = useState(false);

  const isNewFunnel = house.requestedDuties.length === 0;

  useKeyPressEvent('ArrowRight', isEditingHouseProps ? null : onNext);
  useKeyPressEvent('Enter', isEditingHouseProps ? null : onNext);

  const { address, type, area, constructionYear, labelRegistration, vve } = house;
  const { number, suffix, street, zip, city } = address;
  const inlineAddress = `${street} ${number}${suffix ? ` ${suffix}` : ''}, ${zip} ${city}`;
  const mapsCenter = getMapsCenter(address);
  const streetviewURL = `https://maps.googleapis.com/maps/api/streetview?size=240x240&location=${mapsCenter}&fov=80&source=outdoor&key=${config.keys.google.maps}`;
  const staticMapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${mapsCenter}&zoom=15&size=240x240&scale=3&maptype=roadmap&language=nl&style=feature:all|element:labels|visibility:off&markers=${mapsCenter}&key=${config.keys.google.maps}`;

  const houseProps = [
    { icon: House, text: HOUSE_TYPES_NL[type] },
    { icon: Crop, text: `${area}m²` },
    { icon: ClockCounterClockwise, text: constructionYear },
    {
      icon: Certificate,
      text: labelRegistration?.registered
        ? `Energielabel ${labelRegistration.energyLabel}`
        : 'Geen geregistreerd energielabel',
    },
    { icon: BuildingApartment, text: vve ? 'Onderdeel van een VvE' : 'Geen VvE' },
  ];

  return (
    <Flex flexWrap="wrap" alignItems="center" mx={-2} px={isMobile ? 2 : 4} pb={isMobile ? 2 : 4}>
      <Box width={[1, 1, 1 / 3, 2 / 5]} px={2}>
        <Flex flexDirection={['column-reverse', 'column-reverse', 'column']}>
          <Heading heading="h2" style={{ lineHeight: 1 }}>
            Jouw woning
          </Heading>
          {isNewFunnel && (
            <Small onClick={() => setActiveHouseId('')}>
              <TextLink color="gray" hoverColor="green">
                Ander adres gebruiken
              </TextLink>
            </Small>
          )}
        </Flex>
      </Box>

      <Box width={[1, 1, 2 / 3, 3 / 5]} px={2}>
        <Card
          bgColor="grayLighter"
          borderColor="grayLight"
          animation="none"
          shadow="none"
          p={3}
          mb={2}
        >
          <Flex flexWrap="wrap" alignItems="center" justifyContent="center" mx={-1}>
            <Box width={[1 / 2, 1 / 3, 1 / 2]} px={1}>
              <Image src={streetviewURL} alt={inlineAddress} width="100%" radius="6px" />
            </Box>
            <Box width={[1 / 2, 1 / 3, 1 / 2]} px={1}>
              <Image src={staticMapURL} alt={inlineAddress} width="100%" radius="6px" />
            </Box>
            <Box width={1} px={1} mt={3}>
              <Box mb={2}>
                <Bold>
                  {street} {number} {suffix}
                </Bold>
              </Box>

              {isEditingHouseProps ? (
                <UpdateHousePropertiesForm
                  house={house}
                  onCancel={() => setIsEditingHouseProps(false)}
                  onCompleted={() => setIsEditingHouseProps(false)}
                />
              ) : (
                <>
                  {houseProps.map(({ icon: Icon, text }, index) => (
                    <Flex
                      key={`house-spec-${index}`}
                      alignItems="center"
                      style={{ fontSize: theme.type.scale[6] }}
                    >
                      <Box>
                        <Icon weight="duotone" color={theme.colors.grayDark} />
                      </Box>
                      <Box ml={2}>{text}</Box>
                    </Flex>
                  ))}
                  <Button
                    minimal
                    fontSize={7}
                    bgColor="grayDarker"
                    iconEnd={Pencil}
                    onClick={() => setIsEditingHouseProps(true)}
                    mt={1}
                    mb={0}
                    px={5}
                  >
                    Gegevens aanpassen
                  </Button>
                </>
              )}
            </Box>
          </Flex>
        </Card>
        <Button
          bgColor="yellow"
          color="grayBlack"
          onClick={onNext}
          disabled={isEditingHouseProps}
          fluid
          mb={0}
          style={{ borderRadius: 3, whiteSpace: 'nowrap' }}
        >
          Klopt, laten we beginnen →
        </Button>
      </Box>
    </Flex>
  );
};

const PlaceholderContent: React.FC = () => {
  const isMobile = useIsMobile();
  return (
    <Flex flexWrap="wrap" alignItems="center" mx={-2} px={isMobile ? 2 : 4} pb={isMobile ? 2 : 4}>
      <Box width={[1, 1, 1 / 3, 1 / 2]} px={2}>
        <Heading heading="h2" style={{ lineHeight: 1 }}>
          Jouw woning
        </Heading>
        <Placeholder height="1em" />
      </Box>

      <Box width={[1, 1, 2 / 3, 1 / 2]} px={2}>
        <Placeholder height="500px" />
        <Placeholder height="2em" />
      </Box>
    </Flex>
  );
};
interface YourHomeCardProps {
  onPrev: () => void;
  onNext: () => void;
  hideLoading: boolean;
}

const YourHomeCard: React.FC<YourHomeCardProps> = ({ onPrev, onNext, hideLoading }) => {
  const { activeHouseId } = useActiveHouseId();
  const intercom = useIntercom();
  const isMobile = useIsMobile();

  const { data, loading, error } = useQuery<addressCard, addressCardVariables>(ADDRESS_CARD, {
    variables: { id: activeHouseId },
    skip: !activeHouseId,
  });

  const [fauxLoadingHouseData, setFauxLoadingHouseData] = useState(!hideLoading);
  const [, setAddressLoaded] = useLocalStorage<boolean>('addressLoaded', false);

  const handleAddressLoaded = useCallback(() => {
    setAddressLoaded(true);
    setFauxLoadingHouseData(false);
  }, [setAddressLoaded]);

  if (!activeHouseId) {
    return (
      <OmniformCard key="omniform-home" p={4} animation="none" id="omniform-home">
        <Box p={isMobile ? 2 : 0}>
          <OmniformPrevArrow onClick={onPrev} />
        </Box>
        <NoAddressYet />
      </OmniformCard>
    );
  }

  if (fauxLoadingHouseData || !data) {
    return (
      <OmniformCard key="omniform-home-loading" p={isMobile ? 4 : 8} animation="none">
        {(!data && !loading) || error ? (
          <Center block>
            <Large>
              <div className="glitch" data-text="Er ging iets mis">
                Er ging iets mis
              </div>
            </Large>
            <p>Er is iets misgegaan bij het ophalen van je woninggegevens.</p>
            Probeer het opnieuw of{' '}
            <TextLink onClick={() => intercom.showNewMessage()}>
              <u>neem contact met ons op</u>
            </TextLink>
            .
          </Center>
        ) : hideLoading ? (
          <PlaceholderContent />
        ) : (
          <LoadingHouseData
            address={data?.house.address}
            dataLoading={loading}
            onFinish={handleAddressLoaded}
          />
        )}
      </OmniformCard>
    );
  }

  return (
    <OmniformCard key="omniform-home" p={isMobile ? 2 : 4} animation="none" id="omniform-home">
      <Box p={isMobile ? 2 : 0}>
        <OmniformPrevArrow onClick={onPrev} />
      </Box>
      <YourHomeContent house={data.house} onNext={onNext} />
    </OmniformCard>
  );
};

export default YourHomeCard;
