import { Accordion, Box, theme } from '@energiebespaarders/symbols';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { useCallback } from 'react';
import styled from 'styled-components';
import RequestPackageForm from '~/components/RequestPackage/RequestPackageForm';
import { StyledFormBox } from '~/components/RequestPackage/layout';
import { getLabelDutyPackageForHouseType, useCustomerDutyPackage } from '~/hooks/useDutyPackage';
import { useMe } from '~/hooks/useMe';
import { dummy_dummy_content_ComponentRequestFormStandaloneRecord } from '~/types/generated/cms/dummy';
import { me_me_Customer, me_me_Lead } from '~/types/generated/me';
import { getActiveHouseId } from '../_app/initializeSession';
interface Props {
  block: dummy_dummy_content_ComponentRequestFormStandaloneRecord;
}

const PackageText = styled(Box)<{ $mobile: boolean }>`
  font-size: ${x => theme.type.scale[x.$mobile ? 3 : 2]};
  font-weight: 700;
  font-family: Causten, sans-serif;
`;

const LabelRequestForm: React.FC<Props> = ({ block }) => {
  const isMobile = useIsMobile();
  const blockDutyPackage = block.dutyPackage?.key || '';
  const { me } = useMe<me_me_Lead | me_me_Customer>();
  const houseType = me.houses.find(house => house.id === getActiveHouseId(me))?.type;
  const determineEnergyLabelPackage = useCallback(() => {
    if (!blockDutyPackage?.includes('energyLabelHouseType')) return;
    return getLabelDutyPackageForHouseType(houseType);
  }, [blockDutyPackage, houseType]);

  const correctedDutyPackageKey = determineEnergyLabelPackage() || blockDutyPackage;
  const { dutyPackage } = useCustomerDutyPackage(correctedDutyPackageKey);

  return (
    <StyledFormBox
      width={1}
      px={[2, 4]}
      textAlign="center"
      $isOpen={!!dutyPackage}
      $bgColor="transparent"
      $openBgColor={dutyPackage?.color || 'green'}
      style={{ background: 'none', border: 'none' }}
    >
      <Accordion contentBgColor="transparent" contentPadding={0} isOpened override>
        <p>
          <PackageText $mobile={isMobile}>
            {block.description ||
              'Vul hieronder je gegevens in om het huisbezoek in te plannen voor de energielabelopname.'}
          </PackageText>
        </p>
        <RequestPackageForm
          dutyPackage={dutyPackage!}
          submitText={block.dutyPackage?.buttonText || 'Start jouw aanvraag'}
          initialValues={
            block.voucherCode?.code ? { voucherCode: block.voucherCode?.code } : undefined
          }
        />
      </Accordion>
    </StyledFormBox>
  );
};

const StandardRequestForm: React.FC<Props> = ({ block }) => {
  const isMobile = useIsMobile();
  const { dutyPackage } = useCustomerDutyPackage(block.dutyPackage?.key || '');
  return (
    <StyledFormBox
      width={1}
      px={[2, 4]}
      textAlign="center"
      $isOpen={!!dutyPackage}
      $bgColor="transparent"
      $openBgColor={dutyPackage?.color || 'green'}
      style={{ background: 'none', border: 'none' }}
    >
      <Accordion contentBgColor="transparent" contentPadding={0} isOpened override>
        <p>
          <PackageText $mobile={isMobile}>
            {block.description ||
              'Fijn dat we je mogen helpen met het verduurzamen van jouw woning! Vul hieronder je gegevens in om het huisbezoek in te plannen.'}
          </PackageText>
        </p>
        <RequestPackageForm
          dutyPackage={dutyPackage!}
          submitText={block.dutyPackage?.buttonText || 'Start jouw aanvraag'}
          initialValues={
            block.voucherCode?.code ? { voucherCode: block.voucherCode?.code } : undefined
          }
        />
      </Accordion>
    </StyledFormBox>
  );
};

const RequestForm: React.FC<Props> = ({ block }) => {
  return block.dutyPackage?.key?.includes('energyLabelHouseType') ? (
    <LabelRequestForm block={block} />
  ) : (
    <StandardRequestForm block={block} />
  );
};

export default RequestForm;
