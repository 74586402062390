import { Modify } from '../../typeHelpers';
import {
  getOmniform_getOmniform,
  getOmniform_getOmniform_flow_nodes_data_OmniformStatement,
  getOmniform_getOmniform_questions,
} from '../../types/generated/getOmniform';
import {
  saveOmniformResponse_saveOmniformResponse,
  saveOmniformResponse_saveOmniformResponse_answers,
} from '../../types/generated/saveOmniformResponse';
import {
  startOmniformResponse_startOmniformResponse,
  startOmniformResponse_startOmniformResponse_answers,
} from '../../types/generated/startOmniformResponse';
import { OmniformFlow } from './flows/omniform-flow';
import type { Me } from '../useMe';

// TODO: replace all these with classes from a separate Omniform package to be in sync with backend.

export type Omniform<TFlow extends OmniformFlow<Record<string, any>>> = Modify<
  getOmniform_getOmniform,
  { questions: readonly OmniformQuestion[]; flow?: TFlow }
>;

export type OmniformQuestion = Modify<
  getOmniform_getOmniform_questions,
  { options?: readonly InputOption<QuestionAnswerType>[]; answerType: QuestionAnswerType }
>;

export type InputOptionValue<T extends QuestionAnswerType> = T extends QuestionAnswerType.Boolean
  ? boolean
  : T extends QuestionAnswerType.Date
  ? Date
  : T extends QuestionAnswerType.Number
  ? number
  : T extends QuestionAnswerType.String
  ? string
  : T extends QuestionAnswerType.StringArray
  ? string
  : T extends QuestionAnswerType.NumberArray
  ? number
  : T extends QuestionAnswerType.BooleanArray
  ? boolean
  : T extends QuestionAnswerType.DateArray
  ? Date
  : never;

export type InputOption<T extends QuestionAnswerType> = {
  label: string;
  value: InputOptionValue<T>;
};

export type OmniformAnswer = Modify<
  | startOmniformResponse_startOmniformResponse_answers
  | saveOmniformResponse_saveOmniformResponse_answers,
  {
    answer?: AnswerType<QuestionAnswerType> | null;
  }
>;

export type OmniformResponse = Modify<
  startOmniformResponse_startOmniformResponse | saveOmniformResponse_saveOmniformResponse,
  { answers: OmniformAnswer[] }
>;

export enum QuestionAnswerType {
  Boolean = 'boolean',
  Date = 'date',
  Number = 'number',
  String = 'string',
  StringArray = 'stringArray',
  NumberArray = 'numberArray',
  BooleanArray = 'booleanArray',
  DateArray = 'dateArray',
}

export type AnswerType<T extends QuestionAnswerType> = T extends QuestionAnswerType.Boolean
  ? boolean
  : T extends QuestionAnswerType.Date
  ? Date
  : T extends QuestionAnswerType.Number
  ? number
  : T extends QuestionAnswerType.String
  ? string
  : T extends QuestionAnswerType.StringArray
  ? string[]
  : T extends QuestionAnswerType.NumberArray
  ? number[]
  : T extends QuestionAnswerType.BooleanArray
  ? boolean[]
  : T extends QuestionAnswerType.DateArray
  ? Date[]
  : never;

export type OmniformResponder = { __typename: Me['__typename']; id: string };

export type OmniformStatement = getOmniform_getOmniform_flow_nodes_data_OmniformStatement;
