import { gql } from '@apollo/client';
import {
  BlogPostFragment,
  ButtonFragment,
  CampaignContainerFragment,
  ColorFragment,
  FaqItemFragment,
  ImageBlockRecordFragment,
  ProductTypeFragment,
  ResponsiveImageFragment,
} from './fragments/fragments';

export const GET_HIGHLIGHTED_BLOG_POSTS = gql`
  ${BlogPostFragment}
  query getHighlightedBlogPosts {
    allBlogPosts(filter: { isHighlighted: { eq: "true" } }) {
      ...BlogPostFragment
    }
  }
`;

export const GET_ALL_CAMPAIGN_SLUGS = gql`
  query getAllCampaignSlugs {
    allCampaigns {
      slug
      id
    }
  }
`;

export const GET_OMNIFORM_HELP_BY_ID = gql`
  ${ImageBlockRecordFragment}

  query getOmniformHelpById($recordId: ItemId!) {
    omniformHelp(filter: { id: { eq: $recordId } }) {
      id
      title
      helpContent {
        value
        links {
          id
          question
          answer {
            value
            links
            blocks {
              id
              ... on ImageBlockRecord {
                ...ImageBlockRecordFragment
              }
            }
          }
        }
        blocks {
          id
          ... on ImageBlockRecord {
            ...ImageBlockRecordFragment
          }
        }
      }
    }
  }
`;

export const GET_FEASIBILITY_STATUS_BY_KEY = gql`
  ${ButtonFragment}
  query getEstimateFeasibilityContent($key: String!) {
    estimateFeasibility(filter: { feasibilityKey: { eq: $key } }) {
      feasibilityKey
      title
      explanation
      feasibilityButton {
        ...ButtonFragment
      }
    }
  }
`;

export const GET_ADVICE_INFO = gql`
  ${ColorFragment}
  ${ResponsiveImageFragment}
  ${CampaignContainerFragment}
  query getAdviceInfoForEstimates {
    adviceInfo {
      id
      heroImage {
        id
        url
        responsiveImage(imgixParams: { w: 1200, h: 400, fit: crop, crop: faces }) {
          ...ResponsiveImage
        }
      }
      content {
        id
        heading
        bgColor {
          ...ColorFragment
        }
        campaignContainerContent {
          ...CampaignContainerFragment
        }
      }
    }
  }
`;

export const GET_ESTIMATE_INFO = gql`
  ${FaqItemFragment}
  ${ProductTypeFragment}
  ${ResponsiveImageFragment}
  query getEstimateInfo($solution: String!) {
    estimateInfo(filter: { solution: { eq: $solution } }) {
      id
      solution
      productTypes {
        ...ProductTypeFragment
      }
      productImage {
        id
        responsiveImage(imgixParams: { w: 400, h: 400, fit: crop, crop: faces }) {
          ...ResponsiveImage
        }
      }
      faqItems {
        ...FaqItemFragment
      }
      description {
        blocks
        links
        value
      }
    }
  }
`;

export const GET_ESTIMATES_FAQ = gql`
  ${FaqItemFragment}
  query getEstimatesFAQ {
    allFaqItems(
      filter: { id: { in: ["HN-vtwSDQwm2A3ZppEspBQ", "54786821", "JulYt4z1RB2VzGjO7WScrQ"] } }
      orderBy: position_DESC
    ) {
      ...FaqItemFragment
    }
  }
`;
