import { Flex, theme } from '@energiebespaarders/symbols';
import { Heading } from '@energiebespaarders/symbols/helpers';
import styled from 'styled-components';
import BlockContainer from './BlockContainer';
import QuickLinkItem, { IQuickLinkItem } from './QuickLinkItem';

interface Props {
  title: string;
  items: IQuickLinkItem[];
}

const QuickLinkItemContainer = styled(Flex)`
  div:last-child {
    margin-bottom: 0px;
  }
`;

const QuickLinks: React.FC<Props> = ({ title, items }) => {
  return (
    <BlockContainer backgroundColor={theme.colors.greenSlate}>
      <Flex flexDirection="column" justifyContent="center">
        <Heading heading="h2">{title}</Heading>

        <QuickLinkItemContainer flexWrap="wrap" justifyContent="space-around" mx={-3}>
          {items.map(i => (
            <QuickLinkItem key={i.id} {...i} />
          ))}
        </QuickLinkItemContainer>
      </Flex>
    </BlockContainer>
  );
};

export default QuickLinks;
