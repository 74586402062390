import { Box, Flex, Icon, theme } from '@energiebespaarders/symbols';
import { Bold } from '@energiebespaarders/symbols/helpers';
import { Check } from '@energiebespaarders/symbols/icons/line';
import { dummy_dummy_content_PointCardsBlockRecord } from '~/types/generated/cms/dummy';
import HybridLink from '../HybridLink';

interface Props {
  block: dummy_dummy_content_PointCardsBlockRecord;
}

const PointCards: React.FC<Props> = ({ block }) => {
  return (
    <Flex flexDirection="column">
      {block.pointCards.map(pc => (
        <Flex
          style={{ backgroundColor: theme.colors.greenLight, borderRadius: 5 }}
          key={pc.id}
          flexDirection="row"
          mb={2}
          p={2}
        >
          <Box>
            <Icon icon={Check}></Icon>
          </Box>

          <Flex flexDirection="column" alignItems="start" width={1} ml={6}>
            <Box style={{ fontSize: theme.type.scale[4] }}>
              <Bold>{pc.title}</Bold>
            </Box>

            <p>{pc.text}</p>
            <HybridLink style={{ color: 'grey' }} to={pc.url!}>
              {pc.urlText}
            </HybridLink>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default PointCards;
