import { Box, Flex, theme } from '@energiebespaarders/symbols';
import { Image, ResponsiveImageType } from 'react-datocms';
import styled from 'styled-components';
import HybridLink from '~/components/HybridLink';

export interface IQuickLinkItem {
  id: string;
  responsiveImageData: ResponsiveImageType;
  heading: string;
  text: string;
  to?: string;
  href?: string;
  showPrompt?: boolean;
}

const StyledImage = styled(Image)`
  border-radius: 5px;
  margin-bottom: 12px;

  img {
    height: 250px;
    object-fit: cover;
  }
`;

const QuickLinkItemContainer = styled(Flex)`
  transition: transform 0.18s ease;

  h3 {
    font-weight: 500;
    font-size: ${theme.type.scale[4]};
  }

  &:hover {
    transform: translateY(-3px);

    img {
      opacity: 0.9;
    }
  }

  p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const QuickLinkItem: React.FC<IQuickLinkItem> = ({
  heading,
  text,
  to,
  href,
  responsiveImageData,
}) => {
  const path = to ? { to } : { href };
  return (
    <QuickLinkItemContainer flexDirection="column" px={3} width={[1, 1, 1 / 3]} mb={[6, 6, 0]}>
      <HybridLink {...path} style={{ color: 'black' }}>
        <StyledImage data={responsiveImageData} />
        <h3>{heading}</h3>
        <p>{text}</p>
        <Box mt={2}>Meer lezen →</Box>
      </HybridLink>
    </QuickLinkItemContainer>
  );
};

export default QuickLinkItem;
