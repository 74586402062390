import { Box, Card, Image, theme } from '@energiebespaarders/symbols';
import React, { ReactNode } from 'react';
import { Image as DatoImage, ResponsiveImageType } from 'react-datocms';
import styled from 'styled-components';

const StyledDatoImage = styled(DatoImage)`
  picture img {
    width: 100% !important;
    object-fit: cover;
    transition: transform 0.15s ${theme.curves.sharp};
    border-radius: 6px 6px 0 0;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  object-fit: cover;
  transition: transform 0.15s ${theme.curves.sharp};
  border-radius: 6px 6px 0 0;
`;

const ImageZoomWrapper = styled(Box)<{ $height: string }>`
  position: relative;
  width: 100%;
  height: ${x => x.$height};
  overflow: hidden;
  border-radius: 6px 6px 0 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
    opacity: 1;
    transition: opacity 0.15s ${theme.curves.sharp};
  }

  ${StyledImage} {
    height: ${x => x.$height};
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: transform 0.3s ${theme.curves.sharp};

  &:hover {
    ${StyledImage} {
      transform: scale(1.05);
    }

    ${ImageZoomWrapper} {
      &::after {
        background: none;
        opacity: 0;
      }
    }
  }
`;

export const Truncated = styled.div<{ $maxLines?: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${p => (p ? p.$maxLines : 5)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyledTitle = styled.div`
  font-size: ${x => x.theme.type.scale[4]};
  letter-spacing: -0.5px;
  font-weight: 500;
`;

interface ImageCardProps {
  content: ReactNode;
  imageSrc?: string;
  responsiveImageData?: ResponsiveImageType;
  title?: string;
  headingType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'div';
  alt: string;
  height?: string;
  maxLinesOfContent?: number;
}

const ImageCard: React.FC<ImageCardProps> = ({
  alt,
  content,
  imageSrc,
  responsiveImageData,
  headingType,
  title,
  height = '120px',
  maxLinesOfContent,
}) => {
  return (
    <StyledCard p={0} animation="none" borderRadius="6px" shadow="none" borderColor="grayLight">
      <ImageZoomWrapper $height={height}>
        {responsiveImageData ? (
          <StyledDatoImage
            data={responsiveImageData}
            style={{ borderRadius: '6px 6px 0 0', maxWidth: 'unset', height: '100%' }}
          />
        ) : (
          <StyledImage src={imageSrc} alt={alt} style={{ borderRadius: '6px 6px 0 0' }} />
        )}
      </ImageZoomWrapper>
      <Box px={3} py={2} style={{ flexGrow: 1 }}>
        {title && <StyledTitle as={headingType}>{title}</StyledTitle>}
        <Box style={{ flexGrow: 1 }}>
          {maxLinesOfContent ? (
            <Truncated $maxLines={maxLinesOfContent}>{content}</Truncated>
          ) : (
            content
          )}
        </Box>
      </Box>
    </StyledCard>
  );
};

export default ImageCard;
