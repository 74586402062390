import { useMutation } from '@apollo/client';
import { Box, Button, Checkbox, Flex, Input, Select } from '@energiebespaarders/symbols';
import { FloppyDisk } from '@energiebespaarders/symbols/icons/solid';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { UPDATE_BASIC_HOUSE_PROPERTIES } from '~/envPartner/PartnerCheck/UpdateHouseInfoForm';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import { addressCard_house } from '~/types/generated/addressCard';
import { houseIsVVE, houseIsVVEVariables } from '~/types/generated/houseIsVVE';
import {
  updateBasicHouseProperties,
  updateBasicHousePropertiesVariables,
} from '~/types/generated/updateBasicHouseProperties';
import { houseTypeOptions, yearOptions } from '../SavingsCheck/AddressQuestion';
import { UPDATE_HOUSE_IS_VVE } from '../SavingsCheck/mutations';

type HousePropsFormState = {
  type: number;
  area: number;
  constructionYear: number;
};

interface UpdateHousePropertiesFormProps {
  house: addressCard_house;
  onCancel?: () => void;
  onCompleted?: () => void;
}

const UpdateHousePropertiesForm: React.FC<UpdateHousePropertiesFormProps> = ({
  house,
  onCancel,
  onCompleted,
}) => {
  const { activeHouseId } = useActiveHouseId();
  const [formState, setFormState] = useState<HousePropsFormState>({
    type: house.type,
    area: house.area,
    constructionYear: house.constructionYear,
  });

  const [updateHouseProperties] = useMutation<
    updateBasicHouseProperties,
    updateBasicHousePropertiesVariables
  >(UPDATE_BASIC_HOUSE_PROPERTIES, {
    onCompleted,
    refetchQueries: ['getSelfIntakeHouseData'],
  });
  const [updateHouseIsVve] = useMutation<houseIsVVE, houseIsVVEVariables>(UPDATE_HOUSE_IS_VVE, {
    refetchQueries: ['getSelfIntakeHouseData'],
  });

  const handleUpdateHouseProperties = useCallback(() => {
    if (
      formState.type !== house.type ||
      formState.area !== house.area ||
      formState.constructionYear !== house.constructionYear
    ) {
      void updateHouseProperties({ variables: { houseId: activeHouseId, ...formState } });
    } else onCancel?.();
  }, [
    activeHouseId,
    formState,
    house.area,
    house.constructionYear,
    house.type,
    onCancel,
    updateHouseProperties,
  ]);

  const capitalizedHouseTypeOptions = houseTypeOptions.map(o => ({
    ...o,
    label: _.capitalize(o.label),
  }));

  return (
    <Flex flexWrap="wrap" mx="-3px" alignItems="flex-end">
      <Box width={[1, 1 / 2, 1 / 2, 1]} px="3px">
        <Select<number>
          onChange={e => {
            setFormState({ ...formState, type: e.value });
            if (e.value === 5 && !house.vve) {
              void updateHouseIsVve({ variables: { houseId: activeHouseId, isVve: true } });
            }
          }}
          options={capitalizedHouseTypeOptions}
          fontSize={6}
          label="Type woning"
          clearable={false}
          value={capitalizedHouseTypeOptions.find(option => option.value === formState.type)}
        />
      </Box>
      <Box width={[1, 1 / 2, 1 / 2, 1]} px="3px">
        <Input
          addonSide="end"
          addonContent="m2"
          type="number"
          fontSize={6}
          minLength={2}
          maxLength={4}
          value={formState.area}
          onChange={e => setFormState({ ...formState, area: parseInt(e.target.value) })}
          placeholder="Woonoppervlak"
          label="Woonoppervlak"
          inputMode="numeric"
        />
      </Box>
      <Box width={[1, 1 / 2, 1 / 2, 1]} px="3px">
        <Select<number>
          value={yearOptions.find(option => option.value === formState.constructionYear)}
          onChange={e => setFormState({ ...formState, constructionYear: e.value })}
          options={yearOptions}
          label="Bouwjaar"
          fontSize={6}
        />
      </Box>
      <Box width={[1, 1 / 2, 1 / 2, 1]} px="3px">
        <Checkbox
          id="vve"
          checked={!!house.vve}
          bgColor="greenDark"
          fontSize={6}
          value={true}
          onChange={() =>
            updateHouseIsVve({ variables: { houseId: activeHouseId, isVve: !house.vve } })
          }
          label="Onderdeel van een VvE"
          width="100%"
        />
      </Box>
      <Box width={1} px="3px">
        <Flex flexDirection={['column-reverse', 'row']}>
          <Button
            minimal
            type="button"
            fontSize={7}
            fluid
            bgColor="red"
            mt={1}
            mb={0}
            onClick={onCancel}
            style={{ borderRadius: '3px' }}
            label="← Annuleren"
          />
          <Button
            fontSize={7}
            type="submit"
            onClick={handleUpdateHouseProperties}
            form="updateHousePropertiesForm"
            fluid
            bgColor="greenDark"
            iconEnd={FloppyDisk}
            mt={1}
            mr={0}
            mb={0}
            style={{ borderRadius: '3px' }}
            label="Opslaan"
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default UpdateHousePropertiesForm;
