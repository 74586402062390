import { Box, Spinner } from '@energiebespaarders/symbols';
import { Center } from '@energiebespaarders/symbols/helpers';
import React, { useCallback, useEffect, useState } from 'react';
import {
  AnswerType,
  OmniformQuestion,
  OmniformStatement,
  QuestionAnswerType,
} from '~/hooks/omniform/types';
import { WithFormState } from '~/hooks/omniform/useOmniform';
import IntakeExitCard from '../CustomerIntake/IntakeExitCard';
import { QKeyCustomerIntake } from '../CustomerIntake/types';
import StyledBackgroundCard, { randomNumber } from './BackgroundCard';
import QuestionCard from './OmniformQuestionCard';

interface OmniformCardControllerProps {
  questions: WithFormState<OmniformQuestion>[];
  nodes?: (WithFormState<OmniformQuestion> | OmniformStatement)[];
  onAnswer: <T extends QuestionAnswerType>(
    questionKey: string,
    answerType: T,
    answer: AnswerType<T>,
  ) => void;
  onPrevious?: () => void;
  onNext?: (answers: Partial<Record<QKeyCustomerIntake, any>>) => void;
  onPreviousFromStart?: () => void;
}

/**
 * Used to mock the visual cue that there are more questions coming for at least this amount of questions.
 * Required because there can be nodes that have 0 edges and therefore make it look like they're the last question until answering.
 */
const FAKE_QUESTIONS_LENGTH = 10;

const OmniformCardController: React.FC<OmniformCardControllerProps> = ({
  nodes: nodesProp, // Only available when there's flow logic in the form
  questions,
  onAnswer,
  onNext,
  onPrevious,
  onPreviousFromStart,
}) => {
  const [currentNodeKey, setCurrentNodeKey] = useState(btoa(questions[0].key));
  const [currentNodeIndex, setCurrentNodeIndex] = useState(0);

  useEffect(() => {
    const encodedNodeKey = btoa(currentNodeKey);
    if (!currentNodeKey || !encodedNodeKey) return;
    if (window.location.hash !== encodedNodeKey) window.location.hash = encodedNodeKey;
  }, [currentNodeKey]);

  // Questions are the default nodes, but we can also have custom nodes like statements.
  const nodes = nodesProp?.length ? nodesProp : questions;

  const goToNode = useCallback(
    (key: string) => {
      const nodeIndex = nodes.findIndex(node => node.key === key);
      if (nodeIndex === -1) return;
      setCurrentNodeIndex(nodeIndex);
      setCurrentNodeKey(key);
    },
    [nodes],
  );

  useEffect(() => {
    const hash = window.location.hash.replace(/-/g, '+').replace(/_/g, '/');
    if (!hash || hash === '#') return;
    const key = atob(hash.slice(1));
    goToNode(key);
  }, [goToNode]);

  const isStatement = nodes[currentNodeIndex].__typename === 'OmniformStatement';
  const isQuestion = nodes[currentNodeIndex].__typename === 'OmniformQuestion';

  const goToNextNode = useCallback(() => {
    if (currentNodeIndex === nodes.length - 1) return;
    setCurrentNodeIndex(currentNodeIndex + 1);
    setCurrentNodeKey(nodes[currentNodeIndex + 1].key);

    if (onNext) {
      const questionsFromNodes = nodes.filter(
        node => node.__typename === 'OmniformQuestion',
      ) as WithFormState<OmniformQuestion>[];
      const answers = questionsFromNodes.reduce(
        (acc, q) => ({ ...acc, [q.key]: q.answer }),
        {} as Record<string, any>,
      );
      onNext(answers);
    }
  }, [currentNodeIndex, nodes, onNext]);

  const handlePrevious = useCallback(() => {
    if (currentNodeIndex === 0) return onPreviousFromStart?.();
    setCurrentNodeIndex(currentNodeIndex - 1);
    setCurrentNodeKey(nodes[currentNodeIndex - 1].key);
    onPrevious?.();
  }, [currentNodeIndex, nodes, onPrevious, onPreviousFromStart]);

  const amountRemainingQuestions =
    currentNodeIndex < FAKE_QUESTIONS_LENGTH
      ? 3 // To make it look like there are more questions coming for at least this amount of questions.
      : nodes.length - currentNodeIndex - 1;

  return (
    <Box style={{ position: 'relative' }} mb={8}>
      {isStatement ? ( // TODO: Currently we only have exit statements, should be made generic for this controller, so we can support intermittent statements
        <IntakeExitCard onPrevious={handlePrevious} />
      ) : isQuestion ? (
        <QuestionCard
          key={nodes[currentNodeIndex].key}
          question={nodes[currentNodeIndex] as WithFormState<OmniformQuestion>}
          onAnswer={onAnswer}
          onPrevious={handlePrevious}
          onNext={goToNextNode}
          isFinalQuestion={amountRemainingQuestions <= 0}
        />
      ) : (
        <Center block>
          <Spinner />
        </Center>
      )}

      {amountRemainingQuestions > 0 &&
        !isStatement && ( // TODO: Once we have intermittent statements, we should also show the background cards for those
          <StyledBackgroundCard
            key={`${nodes[currentNodeIndex].key}-bg`}
            bgColor="rgb(250, 250, 250)"
            $depth={1}
            animation="none"
            $randomizer={(randomNumber(1) > 0.5 ? 1 : -1) * randomNumber(currentNodeIndex)}
          />
        )}
      {amountRemainingQuestions > 1 && !isStatement && (
        <StyledBackgroundCard
          key={`${nodes[currentNodeIndex].key}-bg2`}
          bgColor="rgb(245, 245, 245)"
          $depth={2}
          animation="none"
          $randomizer={(randomNumber(2) > 0.5 ? 1 : -1) * randomNumber(currentNodeIndex)}
        />
      )}
      {amountRemainingQuestions > 2 && !isStatement && (
        <StyledBackgroundCard
          key={`${nodes[currentNodeIndex].key}-bg3`}
          bgColor="rgb(240, 240, 240)"
          $depth={3}
          animation="none"
          $randomizer={(randomNumber(3) > 0.5 ? 1 : -1) * randomNumber(currentNodeIndex)}
        />
      )}
    </Box>
  );
};

export default OmniformCardController;
