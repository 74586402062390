import { Box, Button, Flex } from '@energiebespaarders/symbols';
import { Heading } from '@energiebespaarders/symbols/helpers';
import { Check } from '@energiebespaarders/symbols/icons/solid';
import { ReactElement, useCallback } from 'react';
import { OmniformFlow } from '~/hooks/omniform/flows/omniform-flow';
import type {
  AnswerType,
  Omniform,
  OmniformResponse,
  QuestionAnswerType,
} from '~/hooks/omniform/types';
import useOmniform from '~/hooks/omniform/useOmniform';
import { OmniformAPI } from '~/hooks/omniform/useOmniformAPI';
import OmniformCardController from './OmniformCardController';
import renderQuestionInput from './OmniformInputs';

export type OmniformContentProps<TFlowData extends Record<string, any>> = {
  // @ts-ignore
  form: Omniform<OmniformFlow<TFlowData>>;
  api: OmniformAPI<TFlowData>;
  response: OmniformResponse;
} & OmniformSharedProps<TFlowData>;

export type OmniformSharedProps<TFlowData extends Record<string, any>> = {
  initialFlowData?: TFlowData;
  updateFlowData?: (
    flowData: TFlowData,
    questionKey: string,
    answer: AnswerType<QuestionAnswerType>,
  ) => TFlowData;

  /** Callback to be called when an answer is given or updated. */
  onAnswer?: (
    questionKey: string,
    answer: AnswerType<QuestionAnswerType>,
    currentFlowData: TFlowData,
  ) => void;

  /**
   * Callback to be called when the form's submit button is clicked.
   * Use this for example to move to a next form, or to redirect to a different page.
   * You'll get the last version of the flow data as a parameter, which you can use to calculate a result,
   * or determine whether to move on to a next form / which form to move to.
   *
   * In the background, the form response gets saved in the database.
   */
  onSubmit: (data: TFlowData) => void;

  /** Callback to be called when the form's previous button is clicked. */
  onPrevious?: () => void;

  /** Callback to be called when the form's next button is clicked. */
  onNext?: (answers: Partial<Record<string, any>>) => void;

  /** Callback to be called when the form's previous button is clicked on the first card, backing out of the form. */
  onPreviousFromStart?: () => void;

  /** The text to be displayed on the Submit button */
  submitText?: string;

  /** Determine whether to show the questions one-by-one in a card layout or render a full form in one go */
  formStyle: 'questionCards' | 'default';

  /** Data object containing variables to be used in questions and descriptions, see {@link resolveVariables} */
  textVariables?: Record<string, unknown>;
};

/** Renders an Omniform with the current questions and answers from the response:
 * - Form title
 * - Questions in a list (default) or card layout
 * - Submit button
 */
export function OmniformContent<TFlowData extends Record<string, any>>({
  form,
  api,
  response,
  initialFlowData,
  updateFlowData,
  onAnswer,
  onPrevious,
  onPreviousFromStart,
  onNext,
  onSubmit,
  submitText = 'Voltooien',
  textVariables,
  formStyle,
}: OmniformContentProps<TFlowData>): ReactElement {
  const {
    questions,
    nodes,
    errors,
    answer: handleAnswer,
    submit,
    submissionAttempted,
    formHasErrors,
  } = useOmniform<TFlowData>(
    form,
    response,
    api,
    initialFlowData,
    updateFlowData,
    onSubmit,
    textVariables,
    onAnswer,
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      // Prevent form submission from refreshing the page
      e.preventDefault();
      submit();
    },
    [submit],
  );

  return formStyle === 'questionCards' ? (
    <form id={form.id} onSubmit={handleSubmit} style={{ position: 'relative' }}>
      <OmniformCardController
        questions={questions}
        nodes={nodes}
        onAnswer={handleAnswer}
        onNext={onNext}
        onPrevious={onPrevious}
        onPreviousFromStart={onPreviousFromStart}
      />
    </form>
  ) : (
    <form id={form.id} onSubmit={handleSubmit} style={{ position: 'relative' }}>
      <Heading heading="h3">{form.title}</Heading>
      <Flex flexWrap="wrap" mx="-3px">
        {questions.map(question => (
          <Box key={`box-${question.key}`} width={1} px="3px" pb={2}>
            {renderQuestionInput(question, handleAnswer)}
          </Box>
        ))}

        {onPrevious && (
          <Box width={1 / 3} px="3px">
            <Button inverse type="button" fluid fontSize={5} onClick={onPrevious} label="←" />
          </Box>
        )}

        <Box width={onPrevious ? 2 / 3 : 1} px="3px">
          <Button
            type="submit"
            fluid
            fontSize={5}
            iconStart={!errors.general ? Check : undefined}
            label={submitText}
            error={submissionAttempted && formHasErrors ? errors.general : undefined}
          />
        </Box>
      </Flex>
    </form>
  );
}

export default OmniformContent;
