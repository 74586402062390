import { gql, useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { OrderItemFragment } from '~/fragments/OrderItem';
import { createFunnel, createFunnelVariables } from '~/types/generated/createFunnel';
import { getMyFunnel, getMyFunnelVariables } from '~/types/generated/getMyFunnel';
import { DutyPackageName } from '~/types/graphql-global-types';
import { useActiveHouseId } from './useActiveHouseId';
import { useCustomerDutyPackage } from './useDutyPackage';
import { useIsInitializing } from './useIsInitializing';

const CREATE_FUNNEL = gql`
  mutation createFunnel($houseId: ID!, $dutyPackage: String!, $couponCode: String) {
    createFunnel(houseId: $houseId, dutyPackage: $dutyPackage, couponCode: $couponCode) {
      id
    }
  }
`;

interface FunnelInput {
  dutyPackage: DutyPackageName | string;
  couponCode?: string;
}

export const useStartFunnel = (input: FunnelInput) => {
  const router = useRouter();
  const { activeHouseId } = useActiveHouseId();

  const [createFunnelMutation, { loading, error }] = useMutation<
    createFunnel,
    createFunnelVariables
  >(CREATE_FUNNEL, {
    variables: {
      houseId: activeHouseId,
      ...input,
    },
    onCompleted: () => router.push('/intake'),
    onError: e => {
      console.log('Error creating the funnel', e);
      // We can safely redirect to /intake anyways, a fallback to a Complete package will be shown
      void router.push('/intake');
    },
  });

  return { startFunnel: () => createFunnelMutation(), loading, error };
};

// FIXME: the Price query expects IDs as type String but should be IDs (like House). Ugly duplication as a workaround.
const GET_MY_FUNNEL = gql`
  ${OrderItemFragment}

  query getMyFunnel($houseId: ID!, $houseIdString: String!) {
    house(id: $houseId) {
      funnel {
        orderItem {
          ...OrderItem
          price(requesterId: "", requesterType: "Lead", houseId: $houseIdString)
        }
        discount {
          id
          code
        }
      }
    }
  }
`;

/**
 * Looks up the Funnel and corresponding OrderItem for the active house (similar to useCustomerDutyPackage).
 * Defaults to a Complete OrderItem if no Funnel was found.
 *
 * @returns
 */
export const useFunnel = () => {
  const { activeHouseId: houseId } = useActiveHouseId();
  const [initialHouseId] = useState(houseId);
  const { isInitializing } = useIsInitializing();

  const {
    data: funnelData,
    error,
    loading,
  } = useQuery<getMyFunnel, getMyFunnelVariables>(GET_MY_FUNNEL, {
    variables: {
      // when house ID changes, avoid re-fetching the package: would be nice in theory, but it breaks re-renders (RequestForm disappears)
      houseId: initialHouseId,
      houseIdString: initialHouseId,
    },
    skip: isInitializing,
  });

  const funnel = funnelData?.house.funnel;
  const dutyPackage = funnelData?.house.funnel?.orderItem;

  const completeResult = useCustomerDutyPackage(DutyPackageName.complete);

  return {
    funnel,
    discount: loading ? undefined : funnel?.discount,
    dutyPackage: loading ? undefined : dutyPackage || completeResult.dutyPackage,
    error: error ? new Error('Er is iets mis gegaan, probeer het later nog eens.') : undefined,
    loading,
  };
};
