interface Props {
  size: number;
  clipPathId?: string;
  color?: string;
  invert?: boolean;
}

/**
 * If using this as a clipping mask for an image, the size prop needs to be the same dimension as the parent container you are wrapping your image with.
 * ie: your image is in a box that is 200 x 200, <LogoCutout size={200}/>
 */
const LogoCutout: React.FC<Props> = ({ size, clipPathId, color, invert }) => {
  const logoPath =
    'M361.464 343H0.00440374C-0.342733 234.483 19.8676 129.838 56.701 32.1499C66.6664 5.44784 98.1622 -6.8787 125.413 3.87692L380.383 109.149C395.476 115.983 403.244 133.554 397.341 148.606C374.07 209.196 361.293 275.038 361.464 343Z';
  const invertedLogoPath =
    'M380.379 233.567L126.812 339.3C99.8098 350.274 68.7251 336.878 58.114 310.681C30.6367 239.313 12.7585 164.107 4.48145 85.0618C1.56396 56.4836 0.0942383 28.1118 0.00439453 0H361.464C361.508 17.416 362.402 34.9713 364.188 52.6133C368.759 102.435 380.234 149.229 397.999 194.13C404.015 209.385 395.984 226.972 380.379 233.567Z';

  const scaleX = size / 400;
  const scaleY = size / 343;

  const path = (
    <path
      d={invert ? invertedLogoPath : logoPath}
      fill={color ? `#${color}` : 'white'}
      transform={`scale(${scaleX} ${scaleY})`}
    />
  );

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {clipPathId ? <clipPath id={clipPathId}>{path}</clipPath> : path}
    </svg>
  );
};

export default LogoCutout;
