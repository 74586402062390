import { gql, useMutation } from '@apollo/client';
import { Box, Button, Card, Flex, Radio, RadioGroup } from '@energiebespaarders/symbols';
import { Bold, Small } from '@energiebespaarders/symbols/helpers';
import { CaretRightDouble } from '@energiebespaarders/symbols/icons/solid';
import { useState } from 'react';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';

type Bucket = 'red' | 'orange' | 'green';

const BYPASS_CUSTOMER_INTAKE = gql`
  mutation bypassCustomerIntake($houseId: ID!, $bucket: String!) {
    bypassCustomerIntake(houseId: $houseId, bucket: $bucket) {
      submittedOn
      bucket
      canPlanOwnVisit
    }
  }
`;

const options = [
  { value: 'green', label: 'Green' },
  { value: 'red', label: 'Red' },
];

/**
 * A dev-tools panel for skipping the TypeForm and directly submitting
 * the result of the form (mainly for setting the "intake bucket" variable).
 * Intended to be used only in non-production environments for simulating the
 * self-serivce intake form which only really works in production
 *
 * Shows a dropdown for the red, orange and green bucket options and a submit button
 */
const SelfServiceIntakeDevTools: React.FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
  const { activeHouseId } = useActiveHouseId();
  const [bucket, setBucket] = useState<Bucket | 'gray'>('gray');

  const [bypassCustomerIntake, { loading, error }] = useMutation(BYPASS_CUSTOMER_INTAKE, {
    variables: {
      houseId: activeHouseId,
      bucket,
    },
    onCompleted: onSubmit,
    refetchQueries: ['customerIntakeByHouse'],
  });

  return (
    <Card bgColor={`${bucket}Lighter`} borderColor={bucket} p={2} style={{ borderStyle: 'dashed' }}>
      <form
        onSubmit={e => {
          e.preventDefault();
          void bypassCustomerIntake();
        }}
      >
        <Flex flexWrap="wrap" alignItems="flex-end" minWidth="300px" mx={-1}>
          <Box width={1} px={1} textAlign="center">
            <Small>
              <Bold>DEV TOOLS</Bold>
            </Small>
          </Box>
          <Box width={[1, 1, 2 / 3]} px={1}>
            <RadioGroup
              value={options.find(o => o.value === bucket)}
              onChange={e => setBucket(e.value as Bucket)}
              fontSize={6}
              name="bucket"
              mb={0}
              label="Bucket"
            >
              {options.map(option => (
                <Radio
                  id={option.value}
                  key={option.value}
                  value={option}
                  label={option.label}
                  bgColor={`${option.value}Dark`}
                  mb={0}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box width={[1, 1, 1 / 3]} px={1}>
            <Button
              fluid
              type="submit"
              bgColor={bucket}
              loading={loading}
              error={error?.message}
              iconEnd={CaretRightDouble}
              fontSize={6}
              mb={0}
            >
              Skip
            </Button>
          </Box>
        </Flex>
      </form>
    </Card>
  );
};

export default SelfServiceIntakeDevTools;
