import { gql } from '@apollo/client';

export const OmniformQuestionOptionFragment = gql`
  fragment OmniformQuestionOptionFragment on OmniformQuestionOption {
    ... on BooleanOption {
      label
      booleanValue: value
    }
    ... on DateOption {
      label
      dateValue: value
    }
    ... on FloatOption {
      label
      floatValue: value
    }
    ... on StringOption {
      label
      stringValue: value
    }
  }
`;

export const OmniformAnswerFragment = gql`
  fragment OmniformAnswer on OmniformAnswer {
    questionKey
    questionRevision
    answerType
    answeredAt
    ... on OmniformBooleanAnswer {
      booleanAnswer: answer
    }
    ... on OmniformDateAnswer {
      dateAnswer: answer
    }
    ... on OmniformNumericAnswer {
      numericAnswer: answer
    }
    ... on OmniformStringAnswer {
      textAnswer: answer
    }
    ... on OmniformBooleanArrayAnswer {
      booleanArrayAnswer: answer
    }
    ... on OmniformDateArrayAnswer {
      dateArrayAnswer: answer
    }
    ... on OmniformNumericArrayAnswer {
      numericArrayAnswer: answer
    }
    ... on OmniformStringArrayAnswer {
      stringArrayAnswer: answer
    }
  }
`;

export const OmniformQuestionFragment = gql`
  ${OmniformQuestionOptionFragment}
  fragment OmniformQuestion on OmniformQuestion {
    id
    key
    revision
    type
    question
    description
    helpContentCMSId
    isOptional
    answerType
    unit
    options {
      ...OmniformQuestionOptionFragment
    }
    range
  }
`;

export const OmniformResponseFragment = gql`
  ${OmniformAnswerFragment}
  fragment OmniformResponse on OmniformResponse {
    id
    formKey
    formRevision
    responder {
      __typename
      id
    }
    answers {
      ...OmniformAnswer
    }
    context
  }
`;

export const GET_OMNIFORM = gql`
  ${OmniformQuestionFragment}
  query getOmniform($key: String!, $revision: Int) {
    getOmniform(key: $key, revision: $revision) {
      id
      key
      revision
      title
      questions {
        ...OmniformQuestion
      }
      flow {
        nodes {
          id
          type
          data {
            ... on OmniformQuestion {
              ...OmniformQuestion
            }
            ... on OmniformStatement {
              key
              text
            }
          }
        }
        edges {
          id
          source
          target
          condition
        }
      }
    }
  }
`;

export const FIND_OMNIFORM_RESPONSE = gql`
  ${OmniformResponseFragment}
  query findOmniformResponse(
    $key: String!
    $responderId: ID!
    $responderType: UserType!
    $context: JSONObject!
  ) {
    findOmniformResponse(
      key: $key
      responderId: $responderId
      responderType: $responderType
      context: $context
    ) {
      ...OmniformResponse
    }
  }
`;

export const START_OMNIFORM_RESPONSE = gql`
  ${OmniformResponseFragment}
  mutation startOmniformResponse(
    $key: String!
    $responderId: ID!
    $responderType: UserType!
    $context: JSONObject!
  ) {
    startOmniformResponse(
      key: $key
      responderId: $responderId
      responderType: $responderType
      context: $context
    ) {
      ...OmniformResponse
    }
  }
`;

export const SAVE_OMNIFORM_RESPONSE = gql`
  ${OmniformResponseFragment}
  mutation saveOmniformResponse(
    $responseId: ID!
    $answers: [OmniformAnswerInput!]!
    $lastSeenNode: String
  ) {
    saveOmniformResponse(responseId: $responseId, answers: $answers, lastSeenNode: $lastSeenNode) {
      ...OmniformResponse
    }
  }
`;

export const SUBMIT_OMNIFORM_RESPONSE = gql`
  ${OmniformResponseFragment}
  mutation submitOmniformResponse($responseId: ID!, $answers: [OmniformAnswerInput!]!) {
    submitOmniformResponse(responseId: $responseId, answers: $answers) {
      ...OmniformResponse
    }
  }
`;
