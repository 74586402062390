import { useInterval, useIsMobile } from '@energiebespaarders/hooks';
import { Box, Button, Flex, Separator, TextLink, theme } from '@energiebespaarders/symbols';
import { Heading, Left, Medium, Small } from '@energiebespaarders/symbols/helpers';
import { Mail } from '@energiebespaarders/symbols/icons/solid';
import { CheckFat, UserCircleCheck } from '@phosphor-icons/react';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useFlag } from 'react-unleash-flags';
import styled from 'styled-components';
import { useMe } from '~/hooks/useMe';
import { me_me_Customer, me_me_Lead } from '~/types/generated/me';
import SocialLogin from '../AuthenticationForms/SocialLogin';
import OmniformCard, { OmniformPrevArrow } from '../Omniform/OmniformCard';
import MinimalSignupForm from './MinimalSignupForm';

const StyledTermsDisclaimer = styled.p`
  font-size: ${theme.type.scale[7]};
  color: ${theme.colors.grayDark};

  & a {
    color: ${theme.colors.grayDark};
    text-decoration: underline;
    text-decoration-color: ${theme.colors.gray};
  }
`;

const AcceptTerms = () => (
  <StyledTermsDisclaimer>
    Door in te loggen ga je akkoord met de{' '}
    <Link href="/voorwaarden">
      <a target="_blank" title="Lees onze algemene voorwaarden">
        algemene voorwaarden
      </a>
    </Link>{' '}
    en{' '}
    <Link href="/privacy">
      <a target="_blank" title="Lees onze privacyverklaring">
        privacyverklaring
      </a>
    </Link>
    .
  </StyledTermsDisclaimer>
);

interface IntakeAuthCardProps {
  onPrev: () => void;
  onLoginSuccess: () => void;
}

const IntakeAuthCard: React.FC<IntakeAuthCardProps> = ({ onPrev, onLoginSuccess }) => {
  const { me } = useMe<me_me_Lead | me_me_Customer>();
  const isMobile = useIsMobile();
  const identityProvidersFlag = useFlag('identity-providers');
  const [showEmailLogin, setShowEmailLogin] = useState(false);

  const shouldShowLoginConfirmation =
    me?.__typename === 'Customer' && window.location.hash === '#start';

  useEffect(() => {
    if (me?.__typename !== 'Customer') return;
    if (!shouldShowLoginConfirmation) onLoginSuccess();
  }, [me?.__typename, onLoginSuccess, shouldShowLoginConfirmation]);

  const [loginConfirmationCountdown, setLoginConfirmationCountdown] = useState(2000);

  useInterval(() => {
    // Only show the login confirmation when #start is in the URL
    if (me?.__typename !== 'Customer') return;
    if (shouldShowLoginConfirmation && loginConfirmationCountdown > 0) {
      setLoginConfirmationCountdown(loginConfirmationCountdown - 100);
    } else return onLoginSuccess();
  }, 100);

  const checks = [
    <>
      <Medium>Direct inzicht</Medium> in welke producten mogelijk zijn voor jouw woning
    </>,
    <>
      <Medium>Kosten en besparingen</Medium> van producten ingeschat
    </>,
    <>
      <Medium>Plan een advies met offertes</Medium> met een van onze energieadviseurs
    </>,
  ];

  return (
    <>
      <OmniformCard p={isMobile ? 4 : 8} animation="none" id="omniform-auth">
        {shouldShowLoginConfirmation ? (
          <Box p={2} textAlign="center">
            <UserCircleCheck size={64} color={theme.colors.green} weight="duotone" />
            <Heading heading="h2" style={{ lineHeight: isMobile ? 1.2 : 1 }}>
              Welkom {me.firstName}!
            </Heading>
            <p>Je bent succesvol ingelogd. Je wordt automatisch doorgestuurd.</p>
          </Box>
        ) : (
          <>
            <Box pt={isMobile ? 0 : 2} pb={isMobile ? 2 : 0}>
              <OmniformPrevArrow onClick={onPrev} />
            </Box>
            <Flex flexWrap="wrap" alignItems="center" mx={-2}>
              <Box width={[1, 1, 1, 2 / 5]} px={2}>
                <Heading heading="h2" style={{ lineHeight: isMobile ? 1.2 : 1 }}>
                  Ontdek hoe jij kunt verduurzamen
                </Heading>
              </Box>

              <Box width={[1, 1, 1, 3 / 5]} px={2}>
                <Flex
                  flexWrap="wrap"
                  alignItems="flex-end"
                  mx={-1}
                  flexDirection={['column-reverse', 'row']}
                >
                  <Box width={1} px={1}>
                    {checks.map((check, index) => (
                      <Flex mx={-1} key={index} pb={1}>
                        <Box px={1}>
                          <CheckFat color={theme.colors.green} weight="duotone" size={24} />
                        </Box>
                        <Box px={1}>
                          <p>{check}</p>
                        </Box>
                      </Flex>
                    ))}
                  </Box>
                  <Box width={1} px={1} textAlign="center">
                    <Separator my={isMobile ? 2 : 3} />
                    <p>
                      <Medium>Log in om je voortgang op te slaan</Medium>
                    </p>
                    {identityProvidersFlag?.enabled && !showEmailLogin ? (
                      <>
                        <SocialLogin
                          buttonProps={{ fluid: true, mb: 0, style: { borderWidth: 2 } }}
                          redirectUrl="/intake#start"
                        />
                        <Button
                          bgColor="grayDark"
                          style={{ borderRadius: 3, whiteSpace: 'nowrap' }}
                          iconStart={Mail}
                          fluid
                          px={5}
                          onClick={() => setShowEmailLogin(true)}
                          mt={1}
                          mr={0}
                        >
                          Of ga door met e-mail
                        </Button>
                      </>
                    ) : (
                      <Left block>
                        <Small onClick={() => setShowEmailLogin(false)}>
                          <TextLink>
                            ← Log in met <Medium>social</Medium>
                          </TextLink>
                        </Small>
                        <MinimalSignupForm />
                      </Left>
                    )}
                    <AcceptTerms />
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </>
        )}
      </OmniformCard>
    </>
  );
};

export default IntakeAuthCard;
