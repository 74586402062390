import { useIsMobile } from '@energiebespaarders/hooks';
import { Box, Flex, theme } from '@energiebespaarders/symbols';
import { Heading, Larger, Medium } from '@energiebespaarders/symbols/helpers';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import { readableColor } from 'polished';
import { Image, ResponsiveImageType } from 'react-datocms';
import styled from 'styled-components';
import useMediaQuery from '~/hooks/useMediaQuery';
import { CTASectionBlockFragment } from '~/types/generated/cms/CTASectionBlockFragment';
import BlockContainer from '../DatoCms/BlockContainer';
import LogoCutout from '../DatoCms/LogoCutout';
import TypedStructuredText from '../DatoCms/TypedStructuredText';
import DatoButton from './DatoButton';

interface Props {
  block: CTASectionBlockFragment;
}

const Content = styled(Flex)<{ $isMobile: boolean; $bgColor: string }>`
  min-height: ${x => (!x.$isMobile ? '420px' : 'unset')};
  color: ${x =>
    readableColor(themify(x.$bgColor) || 'white', themify('grayBlack'), 'white', false)};
`;

const PriceContainer = styled(Box)<{ $width: number }>`
  position: absolute;
  bottom: 0px;
  width: ${x => x.$width}px;
`;

const ClippedImage = styled(Image)`
  clip-path: url(#CTASectionClipPath);
`;

const Price = styled(Box)`
  font-size: ${theme.type.scale[4]};
  font-weight: 600;

  ${mediaMin.md} {
    font-size: ${theme.type.scale[2]};
  }
`;

const PriceContent = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const MobilePriceOverlay = styled(Box)`
  width: 70%;
  border-radius: 0 6px 6px 0;
  background: white;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1;
`;

interface ImageAndPriceCutoutProps {
  block: CTASectionBlockFragment;
  svgImageDimensions: number;
  svgPriceDimensions: number;
}

const ImageAndPriceCutout: React.FC<ImageAndPriceCutoutProps> = ({
  block,
  svgImageDimensions,
  svgPriceDimensions,
}) => {
  return (
    <>
      <Box style={{ position: 'absolute', height: 'calc(100% + 36px)', bottom: '0px' }}>
        <ClippedImage
          data={block.ctaSection!.image!.responsiveImage!}
          objectFit="cover"
          style={{ position: 'absolute', overflow: 'unset', bottom: '0px' }}
          usePlaceholder
        />
        <LogoCutout size={svgImageDimensions} clipPathId="CTASectionClipPath" />
      </Box>

      {!block.hideCallout && (
        <PriceContainer $width={svgPriceDimensions}>
          <Box width={1} height="100%" style={{ position: 'relative' }}>
            <LogoCutout size={svgPriceDimensions} />

            <PriceContent alignItems="center" justifyContent="center" flexDirection="column" pt={8}>
              <Box>{block.ctaSection?.priceTitle}</Box>

              <Flex>
                <Price>€ {block.ctaSection?.price}</Price>
                <Box>
                  <strong>/ {block.ctaSection?.period}</strong>
                </Box>
              </Flex>
            </PriceContent>
          </Box>
        </PriceContainer>
      )}
    </>
  );
};

const CTASection: React.FC<Props> = ({ block }) => {
  const isSmallMobile = useMediaQuery('sm');
  const isMobile = useIsMobile();
  const svgImageDimensions = isSmallMobile ? 400 : isMobile ? 500 : 600;
  const svgPriceDimensions = isMobile ? 180 : 200;

  const imageAndPriceCutoutProps = {
    block,
    svgImageDimensions,
    svgPriceDimensions,
  };

  return (
    <>
      {isMobile && (
        <Box width={1}>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image
            data={block.ctaSection!.image!.mobileResponsiveImage as ResponsiveImageType}
            objectFit="cover"
            style={{ height: 200, width: '100%', maxWidth: '100%', objectPosition: 'center' }}
          />
          {!block.hideCallout && (
            <MobilePriceOverlay p={2} textAlign="center">
              <Box>{block.ctaSection?.priceTitle}</Box>
              <Box>
                <Larger>
                  <Medium>
                    € {block.ctaSection?.price} / {block.ctaSection?.period}
                  </Medium>
                </Larger>
              </Box>
            </MobilePriceOverlay>
          )}
        </Box>
      )}

      <BlockContainer
        backgroundColor={block.bgColor?.hex || 'greenLight'}
        size="xl"
        mt={isMobile ? 0 : 6}
        containerCustomStyle={{ position: 'unset' }}
        style={{ position: 'relative' }}
      >
        <Content width={1} flexWrap="wrap" $isMobile={isMobile} $bgColor="green">
          <Flex flexWrap="wrap">
            <Box width={[1, 1, 1 / 2]} pt={[6, 6, 0]}>
              <Heading heading="h2">{block.ctaSection?.title}</Heading>
              <Box pb={4}>
                {isMobile ? (
                  <p>{block.ctaSection?.mobileText}</p>
                ) : (
                  <TypedStructuredText data={block.ctaSection!.newText[0]?.structuredText} />
                )}
              </Box>
              <DatoButton block={block.ctaSection!.button[0]!} customStyle={{ margin: 0 }} />
            </Box>

            {!isMobile && (
              <Box width={[1, 1, 1 / 2]} pb={isMobile ? 4 : 0}>
                <ImageAndPriceCutout {...imageAndPriceCutoutProps} />
              </Box>
            )}
          </Flex>
        </Content>
      </BlockContainer>
    </>
  );
};

export default CTASection;
