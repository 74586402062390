import { Box, Button, Container, Flex, Icon, theme } from '@energiebespaarders/symbols';
import { Small } from '@energiebespaarders/symbols/helpers';
import { ArrowLeft } from '@energiebespaarders/symbols/icons/line';
import { useContext, useEffect, useState } from 'react';
import { useKeyPressEvent } from 'react-use';
import OmniformCard from '../Omniform/OmniformCard';
import { IntakeExitContext } from './IntakeExitContext';
import { useExitScenarios } from './useExitScenarios';

interface ExitCardProps {
  onPrevious: () => void;
}

const IntakeExitCard: React.FC<ExitCardProps> = ({ onPrevious }) => {
  useKeyPressEvent('ArrowLeft', onPrevious);
  const [exitScenario] = useContext(IntakeExitContext);
  const exitContent = useExitScenarios(exitScenario!);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const cardId = `omniform-exit-${exitScenario}`;

  useEffect(() => {
    if (isSubmitted) return;
    // Append a submit button to the card and natively submit the form on render
    const submitButton = document.createElement('button');
    submitButton.type = 'submit';
    submitButton.style.display = 'none';

    document.getElementById(cardId)?.appendChild(submitButton);
    if (!submitButton) return;

    submitButton.click();

    setIsSubmitted(true);
  }, [cardId, isSubmitted, exitScenario]);

  return (
    <OmniformCard p={4} animation="none" id={cardId}>
      <Flex flexWrap="wrap">
        <Box width={1}>
          <Icon icon={ArrowLeft} stroke="gray" onClick={onPrevious} />
        </Box>

        <Box width={1} pt={5}>
          <Container size="md">{exitContent}</Container>
        </Box>
        <Box width={1} textAlign="center" pt={5} pb={10}>
          <Small>
            <p>Foutje gemaakt in de antwoorden?</p>
          </Small>
          <Button
            bgColor="grayDark"
            minimal
            onClick={onPrevious}
            style={{ borderRadius: 3, borderColor: theme.colors.grayDark }}
            py={2}
            px={10}
            mr={0}
            mb={0}
          >
            ← Terug
          </Button>
        </Box>
      </Flex>
    </OmniformCard>
  );
};

export default IntakeExitCard;
