import { Box, Flex, Icon, theme } from '@energiebespaarders/symbols';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { containers } from '@energiebespaarders/symbols/styles/theme';
import { ReactNode } from 'react';
import styled from 'styled-components';
import BlockContainer from './DatoCms/BlockContainer';

export interface IStep {
  id: string;
  title: string;
  text: string;
  icon: ReactNode;
  hideDottedLine?: boolean;
}

export interface StepperProps {
  title: string;
  steps: IStep[];
  backgroundColor?: string;
  containerSize: keyof typeof containers;
}

const StyledSectionTitle = styled.h2`
  text-align: center;
  font-weight: 500;
`;

const DottedLine = styled(Box)`
  width: 100%;
  border: 0px;
  border-bottom: 1px black;
  border-style: dashed solid;
  opacity: 0.5;
`;

const StyledIcon = styled(Icon)`
  span {
    line-height: 0px;
  }

  transform: rotate(100deg);
  margin-right: 18px;
`;

const StepName = styled(Box)`
  font-weight: 600;
  font-size: 22px;
`;

const StepContainer = styled(Box)<{ $isSmallScreen: boolean }>`
  min-height: ${x => (x.$isSmallScreen ? '150px' : '200px')};
`;

const Stepper: React.FC<StepperProps> = ({ title, steps, backgroundColor, containerSize }) => {
  const isMobile = useIsMobile();

  return (
    <BlockContainer
      size={containerSize}
      backgroundColor={backgroundColor || theme.colors.greenSlate}
      py={isMobile ? 4 : 10}
    >
      <Flex flexDirection="column">
        <StyledSectionTitle>{title}</StyledSectionTitle>
        <Flex mx={-4} flexWrap="wrap">
          {steps?.map(c => {
            return (
              <StepContainer
                $isSmallScreen={isMobile}
                width={[1, 1, 1 / 3]}
                mb={[4, 4, 0]}
                key={c.id}
                px={4}
              >
                <Flex style={{ opacity: 0.7 }} width={1} alignItems="center" mb={2}>
                  <StyledIcon icon={c.icon} />
                  {!c.hideDottedLine && <DottedLine />}
                </Flex>

                <StepName>{c.title}</StepName>
                <p>{c.text}</p>
              </StepContainer>
            );
          })}
        </Flex>
      </Flex>
    </BlockContainer>
  );
};

export default Stepper;
