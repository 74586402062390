import { Box } from '@energiebespaarders/symbols';
import { StructuredText, StructuredTextGraphQlResponse } from 'react-datocms';
import { BlockRenderer, BlockRendererOverrides, CMSBlock } from './BlockRenderer';
import InlineRecordRenderer from './InlineRecordRenderer';

export type CMSStructuredTextData = {
  readonly __typename: string;
  readonly blocks: ReadonlyArray<CMSBlock>;
  readonly links: ReadonlyArray<string>;
  readonly value: any;
};

interface Props {
  data: CMSStructuredTextData | null;
  overrides?: BlockRendererOverrides;
}

// TODO: add more props and type it better ~ Remi, make it faster, better, and stronger
const TypedStructuredText: React.FC<Props> = ({ data, overrides }) => {
  return (
    <Box className="dato-cms">
      <StructuredText
        data={data as unknown as StructuredTextGraphQlResponse}
        renderBlock={b => (
          <BlockRenderer block={b.record as unknown as CMSBlock} overrides={overrides} />
        )}
        renderInlineRecord={InlineRecordRenderer}
      />
    </Box>
  );
};

export default TypedStructuredText;
