import { Box, Flex, theme } from '@energiebespaarders/symbols';
import { Image } from 'react-datocms';
import styled from 'styled-components';
import useMediaQuery from '~/hooks/useMediaQuery';
import { dummy_dummy_content_PrimaryImageRecord } from '~/types/generated/cms/dummy';
import BlockContainer from '../DatoCms/BlockContainer';
import PrimaryImageBlockCard from './PrimaryImageBlockCard';

interface Props {
  block: dummy_dummy_content_PrimaryImageRecord;
}

const ImageContainer = styled(Box)<{ $isMobile: boolean }>`
  position: relative;
  height: ${x => (x.$isMobile ? '300px' : '480px')};
  width: 100%;
`;

const StyledImage = styled(Image)<{ $isMobile: boolean }>`
  height: 100%;
  width: 100%;
  border-radius: 12px;
  max-width: unset !important;

  picture img {
    object-fit: cover !important;
  }
`;

const ImageText = styled(Box)<{ $textColor: string; $isMobile: boolean }>`
  font-size: ${x => (x.$isMobile ? theme.type.scale[2] : theme.type.scale[1])};
  font-family: Causten, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: ${x => x.$color};

  position: absolute;
  bottom: 80px;
  left: ${x => (x.$isMobile ? '28px' : '54px')};
  width: ${x => (x.$isMobile ? 'calc(100% - 2 * 28px)' : 'calc(100% - 2 * 54px)')};
  z-index: 2;
`;

const GradientOverlay = styled(Flex)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 33%, rgba(0, 0, 0, 0.66));
  border-radius: 12px;
  z-index: 1;
`;

const PrimaryImage: React.FC<Props> = ({ block }) => {
  const isMobile = useMediaQuery('md');

  return (
    <BlockContainer>
      <Flex flexDirection="column" width={1}>
        <ImageContainer $isMobile={isMobile}>
          <GradientOverlay $isMobile={isMobile} />
          <StyledImage data={block.image!.responsiveImage!} $isMobile={isMobile} />
          <ImageText $isMobile={isMobile} $color={block.textColor}>
            {block.text}
          </ImageText>
        </ImageContainer>

        {!!block.primaryImageCard.length && (
          <PrimaryImageBlockCard block={block.primaryImageCard[0]} />
        )}
      </Flex>
    </BlockContainer>
  );
};

export default PrimaryImage;
