import { Record as DatoRecord } from 'datocms-structured-text-utils';
import { FaqItemFragment } from '~/types/generated/cms/FaqItemFragment';
import FaqItem from '../FAQ/FaqItem';

export type InlineRecordRendererOverrides = Partial<
  Record<string, React.FC<{ record: DatoRecord }>>
>;

interface InlineRecordRendererProps {
  record: DatoRecord;
  overrides?: InlineRecordRendererOverrides;
}

/**
 * Similar to the BlockRenderer, but for rendering inline records:
 * Embedding CMS documents of one type in another, such as FAQ Items in an OmniformHelp item.
 */
export function InlineRecordRenderer({ record, overrides }: InlineRecordRendererProps) {
  const type = record.__typename;

  if (overrides && type in overrides) {
    const Override = overrides[type]!;
    return <Override record={record} />;
  }

  switch (type) {
    case undefined:
      return <></>;

    case 'FaqItemRecord':
      return <FaqItem item={record as unknown as FaqItemFragment} />;

    default:
      return <></>;
  }
}

export default InlineRecordRenderer;
