/* eslint-disable @typescript-eslint/no-use-before-define --- This rule becomes impossible to satisfy with any logical sense in this file. */
import { isNull } from 'lodash';
import { isEmpty } from '~/utils/isEmpty';
import {
  EvaluateConditionError,
  InvalidFlowConditionError,
  UnresolvedVariableError,
} from './errors';

// Warning! Everything below this line is a copy of `src/omniform/flows/errors.ts` from GQL
// ------------------------------------------------------------------------------------------------

/** Dates can differ by at most 1 second for them to still be considered equal. */
const DATE_MAX_DELTA_MS = 1000;

// ------------------------------------------------------------------------------------------------

function evaluate<T extends Record<string, any>>(
  condition: FlowCondition<T> | undefined,
  data: T,
): boolean {
  // an empty condition trivially holds
  if (!condition || isEmpty(condition)) return true;

  // FlowCondition contains Condition objects for each of its keys. Every condition must hold for the FlowCondition to return true.
  if (typeof condition === 'object') {
    return (Object.keys(condition) as (keyof FlowCondition<T>)[]).every(key => {
      if (typeof key === 'number' || typeof key === 'symbol') return true;

      if (key === '$or')
        return condition.$or?.some(subCondition => evaluateCondition(subCondition, data, data));
      if (key === '$not') return !evaluate(condition.$not, data);

      const value = resolve(data, key);
      const subCondition = condition[key];
      if (!subCondition) return equals(value, subCondition);

      return evaluateCondition(subCondition, value!, data);
    });
  }

  throw new InvalidFlowConditionError('expecting an object or undefined', condition);
}

function evaluateCondition<T, Data extends Record<string, any>>(
  condition: Condition<T>,
  value: T,
  data: Data,
): boolean {
  // A Condition is either a ValueCondition or a nested condition object.
  if (isValueCondition(condition)) {
    return evaluateValueCondition(condition, value as Value, data);
  }

  try {
    // Otherwise, condition is another Condition object, on which every condition must hold for this condition to return true
    if (typeof condition === 'object' && !isNull(condition)) {
      return (Object.keys(condition) as (keyof Condition<Record<string, any>>)[]).every(key => {
        if (typeof key === 'number' || typeof key === 'symbol') return true;

        const subValue = resolve(value as Record<string, any>, key);
        const subCondition = condition[key];

        return evaluateCondition(subCondition, subValue, data);
      });
    }
  } catch (err) {
    throw new EvaluateConditionError(condition, value).withCause(err);
  }

  throw new InvalidFlowConditionError(
    'expecting value condition or nested condition object',
    condition,
  );
}

function evaluateValueCondition<T extends Value, Data extends Record<string, any>>(
  condition: ValueCondition<T>,
  value: T,
  data: Data,
): boolean {
  let result = true;
  let touched = false;

  try {
    // ValueConditions are either null, literal values, arrays of literal values, variable references, a boolean operator or a comparator.
    // If condition is null, a literal value or a variable reference, then this is equivalent to $eq so we must call equals.
    if (isNull(condition)) return equals(value as Literal, condition);
    // If condition is an array of literal values, then we check if the value contains all of the literal values.
    if (isLiteralArray(condition)) return leftContainsRight(value as Literal[], condition);
    if (isLiteral(condition)) return equals(value as Literal, condition as Literal);
    if (isVariable(condition)) return equals(value as Literal<T>, resolve(data, condition.var));

    if (isGenericComparators(condition)) {
      result = result && evaluateGenericComparators(condition, value, data);
      touched = true;
    }

    if (isBooleanOperators(condition)) {
      result = result && evaluateBooleanOperators(condition, value, data);
      touched = true;
    }

    if (isNumericComparators(condition)) {
      result =
        result &&
        evaluateNumericComparators(
          condition as NumericComparators<Value<number | Date>>, // note: this cast shouldn't be necessary as isGenericComparators already performs this type guard, but for some reason TS requires it...
          value,
          data,
        );
      touched = true;
    }

    if (isArrayOperators(condition)) {
      result = result && evaluateArrayOperators(condition, value as Literal[]);
      touched = true;
    }
  } catch (err) {
    throw new EvaluateConditionError(condition, value).withCause(err);
  }

  if (!touched) throw new InvalidFlowConditionError('expecting a value condition', condition);
  return result;
}

// ------------------------------------------------------------------------------------------------

function evaluateGenericComparators<T extends Value, Data extends Record<string, any>>(
  condition: GenericComparators<T>,
  value: T,
  data: Data,
): boolean {
  let result = true;

  if (condition.$eq !== undefined) {
    if (isNull(condition.$eq)) result = result && equals(value as Literal, condition.$eq);
    if (isLiteral(condition.$eq) || isLiteralArray(condition.$eq))
      result = result && equals(value as Literal, condition.$eq as Literal);
    if (isVariable(condition.$eq))
      result = result && equals(value as Literal<T>, resolve(data, condition.$eq.var));
  }

  if (condition.$ne !== undefined) {
    if (isNull(condition.$ne)) result = result && !equals(value as Literal, condition.$ne);
    if (isLiteral(condition.$ne) || isLiteralArray(condition.$ne))
      result = result && !equals(value as Literal, condition.$ne as Literal);
    if (isVariable(condition.$ne))
      result = result && !equals(value as Literal<T>, resolve(data, condition.$ne.var));
  }

  return result;
}

function evaluateNumericComparators<
  T extends Value<number | Date>,
  Data extends Record<string, any>,
>(condition: NumericComparators<T>, value: T, data: Data): boolean {
  let result = true;

  // numbers cannot be compared to null or undefined, will always return false.
  if (value == null) return false; // eslint-disable-line eqeqeq --- != null is intentional here, we want ensure v is not null or undefined.

  if (condition.$gt !== undefined) {
    if (isLiteral(condition.$gt)) result = result && compare(condition.$gt, value) > 0;
    if (isVariable(condition.$gt)) {
      const varValue = resolve(data, condition.$gt.var);
      result = result && varValue != null && compare(varValue, value) > 0; // eslint-disable-line eqeqeq --- != null is intentional here, we want ensure v is not null or undefined.
    }
  }

  if (condition.$gte !== undefined) {
    if (isLiteral(condition.$gte)) result = result && compare(condition.$gte, value) >= 0;
    if (isVariable(condition.$gte)) {
      const varValue = resolve(data, condition.$gte.var);
      result = result && varValue != null && compare(varValue, value) >= 0; // eslint-disable-line eqeqeq --- != null is intentional here, we want ensure v is not null or undefined.
    }
  }

  if (condition.$lt !== undefined) {
    if (isLiteral(condition.$lt)) result = result && compare(value, condition.$lt) > 0;
    if (isVariable(condition.$lt)) {
      const varValue = resolve(data, condition.$lt.var);
      result = result && varValue != null && compare(value, varValue) > 0; // eslint-disable-line eqeqeq --- != null is intentional here, we want ensure v is not null or undefined.
    }
  }

  if (condition.$lte !== undefined) {
    if (isLiteral(condition.$lte)) result = result && compare(value, condition.$lte) >= 0;
    if (isVariable(condition.$lte)) {
      const varValue = resolve(data, condition.$lte.var);
      result = result && varValue != null && compare(value, varValue) >= 0; // eslint-disable-line eqeqeq --- != null is intentional here, we want ensure v is not null or undefined.
    }
  }

  return result;
}

function evaluateBooleanOperators<T extends Value, Data extends Record<string, any>>(
  condition: BooleanOperators<T>,
  value: T,
  data: Data,
): boolean {
  let result = true;

  // eslint-disable-next-line eqeqeq --- != null is intentional here, we want ensure v is not null or undefined.
  if (condition.$or != null) {
    result =
      result &&
      condition.$or.some(subCondition => {
        return evaluateValueCondition(subCondition, value, data);
      });
  }

  if (condition.$not) {
    result = result && !evaluateValueCondition(condition.$not, value, data);
  }

  return result;
}

function evaluateArrayOperators<T extends Value[]>(
  condition: ArrayOperators<T>,
  value: T,
): boolean {
  // eslint-disable-next-line eqeqeq --- != null is intentional here, we want ensure v is not null or undefined.
  if (condition.$all != null) {
    return value?.every(v => condition.$all.includes(v));
  }
  return false;
}

// ------------------------------------------------------------------------------------------------

/**
 * Returns true if:
 * - value1 and value2 are equal strings / numbers / booleans
 * - value1 and value2 are dates at most {@link DATE_MAX_DELTA_MS} ms apart
 * - value1 and value2 are both null or undefined
 */
export function equals<T1, T2>(
  value1: Literal<T1> | Literal<T1>[] | null | undefined,
  value2: Literal<T2> | Literal<T2>[] | null | undefined,
): boolean {
  if (value1 instanceof Date && value2 instanceof Date)
    return Math.abs(value1.valueOf() - value2.valueOf()) <= DATE_MAX_DELTA_MS;

  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) return false;
    return value1.every(r => value2.some(l => equals(l, r)));
  }

  if (
    (typeof value1 === 'string' && typeof value2 === 'string') ||
    (typeof value1 === 'number' && typeof value2 === 'number') ||
    (typeof value1 === 'boolean' && typeof value2 === 'boolean')
  )
    return value1 === value2;

  // eslint-disable-next-line eqeqeq --- != null is intentional here, we want ensure v is not null or undefined.
  if (value1 == null && value2 == null) return true;

  return false;
}

export function compare(value1: Value<number | Date>, value2: Value<number | Date>): number {
  return (value2.valueOf() as number) - (value1.valueOf() as number);
}

export function leftContainsRight<T>(
  left: Literal<T>[] | null | undefined,
  right: Literal<T>[] | null | undefined,
): boolean {
  if (!left || !right) return false;
  if (left.length < right.length) return false;
  return right.every(r => left.some(l => equals(l, r)));
}

// ------------------------------------------------------------------------------------------------

/**
 * Resolves a reference to a value on `data` to the actual value.
 * Accepts both direct keys of the `data` object, as well as dot-notation paths on `data`.
 *
 * Warning: this will throw an {@link UnresolvedVariableError} when the object that a dot-notation path needs to nest into, does not exist.
 * E.g. trying to resolve 'foo.bar' on `{}` will throw this error.
 * However, trying to resolve 'foo.bar' on `{ foo: {} }`, will return undefined,
 * i.e. this method returns undefined when the last segment of a dot-notation syntax does not exist on the referenced object.
 */
export function resolve<T extends Record<string, any>>(data: T, path: keyof T): T[keyof T];
export function resolve<T extends Record<string, any>>(data: T, path: string): any;
export function resolve<T extends Record<string, any>>(
  data: T,
  path: keyof T | string,
): T[keyof T] | any {
  if (String(path).includes('.')) {
    const segments = String(path).split('.');
    if (!(segments[0] in data)) throw new UnresolvedVariableError(data, path);

    return resolve(data[segments[0]], segments.slice(1).join('.'));
  }

  if (!data) return undefined;
  return data[path];
}

// ------------------------------------------------------------------------------------------------

export const FlowCondition = { evaluate };

/**
 * A FlowCondition is a structure that encodes a function `(data: TValue) => boolean`,
 * i.e. a function that takes some data, evaluates some arbitrary condition on it and returns true or false,
 * meaning that the condition holds on the given data or not.
 *
 * The structure is fairly similar to (and heavily inspired by) MongoDB's query objects.
 * Many of the special operators like `$ne`, `$gt` and `$lte` come directly from MongoDB's syntax.
 * However, this query language is more flexible than MongoDB's queries. Here's some examples to show what I mean.
 *
 * @example { constructionYear: { $gte: 1970, $lt: 1980 }, solutionInterests: { wallInsulation: { $ne: null } } }
 * @example { glazing: { living: { $or: ['single', 'double'] } } }
 * @example { $or: [{ glazing: { living: { $or: ['single', 'double'] } } }, { glazing: { sleeping: { $or: ['single', 'double'] } } }]
 * @example { consumption: { gas: { $gt: { var: 'estimatedConsumption.gas' } } } }
 * @example { solutionInterests: ['wallInsulation'] }
 * @example { solutionInterests: { $eq: ['wallInsulation'] } }
 *
 * @description These examples show a few important implementation details:
 * 1. **Implicit AND (`&&`)**: specifying multiple properties / comparators on the same object means that every one of those properties / comparators must be true.
 *      The first example shows this clearly: it requires that `constructionYear` is both greater than or equal to 1970, as well as less than 1980 (i.e. must be between 1970 and 1979 (inclusive)).
 * 2. **(Nested) Condition objects only need to match the defined properties**: Unlike MongoDB where the top-level query object matches only the defined properties and query sub-objects need to match exactly,
 *      FlowCondition structures always only match on the defined properties. Consider the second example, let's assume that `glazing` not only has a property `living`, but also a property `sleeping`.
 *      In MongoDB queries, you'd have to write `{ 'glazing.living': 'single' }` in order to avoid the query only matching on `glazing` objects that are missing the `sleeping` property.
 *      In FlowConditions, that is not a problem and the query works exactly as you expect.
 * 3. **`$or` can be used as either as top-level operator, or as condition on a single value**: The third example shows that `$or` can be used as a top-level operator, similar to how MongoDB queries allow you to use $or.
 *      However, in FlowConditions, it is also possible to use `$or` as a condition on a single value, shown by the second and third example.
 * 4. **Literal values are the same as `$eq` with that same value**. Note that this also works with $or, so `{ $or: ['single', 'double'] }` is the same as `{ $or: [{ $eq: 'single' }, { $eq: 'double' }] }`.
 * 5. **Using `null`**: `null` can be used as a condition on a property to assert that the property must be `null` or `undefined`.
 *      Conversely, `{ $ne: null }` can be used to assert that the property must be defined and may thus not be `null` or `undefined`.
 * 4. **Variables**: As shown by the fourth example, FlowConditions allow you to use values from the data object within your query, with `{ var: 'propertyName' }` or `{ var: 'nested.propertyName' }`
 *      In the fourth example, let's assume that `estimatedConsumption` is an object that is filled in by our calculations API before the user starts filling in the Omniform
 *      and that `consumption.gas` is filled in with the answer from the 'What is your gas consumption?' question. The condition in the fourth example thus evaluates whether
 *      the user-provided gas consumption is greater than the estimated gas consumption.
 * 5. **References to nested values**: Similar to MongoDB, FlowConditions allow references to nested values using the dot-notation syntax that you're used to from JavaScript.
 *      However, this is currently only possible for variable references, as shown in the fourth example.
 *      For now, it is not possible to write `{ 'consumption.gas': 420 }`, this must be written as `{ consumption: { gas: 420 } }`
 * 6. **Working with arrays**: FlowConditions where the properties are arrays are also supported. When no operator is provided (see example 5) the default behavior is that the value must at least contain all of the values of the array. So an array with ['floorInsulation', 'wallInsulation'] would satisfy the condition.
 *     When an operator is provided, the behavior is as you'd expect. E.g. `{ solutionInterests: { $eq: ['wallInsulation'] } }` requires that the `solutionInterests` array contains exactly the same elements while $ne will be the opposite.
 *     The order in the array never matters! So `{ solutionInterests: { $eq: ['wallInsulation', 'floorInsulation'] } }` is the same as `{ solutionInterests: { $eq: ['floorInsulation', 'wallInsulation'] } }`.
 *     NOTE: Currently only array of literals (string, number, boolean, Date) are supported, not arrays of nested objects or nested conditions. This is a limitation that may be lifted in the future.
 */
export type FlowCondition<TValue extends Record<string, any>> = {
  // maps all properties of TValue, excluding functions, to a Condition on that property.
  // eslint-disable-next-line @typescript-eslint/ban-types --- Function is intentional as we want to disallow specifying conditions on any function, regardless of its shape.
  [key in keyof TValue as Exclude<key, GetKeysWithType<TValue, Function>>]?: Condition<TValue[key]>;
} & {
  $or?: Condition<TValue>[];
  $not?: FlowCondition<TValue>;
};

/**
 * A Condition either specifies a ValueCondition on the current value,
 * or if the value is an object, specifies what properties on that object its values must match.
 */
export type Condition<TValue> = TValue extends Value
  ? ValueCondition<TValue>
  : TValue extends Record<string, any>
  ? {
      // maps all properties of TValue, excluding functions, to a Condition on that property.
      // eslint-disable-next-line @typescript-eslint/ban-types --- Function is intentional as we want to disallow specifying conditions on any function, regardless of its shape.
      [key in keyof TValue as Exclude<key, GetKeysWithType<TValue, Function>>]?: Condition<TValue[key]>; // eslint-disable-line prettier/prettier
    }
  : never;

/**
 * A ValueCondition is a condition on a single value.
 * This can either be a value or null (same as using $eq with that same value),
 * or a comparator that is applicable to the type of the value,
 * or a boolean operator that collects the results of multiple value conditions on that value.
 */
export type ValueCondition<TValue extends Value> =
  | null
  | TValue
  | Variable
  | BooleanOperators<TValue>
  | GenericComparators<TValue>
  | (TValue extends Value<number | Date> ? NumericComparators<TValue> : never)
  | (TValue extends Value[] ? ArrayOperators<TValue> : never);

function isValueCondition<T extends Value>(v: any): v is ValueCondition<T> {
  return (
    isNull(v) ||
    isValue(v) ||
    isBooleanOperators(v) ||
    isGenericComparators(v) ||
    isNumericComparators(v) ||
    isArrayOperators(v)
  );
}

// ------------------------------------------------------------------------------------------------

type GenericComparators<TValue extends Value> = {
  $eq?: TValue | Variable | null;
  $ne?: TValue | Variable | null;
};

function isGenericComparators(v: any): v is GenericComparators<Value> {
  return typeof v === 'object' && v != null && ('$eq' in v || '$ne' in v); // eslint-disable-line eqeqeq --- != null is intentional here, we want ensure v is not null or undefined.
}

type NumericComparators<TValue extends Value<number | Date>> = {
  $gt?: TValue | Variable;
  $gte?: TValue | Variable;
  $lt?: TValue | Variable;
  $lte?: TValue | Variable;
};

function isNumericComparators(v: any): v is NumericComparators<Value<number | Date>> {
  return typeof v === 'object' && ('$gt' in v || '$gte' in v || '$lt' in v || '$lte' in v);
}

// ------------------------------------------------------------------------------------------------

/**
 * Defines the boolean operators $or and $not.
 * - `$or` requires that one of the given value conditions is true in order to result in true.
 * - `$not` inverts the result of the given value condition.
 *
 * Note that $and is not implemented, as this is unnecessary. $and implicitly happens when specifying
 * multiple properties on a single condition object.
 * E.g.: `{ foo: { $gt: 10, $lte: 42 } }` requires that `foo` is both greater than 10 and less than or equal to 42.
 */
type BooleanOperators<TValue extends Value> = {
  $or?: ValueCondition<TValue>[];
  $not?: ValueCondition<TValue>;
};

function isBooleanOperators(v: any): v is BooleanOperators<Value> {
  return typeof v === 'object' && v != null && ('$or' in v || '$not' in v); // eslint-disable-line eqeqeq --- != null is intentional here, we want ensure v is not null or undefined.
}

// ------------------------------------------------------------------------------------------------

type ArrayOperators<TValue extends Value[]> = {
  $all: TValue;
};

function isArrayOperators(v: any): v is ArrayOperators<Value[]> {
  return typeof v === 'object' && v != null && '$all' in v; // eslint-disable-line eqeqeq --- != null is intentional here, we want ensure v is not null or undefined.
}

// ------------------------------------------------------------------------------------------------

type Primitive = string | number | Date | boolean;

/** A literal is a literal value, i.e. a string, number, date or boolean. */
export type Literal<TValue = Primitive> = TValue extends any
  ? Primitive
  : TValue extends Primitive
  ? TValue
  : never;

function isLiteralArray(v: any): v is Literal<Primitive>[] {
  if (Array.isArray(v)) {
    return v.every(isLiteral);
  }
  return false;
}

function isLiteral(v: any): v is Literal<Primitive> {
  return (
    typeof v === 'string' || typeof v === 'number' || typeof v === 'boolean' || v instanceof Date
  );
}

/** A variable is a reference to named data, e.g. the properties of a flow condition's data object. */
export type Variable = { var: string };

function isVariable(v: any): v is Variable {
  return typeof v === 'object' && v != null && 'var' in v && typeof v.var === 'string'; // eslint-disable-line eqeqeq --- != null is intentional here, we want ensure v is not null or undefined.
}

/** A value is either a literal value or a variable (reference to a value on flow condition's data object) */
export type Value<TValue extends Primitive = Primitive> =
  | Literal<TValue>
  | Variable
  | Literal<TValue>[];

function isValue(v: any): v is Value {
  return isLiteralArray(v) || isLiteral(v) || isVariable(v);
}

// TODO: add numeric operators (+ - * /)
// TODO: add array element access and object property access
// TODO: add array element operators (e.g. $elemMatch, $size)

// ------------------------------------------------------------------------------------------------

/** Get the keys from type `T` that map to a value with type `TValue`. */
type GetKeysWithType<T, TValue> = {
  [key in keyof T]: T[key] extends TValue ? key : never;
}[keyof T];
