import { Card } from '@energiebespaarders/symbols';
import styled from 'styled-components';

/**
 * Generates a random number with a seed, so we can have different but fixed random numbers.
 * We use this to randomize the rotation for each background card for each new question.
 */
export function randomNumber(seed: number) {
  const x = Math.sin(seed + 1) * 10000;
  return x - Math.floor(x);
}

/** Fake background card to simulate a stack of cards */
const StyledBackgroundCard = styled(Card)<{ $depth: 1 | 2 | 3; $randomizer: number }>`
  z-index: ${x => 3 - x.$depth};
  position: absolute;
  left: ${x => `calc(12px * ${x.$depth})`};
  right: ${x => `calc(12px * ${x.$depth})`};
  bottom: ${x => `calc(-12px * ${x.$depth})`};
  width: ${x => `calc(100% - 24px * ${x.$depth})`};
  height: 100%;
  rotate: ${x => `${x.$randomizer * 0.15 * x.$depth}deg`};

  @keyframes floatAndFadeIn {
    from {
      transform: translateY(20px);
      opacity: 0.5;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  animation: floatAndFadeIn ${x => 0.15 * x.$depth}s;
`;

export default StyledBackgroundCard;
