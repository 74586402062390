import { useIsMobile } from '@energiebespaarders/hooks';
import { Box, Flex } from '@energiebespaarders/symbols';
import styled from 'styled-components';
import { dummy_dummy_content_PrimaryImageRecord_primaryImageCard } from '~/types/generated/cms/dummy';
import DatoButton from './DatoButton';

interface Props {
  block: dummy_dummy_content_PrimaryImageRecord_primaryImageCard;
}

const InfoCard = styled(Flex)<{ $isMobile: boolean }>`
  position: relative;
  margin-top: -64px;
  border-radius: 12px;
  background-color: white;
  z-index: 1;
`;

const PrimaryImageBlockCard: React.FC<Props> = ({ block }) => {
  const isMobile = useIsMobile();

  return (
    <InfoCard
      $isMobile={isMobile}
      flexDirection="column"
      p={4}
      ml={[2, 4, 8]}
      width={['calc(100% - 24px)', 'calc(100% - 48px)', '600px']}
    >
      <Box mb={4}>{block.text}</Box>

      <Box>
        <DatoButton block={block.button[0]} />
      </Box>
    </InfoCard>
  );
};

export default PrimaryImageBlockCard;
