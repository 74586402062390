import { Box } from '@energiebespaarders/symbols';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { themify, tint } from '../../styles/mixins';
import Exploded from '/public/img/illustrations/exploded.png';

export const StyledHeading = styled.h1`
  font-size: 3em;
  letter-spacing: -0.025em;
  line-height: 1.1;
  font-weight: 600;
`;

export const StyledExplodedGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vw;
  background: ${({ theme }) =>
    `linear-gradient(to bottom, ${rgba(theme.colors.greenSlate, 0)} 25%, ${
      theme.colors.greenSlate
    }), url(${Exploded})`};
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
  opacity: 0.8;

  ${mediaMin.sm} {
    background-position: top right;
  }
`;

export interface FormBoxProps {
  $isOpen: boolean;
  $bgColor: string;
  $openBgColor?: string;
}

export const StyledFormBox = styled(Box)<FormBoxProps>`
  ${x =>
    x.$bgColor !== 'none' &&
    css<FormBoxProps>`
      background: ${`linear-gradient(to bottom, ${themify(
        x.$bgColor,
      )}, rgba(255, 255, 255, 0.001)), ${tint(0.2, themify(x.$openBgColor))}`};
      transition: all 1s;
      border-bottom: ${x.$isOpen ? '1px' : '0'} solid ${x => x.theme.colors.greenSlate};
    `};

  ${x =>
    x.$isOpen &&
    css<FormBoxProps>`
      border-color: ${x => themify(x.$openBgColor || x.theme.colors.green)};
    `};
`;
