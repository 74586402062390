import { Box, Flex } from '@energiebespaarders/symbols';
import { useState } from 'react';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import { OmniformBlockFragment } from '~/types/generated/cms/OmniformBlockFragment';
import CustomerIntakeController from '../CustomerIntake/CustomerIntakeController';
import Omniform from '../Omniform/Omniform';
import { OmniformSharedProps } from '../Omniform/OmniformContent';
import AddressInitForm from '../RequestPackage/AddressInitForm';

interface OmniformBlockProps {
  block: OmniformBlockFragment;
}

const OmniformForm: React.FC<OmniformBlockProps> = ({ block }) => {
  const { activeHouseId } = useActiveHouseId();

  const [selectedHouseId, setSelectedHouseId] = useState(activeHouseId);

  if (!activeHouseId) {
    return (
      <Flex justifyContent="center">
        <Box width={[1, 1, 1 / 2]}>
          <AddressInitForm
            selectedHouseId={selectedHouseId}
            setSelectedHouseId={setSelectedHouseId}
            isActive
          />
        </Box>
      </Flex>
    );
  }

  const { formKey, formStyle, submitText, textVariables } = block;
  // const sendGAEvent = useGAEvent();
  switch (formKey) {
    case 'self-intake-complete':
      return (
        <CustomerIntakeController
          formStyle={formStyle as OmniformSharedProps<any>['formStyle']}
          submitText={submitText!}
          textVariables={textVariables}
        />
      );
    default:
      // TODO: how to make Omniform truly dynamic?
      // There is a lot of hardcoded stuff for the self-intake:
      // - flow data based on queried data (CustomerIntakeController)
      // - text variables based on queried data (CustomerIntakeController)
      // - exit scenarios (IntakeExitProvider)
      // - previous submission data (CustomerIntakeController)
      // Waiting with this until it's clearer, once more forms are added
      return (
        <Omniform<any>
          formKey={formKey!}
          submitText={submitText!}
          formStyle={formStyle as OmniformSharedProps<any>['formStyle']}
          textVariables={textVariables}
          onSubmit={() => {
            // if (gaEventsEnabled) {
            //   sendGAEvent(`${formKey}-completed`, {
            //     house_id: activeHouseId,
            //     method: 'omniform',
            //   });
            // }
          }}
          // initialFlowData={flowData}
          // updateFlowData={getUpdatedFlowData}
          // onAnswer={handleUpdateTextVariables}
        />
      );
  }
};

/**
 * Assumptions:
 * House must be initialized, prompt to create if not exists
 *
 * Props:
 * - Form key
 * - Form style
 * - Submit text
 * -
 * - Order needed?
 * - Send GA event on submit?
 *
 * - Text variables? (but those are dynamic atm)
 */
const OmniformBlock: React.FC<OmniformBlockProps> = ({ block }) => {
  return <OmniformForm block={block} />;
};

export default OmniformBlock;
