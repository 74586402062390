import { delimit, isUndefined } from '@energiebespaarders/constants';
import { Box, Image, Placeholder } from '@energiebespaarders/symbols';
import { Center, Color, Small, Smaller } from '@energiebespaarders/symbols/helpers';
import { useState } from 'react';
import {
  StyledEuro,
  StyledOriginalPrice,
  StyledPackageCard,
  StyledPrice,
} from '~/components/RequestPackage/PackageCard';
import PackageFeatures from '~/components/RequestPackage/PackageFeatures';
import PackageRibbon from '~/components/RequestPackage/PackageRibbon';
import EnergyLabelPackageCard from '~/envPartner/PartnerRequest/EnergyLabelPackageCard';
import { useCustomerDutyPackage } from '~/hooks/useDutyPackage';
import { dummy_dummy_content_ComponentDutyPackageRecord } from '~/types/generated/cms/dummy';
import { getPartnerLogo } from 'public/img/partners';
import RequestPackageForm from '../RequestPackage/RequestPackageForm';

interface Props {
  block: dummy_dummy_content_ComponentDutyPackageRecord;
}

const DutyPackageCard: React.FC<Props> = ({ block }) => {
  const [activeDutyPackageKey, setActiveDutyPackageKey] = useState<string>(
    block.componentDutyPackage?.key || '',
  );
  const { dutyPackage, loading, error } = useCustomerDutyPackage(activeDutyPackageKey);

  const hasDiscount =
    dutyPackage &&
    !isUndefined(dutyPackage.nonDiscountedPrice) &&
    dutyPackage.nonDiscountedPrice !== dutyPackage.price;

  return (
    <div>
      {loading || error || !dutyPackage ? (
        <Placeholder height="400px" error={error} />
      ) : block.componentDutyPackage?.key?.includes('energyLabelHouseType') ? (
        <EnergyLabelPackageCard
          packageProps={dutyPackage}
          setActive={() => setActiveDutyPackageKey(dutyPackage.key)}
          hideButton
        />
      ) : (
        <StyledPackageCard $bgColor={dutyPackage.color} px={3} mb={5}>
          {dutyPackage.ribbon && (
            <PackageRibbon bgColor={dutyPackage.ribbonBgColor!} text={dutyPackage.ribbon} />
          )}
          <Center block>
            <h3>
              Verduurzamen{' '}
              <Color c={dutyPackage.color}>
                {dutyPackage.fullName.replace('Verduurzamen ', '')}
              </Color>
            </h3>
            <Small>
              <p>{dutyPackage.customerDescription}</p>
            </Small>
            {hasDiscount ? (
              <StyledPrice>
                <StyledOriginalPrice className="gray strikethrough">
                  {dutyPackage.nonDiscountedPrice}
                  <StyledEuro>€</StyledEuro>
                </StyledOriginalPrice>
                {delimit(dutyPackage.price!, dutyPackage.price! % 1 !== 0 ? 2 : 0)}
                <StyledEuro>€</StyledEuro>
              </StyledPrice>
            ) : (
              <StyledPrice>
                {dutyPackage.price}
                <StyledEuro>€</StyledEuro>
              </StyledPrice>
            )}
          </Center>
          {!dutyPackage || error || loading ? (
            <Placeholder error={error} />
          ) : (
            <PackageFeatures
              dutyPackage={dutyPackage}
              hideButton
              customPackageProps={{ additionsColor: dutyPackage.color }}
            />
          )}
          {getPartnerLogo(dutyPackage.icon) && (
            <Box width={1} px={[1, 2, 2, 3]} mb={3} textAlign="center">
              <p>
                <Color c="gray">
                  <Smaller>Dit pakket wordt speciaal aangeboden voor</Smaller>
                </Color>
              </p>
              <Image
                src={getPartnerLogo(dutyPackage.icon)}
                alt={dutyPackage.fullName}
                style={{ maxHeight: '45px', maxWidth: '120px' }}
              />
            </Box>
          )}
        </StyledPackageCard>
      )}
      {dutyPackage && (
        <RequestPackageForm
          dutyPackage={dutyPackage}
          submitText={block.componentDutyPackage?.buttonText || 'Start jouw aanvraag'}
        />
      )}
    </div>
  );
};

export default DutyPackageCard;
