import React, { useState } from 'react';
import { useLocalStorage } from 'react-use';
import { ExitStatementKey } from './types';

type ContextValue = [ExitStatementKey | undefined, (scenario?: ExitStatementKey) => void];
export const IntakeExitContext = React.createContext<ContextValue>([undefined, () => null]);

export const IntakeExitProvider: React.FC = ({ children }) => {
  const [localStorageValue, setLocalStorageValue] =
    useLocalStorage<ExitStatementKey>('intakeScenario');
  const [intakeExit, setIntakeExit] = useState<ExitStatementKey | undefined>(localStorageValue);

  const handleSetExitScenario = (scenario?: ExitStatementKey) => {
    setIntakeExit(scenario);
    setLocalStorageValue(scenario);
  };

  const contextValue: ContextValue = [intakeExit, handleSetExitScenario];

  return <IntakeExitContext.Provider value={contextValue}>{children}</IntakeExitContext.Provider>;
};
