import { Box } from '@energiebespaarders/symbols';
import { dummy_dummy_content_HeadingRecord } from '~/types/generated/cms/dummy';

interface Props {
  block: dummy_dummy_content_HeadingRecord;
}

const Heading: React.FC<Props> = ({ block }) => {
  return (
    <Box pt={[3, 4, 10]}>
      <h2 style={{ textAlign: 'center' }}>{block.text}</h2>
    </Box>
  );
};

export default Heading;
