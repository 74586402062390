import { Box, Flex, Icon } from '@energiebespaarders/symbols';
import { CaretLeft, CaretRight } from '@energiebespaarders/symbols/icons/line';
import styled from 'styled-components';

interface Props {
  currentPage: number;
  totalPages: number;
  handleNext: () => void;
  handlePrev: () => void;
  invert?: boolean;
}

const StyledCaret = styled(Icon)`
  cursor: pointer;
`;

const CaretContainer = styled(Flex)`
  background-color: white;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 45px;
  width: 50px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);

  span {
    line-height: 0px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const MiniBar = styled(Flex)`
  border-left: 1px solid black;
  margin: 0px 12px 0px 12px;
`;

const SimplePaginationContainer = styled(Flex)<{ $invert?: boolean }>`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  height: 45px;
  flex-direction: ${x => (x.$invert ? 'row-reverse' : 'row')};
`;

const NumberContainer = styled(Box)`
  width: 10px;
  height: 100%;
`;

const SimplePagination: React.FC<Props> = ({
  currentPage,
  totalPages,
  handleNext,
  handlePrev,
  invert,
}) => {
  return (
    <SimplePaginationContainer $invert={invert}>
      <Flex>
        <CaretContainer mr={2}>
          <StyledCaret onClick={handleNext} icon={CaretLeft} />
        </CaretContainer>

        <CaretContainer>
          <StyledCaret onClick={handlePrev} icon={CaretRight} />
        </CaretContainer>
      </Flex>

      <Flex>
        <NumberContainer>{currentPage}</NumberContainer>
        <MiniBar />
        <NumberContainer>{totalPages}</NumberContainer>
      </Flex>
    </SimplePaginationContainer>
  );
};

export default SimplePagination;
