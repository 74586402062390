import { delimit, isUndefined } from '@energiebespaarders/constants';
import {
  Accordion,
  Box,
  Container,
  Flex,
  Image,
  Placeholder,
  theme,
} from '@energiebespaarders/symbols';
import { Book, Center, Color, Small, Smaller } from '@energiebespaarders/symbols/helpers';
import React, { useCallback } from 'react';
import { Element as Anchor, scroller } from 'react-scroll/modules';
import RequestPackageForm from '~/components/RequestPackage/RequestPackageForm';
import { dummy_dummy_content_ComponentRequestFormRecord } from '~/types/generated/cms/dummy';
import { getPartnerLogo } from 'public/img/partners';
import { useCustomerDutyPackage } from '../../hooks/useDutyPackage';
import {
  StyledEuro,
  StyledOriginalPrice,
  StyledPackageCard,
  StyledPrice,
} from '../RequestPackage/PackageCard';
import PackageFeatures from '../RequestPackage/PackageFeatures';
import PackageRibbon from '../RequestPackage/PackageRibbon';
import { StyledFormBox } from '../RequestPackage/layout';

interface CardProps {
  block: dummy_dummy_content_ComponentRequestFormRecord;
}

const RequestFormBlock: React.FC<CardProps> = ({
  block: { dutyPackage: cmsDutyPackage, description, voucherCode },
}) => {
  const { dutyPackage, loading, error } = useCustomerDutyPackage(cmsDutyPackage?.key || '');

  const handleClickCta = useCallback(() => {
    scroller.scrollTo('requestForm', { smooth: true, duration: 1000 });
  }, []);

  const hasDiscount =
    dutyPackage &&
    !isUndefined(dutyPackage.nonDiscountedPrice) &&
    dutyPackage.nonDiscountedPrice !== dutyPackage.price;

  const MiniPackageCard = useCallback(
    () =>
      loading || error || !dutyPackage ? (
        <Placeholder height="150px" error={error} />
      ) : (
        <StyledPackageCard $bgColor={dutyPackage.color} px={3}>
          {!!dutyPackage.ribbon && (
            <PackageRibbon bgColor={dutyPackage.ribbonBgColor!} text={dutyPackage.ribbon} />
          )}
          <Center block>
            <h3>
              Verduurzamen{' '}
              <Color c={dutyPackage.color}>
                {dutyPackage.fullName.replace('Verduurzamen ', '')}
              </Color>
            </h3>
            <Small>
              <p>{dutyPackage.customerDescription}</p>
            </Small>
            {hasDiscount ? (
              <StyledPrice>
                <StyledOriginalPrice className="gray strikethrough">
                  {dutyPackage.nonDiscountedPrice}
                  <StyledEuro>€</StyledEuro>
                </StyledOriginalPrice>
                {delimit(dutyPackage.price!, dutyPackage.price! % 1 !== 0 ? 2 : 0)}
                <StyledEuro>€</StyledEuro>
              </StyledPrice>
            ) : (
              <StyledPrice>
                {dutyPackage.price}
                <StyledEuro>€</StyledEuro>
              </StyledPrice>
            )}
          </Center>

          {!dutyPackage || error || loading ? (
            <Placeholder error={error} />
          ) : (
            <PackageFeatures
              setActiveDutyPackage={handleClickCta}
              dutyPackage={dutyPackage}
              customPackageProps={{ additionsColor: dutyPackage.color }}
            />
          )}
          {getPartnerLogo(dutyPackage.icon) && (
            <Box width={1} px={[1, 2, 2, 3]} mb={3} textAlign="center">
              <p>
                <Color c="gray">
                  <Smaller>Dit pakket wordt speciaal aangeboden voor</Smaller>
                </Color>
              </p>
              <Image
                src={getPartnerLogo(dutyPackage.icon)}
                alt={dutyPackage.fullName}
                style={{ maxHeight: '45px', maxWidth: '120px' }}
              />
            </Box>
          )}
        </StyledPackageCard>
      ),
    [loading, error, dutyPackage, hasDiscount, handleClickCta],
  );

  return (
    <Box
      width={1}
      style={{
        background: `linear-gradient(transparent, ${theme.colors.greenSlate})`,
      }}
    >
      <Container>
        <Anchor name="requestForm" id="requestForm">
          <Flex flexWrap="wrap" mx={[0, -2]} py={[6, 8, 10]}>
            <Box width={[1, 1, 1 / 2]} px={[2, 4]} pb={[5, 5, 0]}>
              <MiniPackageCard />
            </Box>
            <StyledFormBox
              width={[1, 1, 1 / 2]}
              px={[2, 4]}
              textAlign="center"
              $isOpen={!!dutyPackage}
              $bgColor="transparent"
              $openBgColor={dutyPackage?.color || 'green'}
              style={{ background: 'none', border: 'none' }}
            >
              <Accordion contentBgColor="transparent" contentPadding={0} isOpened override>
                <p>
                  <Book>
                    {description ||
                      `Fijn dat we je mogen helpen met het verduurzamen van jouw woning! Vul hieronder
                    je gegevens in om het huisbezoek in te plannen.`}
                  </Book>
                </p>
                <RequestPackageForm
                  dutyPackage={dutyPackage!}
                  initialValues={voucherCode?.code ? { voucherCode: voucherCode?.code } : undefined}
                  submitText="Vraag adviespakket gratis en vrijblijvend aan"
                />
              </Accordion>
            </StyledFormBox>
          </Flex>
        </Anchor>
      </Container>
    </Box>
  );
};

export default RequestFormBlock;
