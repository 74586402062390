import { dummy_dummy_content_BlogPostQuickLinkRecord } from '~/types/generated/cms/dummy';
import { IQuickLinkItem } from '../DatoCms/QuickLinkItem';
import QuickLinks from '../DatoCms/QuickLinks';

interface Props {
  block: dummy_dummy_content_BlogPostQuickLinkRecord;
}

const BlogPostQuickLinks: React.FC<Props> = ({ block }) => {
  const blogPosts: IQuickLinkItem[] = block.blogPosts.map(b => ({
    id: b.id,
    responsiveImageData: b.image!.responsiveImage!,
    heading: b.title!,
    text: b.description!,
    to: `/blog/${b.slug!}`,
  }));

  return <QuickLinks title={block.title!} items={blogPosts} />;
};

export default BlogPostQuickLinks;
