import { dummy_dummy_content_MiscQuickLinkRecord } from '~/types/generated/cms/dummy';
import { IQuickLinkItem } from '../DatoCms/QuickLinkItem';
import QuickLinks from '../DatoCms/QuickLinks';

interface Props {
  block: dummy_dummy_content_MiscQuickLinkRecord;
}

const MiscQuickLinks: React.FC<Props> = ({ block }) => {
  const items: IQuickLinkItem[] = block.items.map(b => ({
    id: b.id,
    responsiveImageData: b.image!.responsiveImage!,
    heading: b.heading!,
    text: b.text!,
    href: b.url!,
  }));

  return <QuickLinks title={block.title!} items={items} />;
};

export default MiscQuickLinks;
