import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  Icon,
  Image,
  Spacer,
  theme,
} from '@energiebespaarders/symbols';
import { Center, Right } from '@energiebespaarders/symbols/helpers';
import { Cancel } from '@energiebespaarders/symbols/icons/solid';
import { themify, tint } from '@energiebespaarders/symbols/styles/mixins';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useCustomerDutyPackage } from '~/hooks/useDutyPackage';
import { dummy_dummy_content_QuotesOrPlanRecord } from '~/types/generated/cms/dummy';
import { DutyPackageName } from '~/types/graphql-global-types';
import TypedStructuredText, { CMSStructuredTextData } from '../DatoCms/TypedStructuredText';
import ProcessIndicator from '../ProcessIndicator';
import RequestPackageForm from '../RequestPackage/RequestPackageForm';

export const quoteFunnelSteps = [
  { order: 1, name: 'Intake' },
  { order: 2, name: 'Producten selecteren' },
  { order: 3, name: 'Offertes opstellen' },
];

interface StyledTipArrowProps {
  $arrowSize: number | string;
  $bgColor: string;
}

const StyledTipArrow = styled.div<StyledTipArrowProps>`
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-top-width: ${x => x.$arrowSize};
  border-right-width: ${x => x.$arrowSize};
  border-bottom-width: 0;
  border-left-width: ${x => x.$arrowSize};
  border-top-color: ${x => x.$bgColor};
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  margin-top: 0;
  margin-left: -${x => x.$arrowSize};
`;

interface QuotesOrPlanCTAProps {
  block: dummy_dummy_content_QuotesOrPlanRecord;
}

const StyledTitle = styled.div<{ $isCompact: boolean }>`
  font-weight: 600;
  font-size: ${x => x.theme.type.scale[x.$isCompact ? 6 : 5]};
  line-height: ${x => (x.$isCompact ? 1 : 1.5)};
`;

const QuotesOrPlanCTA: React.FC<QuotesOrPlanCTAProps> = ({ block }) => {
  const [showQuotesForm, setShowQuotesForm] = useState(false);
  const [showPlanForm, setShowPlanForm] = useState(false);
  const { dutyPackage } = useCustomerDutyPackage(block.qopDutyPackage?.key || '');
  const { dutyPackage: quotesOnlyPackage } = useCustomerDutyPackage(DutyPackageName.quotesOnly);

  const handleShowQuotesForm = useCallback(() => {
    setShowQuotesForm(true);
    setShowPlanForm(false);
  }, []);

  const handleShowPlanForm = useCallback(() => {
    setShowQuotesForm(false);
    setShowPlanForm(true);
  }, []);

  const handleHideForms = useCallback(() => {
    setShowPlanForm(false);
    setShowQuotesForm(false);
  }, []);

  const isShowingForm = showPlanForm || showQuotesForm;
  return (
    <Flex mx={-2} flexWrap="wrap">
      <Box width={1} px={2}>
        <Container size="lg" textAlign="center">
          <Flex mx={-2} flexWrap="wrap">
            <Box width={[isShowingForm ? 1 / 2 : 1, 1 / 2]} p={2}>
              <Card
                shadow="none"
                bgColor={tint(showQuotesForm ? 1 : 0.25, themify('yellow'))}
                borderColor="yellow"
                p={3}
                onClick={handleShowQuotesForm}
                style={showPlanForm ? {} : { cursor: 'pointer' }}
              >
                {!isShowingForm && (
                  <Center block>
                    <p style={{ color: theme.colors.yellowDarker }}>
                      Ik weet waar ik interesse in heb
                    </p>
                    <Image
                      style={{ height: 120 }}
                      alt={block.qopQuotesImage!.responsiveImage!.alt || ''}
                      src={block.qopQuotesImage!.responsiveImage!.src}
                    />
                  </Center>
                )}
                {!isShowingForm && <Spacer vertical />}
                <StyledTitle $isCompact={isShowingForm}>{block.qopQuotesTitle}</StyledTitle>
                {!isShowingForm && (
                  <>
                    <TypedStructuredText
                      data={block.qopQuotesContent as unknown as CMSStructuredTextData}
                    />
                    <Button
                      label="Ontvang offertes"
                      onClick={handleShowQuotesForm}
                      bgColor="yellow"
                      color="grayBlack"
                      mt={2}
                      mr={0}
                      mb={0}
                    />
                  </>
                )}
                {showQuotesForm && (
                  <StyledTipArrow $arrowSize="12px" $bgColor={themify('yellow')} />
                )}
              </Card>
            </Box>

            <Box width={[isShowingForm ? 1 / 2 : 1, 1 / 2]} p={2}>
              <Card
                shadow="none"
                bgColor={tint(showPlanForm ? 1 : 0.25, themify('green'))}
                color={showPlanForm ? 'white' : 'grayBlack'}
                borderColor="green"
                p={3}
                onClick={handleShowPlanForm}
                style={showPlanForm ? {} : { cursor: 'pointer' }}
              >
                {!isShowingForm && (
                  <Center block>
                    <p style={{ color: theme.colors.greenDark }}>
                      Ik weet nog niet waar ik moet beginnen
                    </p>
                    <Image
                      style={{ height: 120 }}
                      alt={block.qopPlanImage!.responsiveImage!.alt || ''}
                      src={block.qopPlanImage!.responsiveImage!.src}
                    />
                  </Center>
                )}
                {!isShowingForm && <Spacer vertical />}
                <StyledTitle $isCompact={isShowingForm}>{block.qopPlanTitle}</StyledTitle>
                {!isShowingForm && (
                  <>
                    <TypedStructuredText
                      data={block.qopPlanContent as unknown as CMSStructuredTextData}
                    />
                    <Button
                      label="Plan afspraak"
                      onClick={handleShowPlanForm}
                      bgColor="yellow"
                      color="grayBlack"
                      mt={2}
                      mr={0}
                      mb={0}
                    />
                  </>
                )}
                {showPlanForm && <StyledTipArrow $arrowSize="12px" $bgColor={themify('green')} />}
              </Card>
            </Box>

            {isShowingForm && (
              <Box width={1} px={1} textAlign="center" pt={5}>
                <ProcessIndicator
                  steps={
                    showQuotesForm
                      ? quoteFunnelSteps
                      : [
                          { name: 'Gegevens invullen' },
                          { name: 'Informatie aanleveren' },
                          { name: 'Afspraak inplannen' },
                        ]
                  }
                  activeStep={1}
                  bgColor={showQuotesForm ? quotesOnlyPackage?.color : dutyPackage?.color}
                />
                <Container size="md">
                  <Right block>
                    <Icon icon={Cancel} fill="gray" hoverColor="red" onClick={handleHideForms} />
                  </Right>
                  <RequestPackageForm
                    dutyPackage={showQuotesForm ? quotesOnlyPackage! : dutyPackage!}
                    submitText={showQuotesForm ? 'Prijsindicaties opvragen' : 'Advies aanvragen'}
                    hideIntro
                    customPackageProps={showQuotesForm ? { price: 0 } : {}} // HACK: Hides voucher input
                    showSolutionChecklist={showQuotesForm}
                  />
                </Container>
              </Box>
            )}
          </Flex>
        </Container>
      </Box>
    </Flex>
  );
};

export default QuotesOrPlanCTA;
