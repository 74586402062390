import {
  Checkbox,
  Container,
  Input,
  Radio,
  RadioGroup,
  Range,
  Select,
  Textarea,
} from '@energiebespaarders/symbols';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import { Center, Large, Medium } from '@energiebespaarders/symbols/helpers';
import { breakpointsPx } from '@energiebespaarders/symbols/styles/breakpoints';
import { padding } from '@energiebespaarders/symbols/styles/mixins';
import dayjs from 'dayjs';
import { ChangeEvent, ReactElement, WheelEvent, useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import type {
  AnswerType,
  InputOption,
  InputOptionValue,
  OmniformQuestion,
} from '~/hooks/omniform/types';
import { QuestionAnswerType } from '~/hooks/omniform/types';
import { WithFormState } from '~/hooks/omniform/useOmniform';
import { QuestionType } from '~/types/graphql-global-types';
import fixUnit from '~/utils/fixUnit';
import { isBetween } from '~/utils/isBetween';
import CustomOmniformInputs from '../CustomerIntake/CustomQuestionInputs';
import { QKeyGeneral } from '../CustomerIntake/types';
import { OmniformInputBaseProps } from './types';

// ------------------------------------------------------------------------------------------------

type CheckboxInputAnswerTypes =
  | QuestionAnswerType.DateArray
  | QuestionAnswerType.NumberArray
  | QuestionAnswerType.StringArray
  | QuestionAnswerType.BooleanArray;

type OmniformCheckboxInputProps<T extends CheckboxInputAnswerTypes = CheckboxInputAnswerTypes> =
  OmniformInputBaseProps<T> & {
    options: InputOption<T>[];
  };

const StyledRadioGroup = styled(RadioGroup)<{ $useSmallerOptions: boolean }>`
  ${x =>
    x.$useSmallerOptions &&
    css`
      & label {
        height: auto;
        ${padding(2, 'y')};
      }
    `};
`;

function OmniformCheckboxInput<T extends CheckboxInputAnswerTypes = CheckboxInputAnswerTypes>({
  question,
  options,
  onAnswer,
  fontSize,
  labelSize,
  labelColor,
  label,
  inputWidth,
}: OmniformCheckboxInputProps<T>): ReactElement {
  const currentAnswer = question.answer as AnswerType<T>;
  const isNarrowInput = inputWidth < breakpointsPx.md;

  /** Options with these values will disable all other options when checked */
  const vetoValues: any[] = useMemo(() => ['none'], []);

  const handleChange = useCallback(
    (value: InputOptionValue<T>) => {
      // note: for some reason, the types of currentAnswer and nextCheckedValues are not inferred correctly,
      // causing includes, indexOf and push to require an argument of type never.
      let nextCheckedValues = [...currentAnswer] as AnswerType<T>;
      if (currentAnswer.includes(value as never)) {
        nextCheckedValues.splice(currentAnswer.indexOf(value as never), 1);
      } else {
        nextCheckedValues.push(value as never);
      }
      if (!currentAnswer.includes(value as never) && vetoValues.includes(value)) {
        nextCheckedValues = [value] as AnswerType<T>;
      }
      onAnswer(
        question.key,
        question.answerType as T,
        nextCheckedValues,
        !question.isOptional && nextCheckedValues.length === 0,
      );
    },
    [currentAnswer, onAnswer, question.answerType, question.isOptional, question.key, vetoValues],
  );

  return (
    <StyledRadioGroup
      label={label}
      labelColor={labelColor}
      labelSize={labelSize}
      fontSize={fontSize}
      name={question.key}
      onChange={handleChange}
      divider={options.length % 3 === 0 ? (isNarrowInput ? 1 : 3) : 2}
      touched={question.touched}
      error={question.error}
      $useSmallerOptions={isNarrowInput}
    >
      {options.map(({ label, value }) => (
        <Checkbox
          key={`option-${value}`}
          label={label}
          value={value}
          bgColor="greenDark"
          checked={currentAnswer.includes(value as never)}
          style={{ height: 'auto' }}
          disabled={
            (currentAnswer as string[]).some(a => vetoValues.includes(a)) &&
            !vetoValues.includes(value)
          }
        />
      ))}
    </StyledRadioGroup>
  );
}

// ------------------------------------------------------------------------------------------------

type RadioInputAnswerTypes =
  | QuestionAnswerType.Date
  | QuestionAnswerType.Number
  | QuestionAnswerType.String
  | QuestionAnswerType.Boolean;

type OmniformRadioInputProps<T extends RadioInputAnswerTypes = RadioInputAnswerTypes> =
  OmniformInputBaseProps<T> & {
    options: InputOption<T>[];
  };

function OmniformRadioInput<T extends RadioInputAnswerTypes = RadioInputAnswerTypes>({
  question,
  options,
  onAnswer,
  fontSize,
  labelSize,
  labelColor,
  label,
  inputWidth,
}: OmniformRadioInputProps<T>): ReactElement {
  const isNarrowInput = inputWidth < breakpointsPx.md;
  const handleChange = useCallback(
    (value: AnswerType<T>) => onAnswer(question.key, question.answerType as T, value),
    [onAnswer, question.answerType, question.key],
  );

  return (
    <Center block>
      <StyledRadioGroup
        labelSize={labelSize}
        labelColor={labelColor}
        divider={options.length % 3 === 0 ? (isNarrowInput ? 1 : 3) : 2}
        fontSize={fontSize}
        label={label}
        name={question.key}
        onChange={handleChange}
        touched={question.touched}
        error={question.error}
        value={question.answer as T}
        $useSmallerOptions={isNarrowInput}
      >
        {options.map(o => (
          <Radio
            key={`radio-${o.value}`}
            label={o.label}
            value={o.value}
            checked={question.answer === o.value}
            bgColor="greenDark"
          />
        ))}
      </StyledRadioGroup>
    </Center>
  );
}

// ------------------------------------------------------------------------------------------------

function OmniformSelectInput<T extends RadioInputAnswerTypes = RadioInputAnswerTypes>({
  question,
  options,
  onAnswer,
  fontSize,
  labelSize,
  labelColor,
  label,
}: OmniformRadioInputProps<T>): ReactElement {
  const handleChange = useCallback(
    (option: DropdownOption<InputOptionValue<T>>) => {
      const answer = option.value as unknown as AnswerType<T>; // TODO: Type mismatch?
      onAnswer(question.key, question.answerType as T, answer);
    },
    [onAnswer, question.answerType, question.key],
  );

  return (
    <Container size="sm">
      <Select<InputOptionValue<T>>
        options={options}
        onChange={handleChange}
        placeholder="Kies een antwoord"
        fontSize={fontSize}
        labelSize={labelSize}
        labelColor={labelColor}
        label={label}
        value={options.find(o => o.value === question.answer)}
      />
    </Container>
  );
}

// ------------------------------------------------------------------------------------------------

type OmniformTextAreaInputProps = OmniformInputBaseProps<QuestionAnswerType.String>;

function OmniformTextAreaInput({
  question,
  onAnswer,
  fontSize,
  labelSize,
  labelColor,
  label,
}: OmniformTextAreaInputProps): ReactElement {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) =>
      onAnswer(question.key, question.answerType as QuestionAnswerType.String, e.target.value),
    [onAnswer, question.answerType, question.key],
  );

  useEffect(() => {
    const textarea = document.getElementById(`textarea-${question.key}`);
    if (textarea) textarea.focus();
  }, [question.key]);

  return (
    <Textarea
      id={`textarea-${question.key}`}
      fontSize={fontSize}
      labelSize={labelSize}
      labelColor={labelColor}
      label={label}
      value={question.answer as string}
      touched={question.touched}
      error={question.error}
      onChange={handleChange}
    />
  );
}

// ------------------------------------------------------------------------------------------------

type OmniformRangeInputProps = OmniformInputBaseProps<QuestionAnswerType.Number>;

function OmniformRangeInput({
  question,
  onAnswer,
  labelSize,
  labelColor,
  label,
}: OmniformRangeInputProps): ReactElement {
  useEffect(() => {
    // Set range input to 0 on first render to set touched: true by default for ranges
    if (question.answer === undefined || question.answer === '') {
      onAnswer(
        question.key,
        question.answerType as QuestionAnswerType.Number,
        question.range?.[0] || 0,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const answer = Number(e.target.value);
      onAnswer(question.key, question.answerType as QuestionAnswerType.Number, answer);
    },
    [onAnswer, question.answerType, question.key],
  );

  return (
    <Center block>
      <div>
        <Large>
          <Medium>
            {question.answer || '0'}
            {question.answer === question.range?.[1] ? '+' : ''}
          </Medium>
        </Large>
      </div>
      <Range
        labelSize={labelSize}
        labelColor={labelColor}
        label={label}
        value={parseInt((question.answer || '0') as string)}
        min={question.range?.[0] || 0}
        max={question.range?.[1] || 10}
        // touched={question.touched} // TODO: Add support in symbols if relevant
        // error={question.error}
        showValue={false}
        onChange={handleChange} // TODO: Add debounce to change handler to prevent tons of network requests
      />
    </Center>
  );
}

// ------------------------------------------------------------------------------------------------

type OmniformInputProps = OmniformInputBaseProps<
  QuestionAnswerType.Number | QuestionAnswerType.String
>;

function OmniformInput({
  question,
  onAnswer,
  fontSize,
  labelSize,
  labelColor,
  label,
}: OmniformInputProps): ReactElement {
  const dateAnswer =
    question.type === QuestionType.date && question.answer
      ? dayjs(question.answer as number).format('YYYY-MM-DD')
      : '';

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const answer = e.target.value as AnswerType<
        QuestionAnswerType.Number | QuestionAnswerType.String
      >;

      if (
        question.range &&
        question.answerType === QuestionAnswerType.Number &&
        !isBetween(Number(answer), question.range[0], question.range[1] + 1)
      ) {
        return;
      }

      onAnswer(
        question.key,
        question.answerType as QuestionAnswerType.Number | QuestionAnswerType.String,
        answer,
      );
    },
    [onAnswer, question.answerType, question.key, question.range],
  );

  useEffect(() => {
    const inputEl = document.getElementById(`input-${question.key}`);
    if (inputEl) inputEl.focus();
  }, [question.key]);

  let minDate = '';
  if (
    question.type === QuestionType.date &&
    question.key === QKeyGeneral.CustomerWillMoveIntoHouse
  ) {
    minDate = dayjs().format('YYYY-MM-DD');
  }

  return (
    <Input
      id={`input-${question.key}`}
      fontSize={fontSize}
      labelSize={labelSize}
      labelColor={labelColor}
      label={label}
      addonContent={question.unit ? fixUnit(question.unit, true) : ''}
      addonSide="start"
      min={minDate || question.range?.[0]}
      max={question.range?.[1]}
      type={question.type === QuestionType.string ? 'text' : question.type}
      value={
        question.type === QuestionType.date ? dateAnswer : (question.answer as string | number)
      }
      touched={question.touched}
      error={question.error}
      onChange={handleChange}
      onScroll={e => e.preventDefault()}
      onWheel={(e: WheelEvent<HTMLInputElement>) => (e.target as HTMLInputElement).blur()}
    />
  );
}

// ------------------------------------------------------------------------------------------------

function renderQuestionInput<T extends QuestionAnswerType>(
  question: WithFormState<OmniformQuestion>,
  answer: (questionKey: string, answerType: T, answer: AnswerType<T>) => void,
  options?: {
    inputWidth?: number;
    hideLabels?: boolean;
  },
) {
  const commonProps: OmniformInputBaseProps<T> = {
    question,
    onAnswer: answer,
    fontSize: 5,
    labelSize: 4,
    labelColor: 'grayBlack',
    label: options?.hideLabels ? '' : question.question,
    inputWidth: options?.inputWidth || 999,
  };

  let inputComponent: JSX.Element = <></>;

  if (question.type === QuestionType.checkbox) {
    inputComponent = (
      <OmniformCheckboxInput
        {...commonProps}
        options={[...question.options!]}
        onAnswer={answer as OmniformCheckboxInputProps['onAnswer']}
      />
    );
  }

  if (question.type === QuestionType.radio) {
    // Render a dropdown if there are more than 10 options
    if (question.options && question.options?.length > 10) {
      inputComponent = (
        <OmniformSelectInput
          {...commonProps}
          options={[...question.options!]}
          onAnswer={answer as OmniformRadioInputProps['onAnswer']}
        />
      );
    } else {
      inputComponent = (
        <OmniformRadioInput
          {...commonProps}
          options={[...question.options!]}
          onAnswer={answer as OmniformRadioInputProps['onAnswer']}
        />
      );
    }
  }

  if (question.type === QuestionType.boolean) {
    inputComponent = (
      <OmniformRadioInput<QuestionAnswerType.Boolean>
        {...commonProps}
        onAnswer={answer as OmniformRadioInputProps<QuestionAnswerType.Boolean>['onAnswer']}
        options={[
          { label: 'Ja', value: true },
          { label: 'Nee', value: false },
        ]}
      />
    );
  }

  if (question.type === QuestionType.textarea) {
    inputComponent = (
      <OmniformTextAreaInput
        {...commonProps}
        onAnswer={answer as OmniformTextAreaInputProps['onAnswer']}
      />
    );
  }

  if (question.type === QuestionType.range) {
    inputComponent = (
      <OmniformRangeInput {...commonProps} onAnswer={answer as OmniformInputProps['onAnswer']} />
    );
  }

  // TODO: Missing: datetime and file inputs
  if ([QuestionType.string, QuestionType.number, QuestionType.date].includes(question.type)) {
    inputComponent = (
      <OmniformInput {...commonProps} onAnswer={answer as OmniformInputProps['onAnswer']} />
    );
  }

  if (CustomOmniformInputs[question.key]?.(commonProps)) {
    inputComponent = CustomOmniformInputs[question.key]!(commonProps)!;
  }

  return inputComponent;
}

export default renderQuestionInput;
