export class OmniformNotFoundError extends Error {
  constructor(formKey: string, formRevision?: number) {
    super(`Omniform ${formKey}${formRevision && ` (rev ${formRevision})`} not found`);
  }
}

export class OmniformResponseNotFoundError extends Error {
  constructor(formKey: string, formRevision?: number) {
    super(`Response to Omniform ${formKey}${formRevision && ` (rev ${formRevision})`} not found`);
  }
}
