import { gql, useQuery } from '@apollo/client';
import { Box, Button, Flex, Spacer, theme } from '@energiebespaarders/symbols';
import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';
import { getGoogleAuthUrl } from '~/types/generated/getGoogleAuthUrl';
import GoogleIcon from '/public/img/logos/google-icon.svg';

export const SOCIAL_LOGIN_SRC_URL_STORAGE_KEY = 'SOCIAL_LOGIN_SRC_URL';

const StyledGoogleButton = styled(Button)`
  border: 1px solid ${theme.colors.gray};
  border-radius: 3px;
  white-space: nowrap;

  &:hover,
  &:active,
  &:focus {
    color: ${theme.colors.grayBlack};
  }

  & * {
    vertical-align: middle;
  }
`;

const GET_GOOGLE_AUTH_URL = gql`
  query getGoogleAuthUrl {
    getGoogleAuthUrl
  }
`;

interface SocialLoginProps {
  redirectUrl?: string;
  buttonProps?: Record<string, any>;
}

const SocialLogin: React.FC<SocialLoginProps> = ({ redirectUrl, buttonProps }) => {
  const { data, loading, error } = useQuery<getGoogleAuthUrl>(GET_GOOGLE_AUTH_URL);

  const handleGoogleLogin = () => {
    // Persists the current URL in the session storage (or the one passed as prop if provided)
    window.localStorage.setItem(
      SOCIAL_LOGIN_SRC_URL_STORAGE_KEY,
      redirectUrl || `${location.pathname}${location.search}`,
    );

    // Redirect to Google login
    window.location.href = data!.getGoogleAuthUrl;
  };

  return (
    <Flex flexWrap="wrap" mx="-3px">
      <Box width={1} px="3px">
        <StyledGoogleButton
          bgColor="white"
          color="grayBlack"
          mt={1}
          px={5}
          onClick={handleGoogleLogin}
          disabled={!!(loading || error)}
          {...buttonProps}
        >
          <Image src={GoogleIcon} loading="lazy" alt="Google logo" width={18} height={18} />
          <Spacer amount={3} />
          Log in met Google
        </StyledGoogleButton>
      </Box>
    </Flex>
  );
};

export default SocialLogin;
