import { Box, Flex } from '@energiebespaarders/symbols';
import { ReactNode } from 'react';
import styled from 'styled-components';

export type ListItem = {
  key: string;
  text: string;
  icon: ReactNode;
};

interface Props {
  title: string;
  items: ListItem[];
}

const Container = styled(Flex)`
  width: max-content;
`;

const ListWithIcon: React.FC<Props> = ({ title, items }) => {
  return (
    <Container flexDirection="column" width={[1, 1, 1 / 2]} mb={4}>
      <Box mb={2}>
        <strong>{title}</strong>
      </Box>

      {items.map(item => (
        <Flex key={item.key} mb={2} style>
          <Box mr={2}>{item.icon}</Box>
          {item.text}
        </Flex>
      ))}
    </Container>
  );
};

export default ListWithIcon;
