import { Solution } from '@energiebespaarders/constants';
import { TextLink } from '@energiebespaarders/symbols';
import { Heading, Medium } from '@energiebespaarders/symbols/helpers';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import {
  Buildings,
  CheckCircle,
  Fan,
  GridFour,
  HouseSimple,
  IconContext,
  Lighthouse,
  PiggyBank,
  ProhibitInset,
  SquareHalfBottom,
  SunHorizon,
  Wall,
  Waves,
} from '@phosphor-icons/react';
import Link from 'next/link';
import { useIntercom } from 'react-use-intercom';
import {
  ExitStatementKey,
  QKeyCustomerIntake,
  QKeyFloorInsulation,
  QKeyGeneral,
  QKeyGlazing,
  QKeyHeating,
  QKeyRoof,
  QKeyRoofInsulation,
  QKeyWallInsulation,
} from './types';

export const exitQuestions = [
  QKeyGeneral.CustomerSolutionInterest,
  QKeyGeneral.HouseIsMonument,
  QKeyGeneral.HouseIsApartment,
  QKeyWallInsulation.HouseWallInsulation,
  QKeyHeating.HouseHeatEmissionSystems,
] as string[];

export const determineIntakeExitScenario = (
  answers: Partial<Record<QKeyCustomerIntake, any>>,
  constructionYear: number,
): ExitStatementKey | undefined => {
  const roofTypes = (answers[QKeyRoof.HouseRoofTypes] || []) as string[];
  const roofingMaterials = (answers[QKeyRoof.HouseRoofingMaterial] || []) as string[];
  const interests = (answers[QKeyGeneral.CustomerSolutionInterest] || []) as string[];
  const checkSingleInterest = (interest: string) =>
    interests.includes(interest) && interests.length === 1;

  const onlyInsulationInterests =
    interests.length &&
    interests.every(
      i =>
        i === Solution.FloorInsulation ||
        i === Solution.WallInsulation ||
        i === Solution.InnerRoofInsulation ||
        i === Solution.WindowGlazing,
    );

  const hasWallCavity = answers[QKeyWallInsulation.HouseWallInsulation] !== 'noCavityWall';
  const crawlspaceIsAccessible = answers[QKeyFloorInsulation.HouseCrawlspaceInfo] === 'accessible';
  const hasSlantedRoof = roofTypes.includes('slanted');
  const hasFlatRoof = roofTypes.includes('flat');
  const hasThatchedRoof = roofingMaterials.includes('thatch');

  const isWallInsulated =
    constructionYear > 1992 ||
    answers[QKeyWallInsulation.HouseWallInsulation] === 'afterConstruction' ||
    answers[QKeyWallInsulation.HouseWallInsulation] === 'duringConstruction';
  const isFloorInsulated =
    constructionYear > 1992 ||
    answers[QKeyFloorInsulation.HouseFloorInsulation] === 'afterConstruction' ||
    answers[QKeyFloorInsulation.HouseFloorInsulation] === 'duringConstruction';
  const isSlantedRoofInsulated =
    hasSlantedRoof &&
    (answers[QKeyRoofInsulation.HouseRoofInsulationSlanted] === 'afterConstruction' ||
      answers[QKeyRoofInsulation.HouseRoofInsulationSlanted] === 'duringConstruction');
  const isFlatRoofInsulated =
    hasFlatRoof &&
    (answers[QKeyRoofInsulation.HouseRoofInsulationFlat] === 'afterConstruction' ||
      answers[QKeyRoofInsulation.HouseRoofInsulationFlat] === 'duringConstruction');
  const isRoofInsulated =
    constructionYear > 1992 ||
    ((isSlantedRoofInsulated || !hasSlantedRoof) && (isFlatRoofInsulated || !hasFlatRoof));
  const isBedroomGlazingInsulated =
    answers[QKeyGlazing.HouseGlazingTypeBedroom] &&
    answers[QKeyGlazing.HouseGlazingTypeBedroom] !== 'single';
  const isLivingGlazingInsulated =
    answers[QKeyGlazing.HouseGlazingTypeLiving] &&
    answers[QKeyGlazing.HouseGlazingTypeLiving] !== 'single';
  const isGlazingInsulated =
    constructionYear > 1992 || (isBedroomGlazingInsulated && isLivingGlazingInsulated);

  const isWallFeasible = hasWallCavity && !isWallInsulated;
  const isFloorFeasible = crawlspaceIsAccessible && !isFloorInsulated;
  const isGlazingFeasible = !isGlazingInsulated;

  // Check for exit scenarios
  if (answers[QKeyGeneral.HouseIsMonument] === true) return ExitStatementKey.IsMonument;

  if (answers[QKeyGeneral.HouseIsApartment] === true) return ExitStatementKey.IsApartment;

  if (checkSingleInterest(Solution.WindowGlazing)) return ExitStatementKey.OnlyGlazing;

  if (checkSingleInterest(Solution.ChargingBox)) return ExitStatementKey.OnlyChargingBox;

  if (
    interests.length &&
    interests.every(i => i === Solution.WindowGlazing || i === Solution.ChargingBox)
  ) {
    return ExitStatementKey.OnlyGlazingOrChargingBox;
  }

  if (checkSingleInterest(Solution.InnerRoofInsulation)) {
    if (hasFlatRoof && !hasSlantedRoof) return ExitStatementKey.OnlyRoofInterestButFlatRoof;
    if (isRoofInsulated) return ExitStatementKey.OnlyRoofInterestButAlreadyInsulated;
  }

  if (onlyInsulationInterests && constructionYear > 1992) {
    return ExitStatementKey.OnlyInsulationInterestButAfter1992;
  }

  if (checkSingleInterest(Solution.WallInsulation)) {
    if (!hasWallCavity) return ExitStatementKey.OnlyWallInterestButNoCavity;
    if (isWallInsulated) return ExitStatementKey.OnlyWallInterestButAlreadyInsulated;
  }

  if (checkSingleInterest(Solution.FloorInsulation)) {
    if (answers[QKeyFloorInsulation.HouseCrawlspaceInfo] === 'inaccessible') {
      return ExitStatementKey.OnlyFloorInterestButInaccessibleCrawlspace;
    }
    if (answers[QKeyFloorInsulation.HouseCrawlspaceInfo] === 'noCrawlspace') {
      return ExitStatementKey.OnlyFloorInterestButNoCrawlspace;
    }
    if (isFloorInsulated) return ExitStatementKey.OnlyFloorInterestButAlreadyInsulated;
  }

  if (checkSingleInterest(Solution.PvSystem) && hasThatchedRoof && roofingMaterials.length === 1) {
    return ExitStatementKey.OnlyPvInterestButThatchedRoof;
  }

  if (
    onlyInsulationInterests &&
    !isWallFeasible &&
    !isFloorFeasible &&
    !isGlazingFeasible &&
    !isRoofInsulated &&
    hasFlatRoof
  ) {
    return ExitStatementKey.OnlyRoofInterestButFlatRoof;
  }

  if (answers[QKeyHeating.ExtraCostsForConvectorPitsCheck] === false) {
    return ExitStatementKey.OnlyHeatPumpInterestButConvectorPits;
  }
  if (answers[QKeyHeating.ExtraCostsForHotAirHeatingCheck] === false) {
    return ExitStatementKey.OnlyHeatPumpInterestButHotAirHeating;
  }

  if (answers[QKeyGeneral.LowSavingsForHeatPumpCheck] === false) {
    return ExitStatementKey.LowSavingsPotentialForHeatPump;
  }
  if (answers[QKeyGeneral.LowSavingsForInsulationCheck] === false) {
    return ExitStatementKey.LowSavingsPotentialForInsulation;
  }
};

function exhaustiveCheck(x: never): never {
  throw new Error(`Unhandled case: ${x}`);
}

export const useExitScenarios = (scenario: ExitStatementKey): JSX.Element | undefined => {
  const intercom = useIntercom();
  if (!scenario) return;

  const title = "Dat was 'em!";
  let subtitle = '';
  let icon: JSX.Element | null = null;
  let body: JSX.Element | null = null;
  const contactUs = (
    <p>
      We blijven voor je klaar staan. Mocht je dus nog vragen hebben over jouw woning en
      energiebesparing, kun je die altijd stellen{' '}
      <TextLink onClick={() => intercom.showNewMessage()}>
        <u>via de chat</u>.
      </TextLink>
    </p>
  );

  switch (scenario) {
    case ExitStatementKey.IsMonument:
      icon = <Lighthouse />;
      subtitle = 'Uit de intake blijkt dat je in een monumentaal pand woont.';
      body = (
        <p>
          We zijn ook eerlijk als we denken dat we je minder goed van dienst kunnen zijn. Wij en
          veel van onze installatiepartners zijn geen specialisten op het gebied van het
          verduurzamen van monumentale panden. Voor advies op dat gebied verwijzen we je graag door
          naar{' '}
          <a href="https://monumenten.nl" target="_blank" rel="noopener noreferrer">
            monumenten.nl
          </a>
          .
        </p>
      );
      break;
    case ExitStatementKey.IsApartment:
      icon = <Buildings />;
      subtitle = 'Uit de intake blijkt dat je in een appartement woont.';
      body = (
        <>
          <p>
            We zijn ook eerlijk als we denken dat we je minder goed van dienst kunnen zijn. Zonder
            uitgesproken akkoord van de VvE om een verduurzaming in gang te zetten, kun je als
            individuele appartementeigenaar vaak weinig tot geen stappen ondernemen.
          </p>

          <p>
            Daarom is de eerste stap een nadrukkelijk akkoord van de VvE om verduurzamingsstappen te
            ondernemen, voordat wij een verduurzamingsadvies (voor het gehele pand of complex)
            kunnen afgeven.
          </p>

          <p>
            Je kunt wel alvast de mogelijkheden voor jouw woning verkennen met onze{' '}
            <Link href="/bespaarcheck">Bespaarcheck</Link>. Die geeft je inzicht in welke
            verduurzamende oplossingen mogelijk geschikt zijn voor je woning en een schatting van de
            investering en besparing.
          </p>

          <p>
            Is er al een overeenstemming van de VvE om te verduurzamen? Neem contact met ons op.
          </p>
        </>
      );
      break;
    case ExitStatementKey.OnlyChargingBox:
      icon = <Fan />;
      subtitle =
        'Uit de intake blijkt dat we je alleen verder zouden kunnen helpen met een laadpaal.';
      body = <p>Energiebespaarders biedt op dit moment geen laadpalen aan.</p>;
      break;
    case ExitStatementKey.OnlyGlazing:
      icon = <GridFour />;
      subtitle =
        'Uit de intake blijkt dat je ons vooral hebt benaderd voor het verbeteren van het glas in je woning.';
      body = (
        <>
          <p>
            We zijn ook eerlijk als we denken dat we je minder goed van dienst kunnen zijn. We
            hebben op dit moment een relatief klein aanbod van installatiepartners op het gebied van
            glas. Bovendien hebben deze partners het heel druk en is de doorlooptijd daarom relatief
            hoog.
          </p>

          <p>
            We raden je daarom aan om op zoek te gaan naar een lokale glaszetter. Om er eentje te
            vinden kun je gebruik maken van de website van onze partner{' '}
            <a href="https://solvari.nl" target="_blank" rel="noopener noreferrer">
              Solvari
            </a>
            .
          </p>
        </>
      );
      break;
    case ExitStatementKey.OnlyGlazingOrChargingBox:
      subtitle =
        'Uit de intake blijkt dat je ons vooral hebt benaderd voor isolatieglas en een laadpaal.';
      body = (
        <>
          <p>
            We zijn ook eerlijk als we denken dat we je minder goed van dienst kunnen zijn. We
            hebben op dit moment een relatief klein aanbod van installatiepartners op het gebied van
            glas. Bovendien hebben deze partners het heel druk en is de doorlooptijd daarom relatief
            hoog.
          </p>

          <p>
            We raden je daarom aan om op zoek te gaan naar een lokale glaszetter. Om er eentje te
            vinden kun je gebruik maken van de website van onze partner{' '}
            <a href="https://solvari.nl" target="_blank" rel="noopener noreferrer">
              Solvari
            </a>
            .
          </p>
        </>
      );
      break;
    case ExitStatementKey.OnlyHeatPumpInterestButConvectorPits:
      icon = <SquareHalfBottom />;
      subtitle =
        'We hebben begrepen dat je alleen in een warmtepomp geïnteresseerd bent en een convectorput als warmteafgiftesysteem hebt.';
      body = (
        <>
          <p>
            We zijn ook eerlijk als we denken dat we je minder goed van dienst kunnen zijn.
            Convectorputten zijn niet goed te combineren met een warmtepomp. De warmteafgifte van
            convectorputten is te hoog voor een warmtepomp. Hierdoor zal de warmtepomp niet goed
            kunnen functioneren en zal je huis niet goed warm worden.
          </p>
          <p>
            Dit betekent dat je waarschijnlijk je warmteafgiftesysteem zult moeten vervangen om over
            te stappen op een warmtepomp. Wees er van bewust dat dit in totaal een behoorlijk
            prijzige ingreep is. Dit komt uit eerdere ervaringen neer op €5.000 tot €10.000 extra
            boven op de kosten van de warmtepomp-installatie.
          </p>
        </>
      );
      break;
    case ExitStatementKey.OnlyHeatPumpInterestButHotAirHeating:
      icon = <Waves />;
      subtitle =
        'We hebben begrepen dat je alleen in een warmtepomp geïnteresseerd bent en heteluchtverwarming hebt.';
      body = (
        <>
          <p>
            We zijn ook eerlijk als we denken dat we je minder goed van dienst kunnen zijn. Een
            warmtepomp in combinatie met heteluchtverwarming is mogelijk, maar is een specialisatie
            die niet veel installateurs hebben. Zo hebben wij geen installatiepartners die hierin
            gespecialiseerd zijn.
          </p>
          <p>
            Je kunt er ook voor kiezen je warmteafgiftesysteem te vervangen. Wees er van bewust dat
            dit in totaal een behoorlijk prijzige ingreep is. Dit komt uit eerdere ervaringen neer
            op €5.000 tot €10.000 extra boven op de kosten van de warmtepomp-installatie.
          </p>
        </>
      );
      break;
    case ExitStatementKey.OnlyWallInterestButNoCavity:
      icon = <Wall />;
      subtitle =
        'Uit de intake blijkt dat het niet mogelijk is om je verder te helpen met spouwmuurisolatie.';
      body = (
        <p>
          Omdat je geen spouwmuur hebt kunnen we je niet verder helpen met de isolatie van je muren,
          omdat we alleen spouwmuurisolatie als vorm van gevelisolatie aanbieden.
        </p>
      );
      break;
    case ExitStatementKey.OnlyRoofInterestButFlatRoof:
      icon = <HouseSimple />;
      subtitle = 'Uit de intake blijkt dat we je vooral met dakisolatie verder kunnen helpen.';
      body = (
        <>
          <p>
            We zijn ook eerlijk als we denken dat we je minder goed van dienst kunnen zijn. We
            hebben op dit moment een relatief klein aanbod van installatiepartners op het gebied van
            het isoleren van platte daken. Bovendien hebben deze partners het heel druk en is de
            doorlooptijd daarom relatief hoog.
          </p>
          <p>
            We raden het daarom aan om op zoek te gaan naar een lokale installateur. Om er eentje te
            vinden kun je gebruik maken van de website van onze partner Solvari.
          </p>
        </>
      );
      break;
    case ExitStatementKey.OnlyFloorInterestButAlreadyInsulated:
      icon = <CheckCircle />;
      subtitle = 'Je hebt aangegeven alleen interesse te hebben in vloerisolatie.';
      body = (
        <p>
          Omdat je al vloerisolatie hebt, is het niet mogelijk om je verder te helpen met
          vloerisolatie.
        </p>
      );
      break;
    case ExitStatementKey.OnlyPvInterestButThatchedRoof:
      icon = <SunHorizon />;
      subtitle = 'Uit de intake blijkt dat je ons vooral hebt benaderd voor zonnepanelen.';
      body = (
        <>
          <p>
            Onderdeel van ons advies is dat we kijken of er iets te verbeteren is in jouw woning.
            Dat wil zeggen dat we eerlijk blijven als er géén mogelijkheden tot verbetering zijn.
          </p>
          <p>
            <Medium>
              De aanwezige rieten dakbedekking is helaas niet geschikt voor zonnepanelen.
            </Medium>
          </p>
        </>
      );
      break;
    case ExitStatementKey.OnlyFloorInterestButInaccessibleCrawlspace:
      icon = <ProhibitInset />;
      subtitle =
        'Uit de intake blijkt dat het niet mogelijk is om je verder te helpen met vloer- of bodemisolatie.';
      body = (
        <>
          <p>
            We raden je aan eerst te overwegen om je kruipruimte toegankelijk te maken met een
            lokale aannemer of klusjesman. Zonder toegankelijke kruipruimte kan er niet goed
            ingeschat worden hoe en of de ruimte geïsoleerd kan worden.
          </p>
          <p>
            Het kan ook zijn dat de kruipruimte niet geheel toegankelijk kan worden gemaakt of dat
            er andere obstakels optreden. Dit omdat er veel puin of leidingwerk aanwezig is of
            wanneer uiteindelijk blijkt dat de ruimte niet hoog genoeg is om te isoleren (minder dan
            35 centimeter). In deze situaties heeft het isoleren geen rendement op het besparen van
            energie en zal dit geen toename in wooncomfort met zich meebrengen. Mocht dit het geval
            zijn, adviseren wij je om de vloer te vervangen door een geïsoleerde balken broodjes
            vloer of schuimbeton. Ook hiervoor verwijzen we je naar een lokale aannemer of
            klusjesman.
          </p>
        </>
      );
      break;
    case ExitStatementKey.OnlyFloorInterestButNoCrawlspace:
      icon = <ProhibitInset />;
      subtitle =
        'Uit de intake blijkt dat het niet mogelijk is om je verder te helpen met vloer- of bodemisolatie.';
      body = (
        <>
          <p>
            Onderdeel van ons advies is dat we kijken of er iets te verbeteren is in jouw woning.
            Dat wil zeggen dat we eerlijk blijven als er géén mogelijkheden tot verbetering zijn.
          </p>
          <p>Zonder kruipruimte is het niet mogelijk om je vloer te isoleren.</p>
        </>
      );
      break;
    case ExitStatementKey.LowSavingsPotentialForInsulation:
      icon = <PiggyBank />;
      subtitle =
        'Uit de intake blijkt dat je weinig besparing zult halen uit het (verder) isoleren van je woning.';
      body = (
        <>
          <p>
            Omdat je hebt aangegeven vooral te willen verduurzamen om geld te besparen, denken we
            dat het voor jouw woning niet erg de moeite waard is om (verder) te isoleren.
          </p>
          <p>
            Je kunt de keuze alsnog maken om je wooncomfort te verbeteren. Wil je om deze reden toch
            verder met je aanvraag? Neem contact met ons op.
          </p>
        </>
      );
      break;
    case ExitStatementKey.OnlyInsulationInterestButAfter1992:
      icon = <CheckCircle />;
      subtitle = 'Je hebt aangegeven alleen interesse te hebben in isolatie.';
      body = (
        <>
          <p>We zijn ook eerlijk als we denken dat we je minder goed van dienst kunnen zijn.</p>

          <p>
            Woningen die gebouwd zijn vanaf 1992 zijn de eerste woningen waaraan de isolatie-eisen
            duidelijk en wettelijk in het Bouwbesluit vast gelegd zijn. In 1992 gold er een minimale
            eis van Rc-waarde 2.5 voor de totale bouwschil.
          </p>

          <p>
            Dit betekent dat deze woningen eigenlijk al behoorlijk goed geïsoleerd zijn. Met verdere
            isolatie zul je dus niet veel meer besparing bereiken.
          </p>
        </>
      );
      break;
    case ExitStatementKey.OnlyGlazingInterestButAlreadyHR:
      icon = <CheckCircle />;
      subtitle =
        'Je hebt aangegeven alleen interesse te hebben in isolatieglas, maar er is al HR-glas aanwezig.';
      body = (
        <>
          <p>We zijn ook eerlijk als we denken dat we je minder goed van dienst kunnen zijn.</p>

          <p>
            Dit betekent dat je woning al voorzien is van goed isolatieglas. Met het vervangen van
            je ramen zul je dus niet veel meer besparing bereiken.
          </p>

          <p>
            Ben je op zoek naar nieuwe kozijnen? We kunnen we je daar op dit moment nog niet mee
            helpen. We raden je in dat geval aan om op zoek te gaan naar een lokale partij. Om er
            eentje te vinden kun je gebruik maken van de website van onze partner{' '}
            <a href="https://solvari.nl" target="_blank" rel="noopener noreferrer">
              Solvari
            </a>
            .
          </p>
        </>
      );
      break;
    case ExitStatementKey.OnlyRoofInterestButAlreadyInsulated:
      subtitle =
        'Je hebt aangegeven alleen interesse te hebben in dakisolatie, maar deze is al geïsoleerd.';
      body = (
        <p>
          Onderdeel van ons advies is dat we kijken of er iets te verbeteren is in jouw woning. Dat
          wil zeggen dat we ook eerlijk zijn als er nu geen mogelijkheden zijn. Je hebt aangegeven
          dat je dak al geïsoleerd is. Met verdere isolatie zul je dus niet veel meer besparing
          bereiken.
        </p>
      );
      break;
    case ExitStatementKey.OnlyWallInterestButAlreadyInsulated:
      icon = <CheckCircle />;
      subtitle =
        'Je hebt aangegeven alleen interesse te hebben in spouwmuurisolatie, maar deze is al geïsoleerd.';
      body = (
        <p>
          Onderdeel van ons advies is dat we kijken of er iets te verbeteren is in jouw woning. Dat
          wil zeggen dat we ook eerlijk zijn als er nu geen mogelijkheden zijn. Je hebt aangegeven
          dat je spouwmuren al geïsoleerd zijn. Met verdere isolatie zul je dus niet veel meer
          besparing bereiken.
        </p>
      );
      break;
    case ExitStatementKey.FullyRetrofitted:
      icon = <CheckCircle />;
      subtitle = 'Uit de intake blijkt dat jouw woning al volledig is verduurzaamd.';
      body = (
        <>
          <p>
            Onderdeel van ons advies is dat we kijken of er iets te verbeteren is in jouw woning.
            Dat wil zeggen dat we ook eerlijk zijn als er nu geen mogelijkheden zijn. Jouw woning
            doet het al supergoed op gebied van energiebesparing en dus denken we dat een huisbezoek
            van energie-adviseur geen nieuwe kansen biedt.
          </p>
        </>
      );
      break;
    case ExitStatementKey.LowSavingsPotentialForHeatPump:
      icon = <PiggyBank />;
      subtitle = 'Uit de intake blijkt dat een warmtepomp voor jouw situatie niet rendabel is.';
      body = (
        <>
          <p>
            We zien dat je interesse hebt in een warmtepomp, maar ook al een relatief laag
            gasverbruik. Waarschijnlijk zal de terugverdientijd van een warmtepomp daarom aan de
            hoge kant liggen. Je kunt al snel denken 15 jaar of meer. Voor sommige mensen is de
            terugverdientijd van een warmtepomp minder belangrijk dan bijvoorbeeld de wens om
            helemaal van het gas af te gaan.
          </p>
          <p>
            Mocht je toch door willen met je aanvraag voor een warmtepomp, neem contact met ons op.
          </p>
        </>
      );
      break;
    default:
      console.warn(`scenario ${scenario} not implemented`);
      subtitle = 'Er is iets misgegaan.';
      body = null;
      return exhaustiveCheck(scenario);
  }
  return (
    <div>
      {icon !== null && (
        <IconContext.Provider value={{ color: themify('green'), size: 32, weight: 'duotone' }}>
          {icon}
        </IconContext.Provider>
      )}
      <Heading heading="h3">{title}</Heading>
      <Heading heading="h4">{subtitle}</Heading>
      {body}
      {body !== null && contactUs}
    </div>
  );
};
