import { Box, Flex, Image } from '@energiebespaarders/symbols';
import { Heading } from '@energiebespaarders/symbols/helpers';
import { useIsTablet } from '@energiebespaarders/symbols/hooks';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import { readableColor } from 'polished';
import { useMeasure } from 'react-use';
import styled from 'styled-components';
import { CTACardBlockFragment } from '~/types/generated/cms/CTACardBlockFragment';
import BlockContainer from '../DatoCms/BlockContainer';
import DatoButton from './DatoButton';

interface Props {
  block: CTACardBlockFragment;
}

const CardContainer = styled(Flex)<{ $isTablet: boolean; $bgColor: string }>`
  background-color: ${x => themify(x.$bgColor)};
  color: ${x => readableColor(themify(x.$bgColor) || 'white', themify('grayBlack'))};
  max-width: 1050px;
  height: max-content;
  border-radius: 12px;
  width: 100%;

  svg {
    height: 100%;
    width: min-content;
    margin-left: auto;
  }
`;

const StyledImage = styled(Image)`
  clip-path: url(#CTACardClipPath);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  object-fit: cover;
  width: 100%;
  border-radius: 0px 12px 12px 0px;
  object-fit: cover;
`;

const ContentContainer = styled(Flex)<{ $centerContent: boolean }>`
  align-items: ${x => (x.$centerContent ? 'center' : 'unset')};
  justify-content: center;

  p {
    margin: 0px;
    margin-bottom: 24px;
  }
`;

const DesktopSVG = () => (
  <svg width="0" height="0" viewBox="0 0 505 350" fill="none" xmlns="http://www.w3.org/2000/svg">
    <clipPath id="CTACardClipPath" clipPathUnits="objectBoundingBox">
      <path
        d="M9.43306 350C-2.50993 235.71 -3.28142 118.629 8.08455 0H495C500.523 0 505 4.47717 505 10V340C505 345.523 500.523 350 495 350H9.43306Z"
        fill="#FCDA3A"
        transform={`scale(${1 / 505}, ${1 / 350})`}
      />
    </clipPath>
  </svg>
);

const CTACard: React.FC<Props> = ({ block }) => {
  const hasImage = !!block.ctaCard?.image?.url;
  const isTablet = useIsTablet();
  const [measureRef, { height }] = useMeasure<HTMLDivElement>();
  return (
    <BlockContainer>
      <div ref={measureRef}>
        <CardContainer
          $bgColor={block.bgColor?.hex || 'lavender'}
          $isTablet={isTablet}
          flexWrap="wrap"
          flexDirection={isTablet ? 'column-reverse' : 'row'}
        >
          <ContentContainer
            $centerContent={!hasImage || isTablet}
            flexDirection="column"
            width={hasImage ? [1, 1, 1, 1 / 2] : 1}
            py={3}
            px={isTablet ? 3 : 5}
          >
            <Heading heading="h3" fontSize={isTablet ? 3 : 2} mb={2}>
              {block.ctaCard?.title}
            </Heading>
            <p>{block.ctaCard?.description}</p>
            <Box width={isTablet ? 1 : undefined}>
              <DatoButton
                block={block.ctaCard!.button[0]!}
                customStyle={{ width: isTablet ? '100%' : '', margin: 0 }}
              />
            </Box>
          </ContentContainer>

          {hasImage && !isTablet ? (
            <Flex height={height} style={{ position: 'relative' }} width={[1, 1, 1 / 2, 1 / 2]}>
              {!isTablet && <DesktopSVG />}
              <StyledImage
                src={block.ctaCard?.image?.url}
                alt={block.ctaCard?.image?.responsiveImage?.alt || ''}
                height={`${height}px`}
              />
            </Flex>
          ) : (
            <Box style={{ height: 250 }} width={[1, 1, 1, 1 / 2]} px={3} pt={3}>
              <Image
                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 6 }}
                src={block.ctaCard?.image?.url}
                alt={block.ctaCard?.image?.responsiveImage?.alt || ''}
              />
            </Box>
          )}
        </CardContainer>
      </div>
    </BlockContainer>
  );
};

export default CTACard;
