import { useIsMobile } from '@energiebespaarders/hooks';
import { Box, Button, Flex, Image } from '@energiebespaarders/symbols';
import { Heading, Small } from '@energiebespaarders/symbols/helpers';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { useKeyPressEvent } from 'react-use';
import styled from 'styled-components';
import ExplodedViewImg from 'public/img/illustrations/exploded.png';
import QuotesImg from 'public/img/illustrations/quotes.png';
import SolutionsImg from 'public/img/illustrations/solutions.png';
import OmniformCard from '../Omniform/OmniformCard';
import { CustomerIntakeInfoContext } from './CustomerIntakeInfoContext';

const StickyButtons = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: white;
  border-top: 1px solid #eee;
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.1);
`;

const threeSteps = [
  {
    image: ExplodedViewImg,
    title: '1. Vertel ons over je woning',
    description:
      'Beantwoord wat vragen over jezelf en je woning, zoals je gas- en elektraverbruik en de huidige staat van je woning.',
  },
  {
    image: SolutionsImg,
    title: '2. Check jouw mogelijkheden',
    description:
      'We berekenen met welke producten jij waarschijnlijk kunt verduurzamen, inclusief prijsindicatie, besparing en terugverdientijden.',
  },
  {
    image: QuotesImg,
    title: '3. Plan een advies en ontvang offertes',
    description:
      'Ben je geïnteresseerd in een of meerdere oplossingen? Plan dan direct een adviesgesprek in en laat offertes opstellen van betrouwbare installateurs.',
  },
];

interface IntakeWelcomeCardProps {
  onNext?: () => void;
}

const IntakeWelcomeCard: React.FC<IntakeWelcomeCardProps> = ({ onNext }) => {
  const router = useRouter();
  const isMobile = useIsMobile();

  useKeyPressEvent('ArrowRight', onNext);
  useKeyPressEvent('Enter', onNext);

  const [customerIntakeInfo] = useContext(CustomerIntakeInfoContext);
  const alreadySubmitted = !!customerIntakeInfo?.submittedOn;

  const goToEstimates = () => router.push('/intake/indicaties');

  const GoToEstimatesButton = () => (
    <Button
      bgColor="yellow"
      color="grayBlack"
      onClick={goToEstimates}
      fluid
      style={{ borderRadius: 3, whiteSpace: 'nowrap' }}
      label="Naar prijsindicaties →"
      mb={0}
    />
  );
  const StartButton = () => (
    <Button
      bgColor="yellow"
      color="grayBlack"
      onClick={onNext}
      fluid
      style={{ borderRadius: 3, whiteSpace: 'nowrap' }}
      label="Begin met verkennen →"
      mb={0}
    />
  );
  const RestartButton = () => (
    <Button
      bgColor="grayDark"
      color="white"
      onClick={onNext}
      inverse
      fluid
      style={{ borderRadius: 3, whiteSpace: 'nowrap' }}
      label="Opnieuw doorlopen"
      mb={0}
    />
  );

  return (
    <>
      <OmniformCard p={isMobile ? 4 : 8} animation="none" id="omniform-start">
        <Flex flexWrap="wrap" alignItems="center" mx={-2}>
          <Box width={[1, 1, 1, 2 / 5]} px={2}>
            <Heading heading="h2" style={{ lineHeight: isMobile ? 1.5 : 1 }}>
              Zo helpen we je verder
            </Heading>
          </Box>

          <Box width={[1, 1, 1, 3 / 5]} px={2}>
            {threeSteps.map((step, index) => (
              <Flex
                key={`step-${index}`}
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                mx={-1}
                style={index < 2 ? { borderBottom: '1px solid #eee' } : {}}
                pt={index > 0 ? 2 : 0}
                pb={index < 2 ? 2 : 0}
              >
                <Box width={1 / 3} px={1} textAlign="center">
                  <Image src={step.image} alt={step.title} style={{ maxHeight: '120px' }} />
                </Box>
                <Box width={2 / 3} px={1}>
                  <Heading heading="h4" style={{ margin: 0 }}>
                    {step.title}
                  </Heading>
                  <Small>
                    <p>{step.description}</p>
                  </Small>
                </Box>
              </Flex>
            ))}
            {alreadySubmitted ? (
              <Flex
                flexWrap="wrap"
                alignItems="flex-end"
                mx={-1}
                flexDirection={['column-reverse', 'row']}
                mt={5}
              >
                <Box width={[1, 1, 1 / 2]} px={1}>
                  <RestartButton />
                </Box>
                {!isMobile && (
                  <Box width={[1, 1, 1 / 2]} px={1}>
                    <GoToEstimatesButton />
                  </Box>
                )}
              </Flex>
            ) : isMobile ? (
              <></>
            ) : (
              <Box mt={5}>
                <StartButton />
              </Box>
            )}
          </Box>
        </Flex>
      </OmniformCard>

      {isMobile && (
        <StickyButtons p={1}>
          {alreadySubmitted ? <GoToEstimatesButton /> : <StartButton />}
        </StickyButtons>
      )}
    </>
  );
};

export default IntakeWelcomeCard;
