import { NodeType } from '../../../types/graphql-global-types';
import { FlowCondition } from './flow-condition';
import type { OmniformQuestion, OmniformStatement } from '../types';
import type { Flow, FlowEdge, FlowNode } from './flow';

// !!! WARNING !!!
// This file is currently copied over from GQL: src/omniform/omniform-flow.ts
// Make sure to keep them in sync!
// TODO: move these classes to a separate package so we can share common logic better.

/**
 * An OmniformFlow prescribes which questions an Omniform responder will get to see in which order,
 * while they are filling in their response.
 *
 * Such a flow object is essentially a graph with directed edges, where each edge can be traversed or not
 * depending on a 'flow condition', i.e. a function that takes some data (data that is prerequisite to the Omniform
 * and / or the answers the user has provided so far) and returns true or false to determine whether the target node
 * should be included in the next nodes or not (thus, which questions the user will see next).
 */
export class OmniformFlow<ConditionData extends Record<string, any>>
  implements Flow<OmniformQuestion | OmniformStatement, ConditionData>
{
  nodes: FlowNode<OmniformQuestion | OmniformStatement>[];
  edges: FlowEdge<ConditionData>[];

  constructor(flow: Flow<OmniformQuestion | OmniformStatement, ConditionData>) {
    this.nodes = flow.nodes;
    this.edges = flow.edges;
  }

  /** The start nodes of an Omniform Flow are all the nodes that have `node.type === 'start'` */
  get startNodes(): FlowNode<OmniformQuestion | OmniformStatement>[] {
    return this.nodes.filter(node => node.type === NodeType.start);
  }

  /** Determines which nodes in a flow graph are reachable based on the flow condition data */
  reachableNodes(data: ConditionData): FlowNode<OmniformQuestion | OmniformStatement>[] {
    const reachableNodes = this.startNodes;
    let newlyReachableNodes = reachableNodes;

    while (newlyReachableNodes.length) {
      newlyReachableNodes = this.nextNodes(newlyReachableNodes, data);
      reachableNodes.push(...newlyReachableNodes);
    }

    return this.nodes.filter(node => reachableNodes.includes(node));
  }

  getNextNodesFromNode(
    data: ConditionData,
    node: FlowNode<OmniformQuestion | OmniformStatement>,
  ): FlowNode<OmniformQuestion | OmniformStatement>[] {
    const nodeEdges = this.edges.filter(edge => edge.source === node.id);

    const unconditionalEdges = nodeEdges.filter(edge => Object.keys(edge.condition).length === 0);
    const conditionalEdges = nodeEdges.filter(edge => Object.keys(edge.condition).length > 0);

    let nextNodes = nodeEdges.flatMap(edge => {
      return FlowCondition.evaluate(edge.condition, data) ? [edge.target] : [];
    });

    // if we have conditional edges and unconditional edges we ignore the unconditional edges if any conditional edge can be traversed
    if (conditionalEdges.length >= 1 && unconditionalEdges.length >= 1) {
      const conditionalNodes = conditionalEdges.flatMap(edge => {
        return FlowCondition.evaluate(edge.condition, data) ? [edge.target] : [];
      });
      if (conditionalNodes.length > 0) {
        nextNodes = conditionalNodes;
      }
    }

    return this.nodes.filter(node => nextNodes.includes(node.id));
  }

  /**
   * Starting from a specific set of nodes, the next nodes are determined by following all the edges
   * going out from those nodes, checking if their flow conditions evaluate to true on the given data,
   * and then collecting the target nodes of all traversable edges.
   */
  nextNodes(
    currentNodes: FlowNode<OmniformQuestion | OmniformStatement>[],
    data: ConditionData,
  ): FlowNode<OmniformQuestion | OmniformStatement>[] {
    const res = currentNodes.flatMap(node => this.getNextNodesFromNode(data, node));
    // filter duplicate nodes
    return Array.from(new Set(res));
  }
}
