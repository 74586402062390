/**
 * ! There is a carbon copy of this file in graphql-api, keep in sync!
 */
export enum QKeyGeneral {
  HouseIsApartment = 'house-is-apartment',
  HouseType = 'house-type',
  HouseIsMonument = 'house-is-monument',
  CustomerMotivation = 'customer-motivation',
  CustomerSolutionInterest = 'customer-solution-interest',
  CustomerIsLivingInHouse = 'customer-is-living-in-house',
  CustomerWillMoveIntoHouse = 'customer-will-move-into-house',
  CustomerRetrofitTimeline = 'customer-retrofit-timeline',
  CustomerStayDuration = 'customer-stay-duration',
  CustomerBudget = 'customer-budget',
  HouseResidentAmount = 'resident-amount',
  HouseSlurpers = 'house-slurpers',
  HousePVPanelAmount = 'house-pv-panel-amount',
  HouseElectricityConsumption = 'house-electricity-consumption',
  HouseGasConsumption = 'house-gas-consumption',
  HouseElectricityConsumptionMonthlyCost = 'house-electricity-consumption-monthly-cost',
  HouseGasConsumptionMonthlyCost = 'house-gas-consumption-monthly-cost',
  LowSavingsForInsulationCheck = 'low-savings-for-insulation-check',
  LowSavingsForHeatPumpCheck = 'low-savings-for-heat-pump-check',
}

export enum QKeyRoof {
  HouseRoofTypes = 'house-roof-types',
  HouseRoofingMaterial = 'house-roofing-material',
}

export enum QKeyHeating {
  HouseHeatingType = 'house-heating-type',
  HouseHeatingBoilerAge = 'house-heating-boiler-age',
  HouseHeatEmissionSystems = 'house-heat-emission-systems',
  ExtraCostsForConvectorPitsCheck = 'extra-costs-for-convector-pits-check',
  ExtraCostsForHotAirHeatingCheck = 'extra-costs-for-hot-air-heating-check',
}

export enum QKeyWallInsulation {
  HouseWallInsulation = 'house-wall-insulation',
}

export enum QKeyFloorInsulation {
  HouseCrawlspaceInfo = 'house-crawlspace-info',
  HouseFloorInsulation = 'house-floor-insulation',
}
export enum QKeyRoofInsulation {
  HouseRoofInsulationSlanted = 'house-roof-insulation-slanted',
  HouseRoofInsulationFlat = 'house-roof-insulation-flat',
}

export enum QKeyGlazing {
  HouseGlazingTypeLiving = 'house-glazing-type-living',
  HouseGlazingTypeBedroom = 'house-glazing-type-bedroom',
}

// Combine all enums into one
export type QKeyCustomerIntake =
  | QKeyGeneral
  | QKeyRoof
  | QKeyHeating
  | QKeyWallInsulation
  | QKeyFloorInsulation
  | QKeyRoofInsulation
  | QKeyGlazing;

export enum ExitStatementKey {
  OnlyGlazing = 'only-glazing-statement',
  OnlyChargingBox = 'only-charging-box-statement',
  OnlyGlazingOrChargingBox = 'only-glazing-charging-box-statement',
  IsMonument = 'is-monument-statement',
  IsApartment = 'is-apartment-statement',
  LowSavingsPotentialForHeatPump = 'low-savings-potential-for-heat-pump-statement',
  LowSavingsPotentialForInsulation = 'low-savings-potential-for-insulation-statement',
  OnlyHeatPumpInterestButConvectorPits = 'only-heat-pump-interest-but-convector-pits-statement',
  OnlyHeatPumpInterestButHotAirHeating = 'only-heat-pump-interest-but-hot-air-heating-statement',
  OnlyInsulationInterestButAfter1992 = 'only-insulation-interest-but-after-1992-statement',
  OnlyRoofInterestButAlreadyInsulated = 'only-roof-interest-but-already-insulated-statement',
  OnlyRoofInterestButFlatRoof = 'only-roof-interest-but-flat-roof-statement',
  OnlyWallInterestButNoCavity = 'only-wall-interest-but-no-cavity-statement',
  OnlyWallInterestButAlreadyInsulated = 'only-wall-interest-but-already-insulated-statement',
  OnlyFloorInterestButAlreadyInsulated = 'only-floor-interest-but-already-insulated-statement',
  OnlyFloorInterestButInaccessibleCrawlspace = 'only-floor-interest-but-inaccessible-crawlspace-statement',
  OnlyFloorInterestButNoCrawlspace = 'only-floor-interest-but-no-crawlspace-statement',
  OnlyGlazingInterestButAlreadyHR = 'only-glazing-interest-but-already-hr-statement',
  OnlyPvInterestButThatchedRoof = 'only-pv-interest-but-thatched-roof-statement',
  FullyRetrofitted = 'fully-retrofitted-statement',
}

export type IntakeFlowData = {
  answers: Record<string, unknown>;
  houseId: string;
  constructionYear: number;
  houseType: number;
};

export type IntakeTextVariables = {
  constructionYear?: number;
  gasConsumption?: number;
  electricityConsumption?: number;
  gasConsumptionMonthlyCost?: number;
  electricityConsumptionMonthlyCost?: number;
};
