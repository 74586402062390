import { Box } from '@energiebespaarders/symbols';
import React from 'react';
import SavingsCheckModule from '~/components/SavingsCheck/SavingsCheckModule';
import CampaignContainer from '~/components/_cmsBlocks/CampaignContainer';
import CTACard from '~/components/_cmsBlocks/CTACard';
import CustomerFeedbackCarousel from '~/components/_cmsBlocks/CustomerFeedbackCarousel';
import DutyPackage from '~/components/_cmsBlocks/DutyPackage';
import FaqDropdown from '~/components/_cmsBlocks/FaqDropdown';
import PartnerCompanyBanner from '~/components/_cmsBlocks/PartnerCompanyBanner';
import PointCards from '~/components/_cmsBlocks/PointCards';
import PrimaryImage from '~/components/_cmsBlocks/PrimaryImage';
import QuotesOrPlanCTA from '~/components/_cmsBlocks/QuotesOrPlanCTA';
import RequestForm from '~/components/_cmsBlocks/RequestForm';
import RequestFormBlock from '~/components/_cmsBlocks/RequestFormBlock';
import Testimonial from '~/components/_cmsBlocks/Testimonial';
import config from '~/config';
import { themify } from '~/styles/mixins';
import { CTASectionBlockFragment } from '~/types/generated/cms/CTASectionBlockFragment';
import { dummy_dummy_content } from '~/types/generated/cms/dummy';
import { assertUnreachable } from 'src/typeHelpers';
import AnchorLinks from '../_cmsBlocks/AnchorLinks';
import BlogPostQuickLinks from '../_cmsBlocks/BlogPostQuickLinks';
import CTASection from '../_cmsBlocks/CTASection';
import DatoButton from '../_cmsBlocks/DatoButton';
import DatoStepper from '../_cmsBlocks/DatoStepper';
import Heading from '../_cmsBlocks/Heading';
import ImageBlock from '../_cmsBlocks/ImageBlockRecord';
import LongReadQuickLink from '../_cmsBlocks/LongReadQuickLink';
import MiscQuickLinks from '../_cmsBlocks/MiscQuickLinks';
import OmniformBlock from '../_cmsBlocks/OmniformBlock';
import StartIntakeCard from '../_cmsBlocks/StartIntakeCard';
import ProductPageContent from './ProductPageContent';
import TypedStructuredText from './TypedStructuredText';

export type CMSBlock = dummy_dummy_content;

// TODO: Do we want to use a Record instead?
// type PossibleBlockTypes = MyBlock['__typename'];
// export const BlockRecord: Record<PossibleBlockTypes, ReactNode> = {
//   AccordionRecord: Accordion,
//   BespaarcheckRecord
// };

export type BlockRendererOverrides = Partial<
  Record<CMSBlock['__typename'], React.FC<{ block: CMSBlock }>>
>;

interface BlockRendererProps {
  block: CMSBlock;
  overrides?: BlockRendererOverrides;
}

/**
 * Inspired by https://community.datocms.com/t/map-modular-content-field-to-react-component/2174/2
 *
 * More inspiration here https://github.com/datocms/new-website/blob/master/components/PostContent/index.js
 *
 * TODO: When there are a lot of blocks here eventually, use Next Dynamic imports
 *
 * @param props
 * @returns
 */

export function BlockRenderer({ block, overrides }: BlockRendererProps) {
  const type = block.__typename;

  if (overrides && type in overrides) {
    const Override = overrides[type]!;
    return <Override block={block} />;
  }

  switch (type) {
    case undefined:
      return <></>;

    case 'ButtonRecord':
      return <DatoButton block={block} />;

    case 'ComponentCustomerFeedbackRecord':
      return <CustomerFeedbackCarousel customers={block.customers} />;

    case 'CampaignContainerRecord':
      return <CampaignContainer block={block} />;

    case 'StructuredTextBlockRecord':
      return <TypedStructuredText data={block.structuredText} overrides={overrides} />;

    case 'BespaarcheckRecord':
      return (
        <Box width={1} py={10} style={{ background: themify('greenSlate') }}>
          <SavingsCheckModule />
        </Box>
      );

    case 'ImageBlockRecord':
      return <ImageBlock block={block} />;

    case 'ComponentRequestFormRecord':
      return <RequestFormBlock block={block} />;

    case 'ComponentDutyPackageRecord':
      return <DutyPackage block={block} />;

    case 'ComponentRequestFormStandaloneRecord':
      return <RequestForm block={block} />;

    case 'CtaCardBlockRecord':
      return <CTACard block={block} />;

    case 'CtaSectionBlockRecord':
      return <CTASection block={block as unknown as CTASectionBlockFragment} />;

    case 'HeadingRecord':
      return <Heading block={block} />;

    case 'PrimaryImageCardRecord':
      return <>PrimaryImageCardRecord</>;

    case 'PrimaryImageRecord':
      return <PrimaryImage block={block} />;

    case 'TestimonialBlockRecord':
      return <Testimonial block={block} />;

    case 'ProductPageContentRecord':
      return <ProductPageContent block={block} overrides={overrides} />;

    case 'FaqDropdownRecord':
      return <FaqDropdown block={block} />;

    case 'PartnerCompanyBannerRecord':
      return <PartnerCompanyBanner />;

    case 'PointCardsBlockRecord':
      return <PointCards block={block} />;

    case 'StepperRecord':
      return <DatoStepper block={block} />;

    case 'BlogPostQuickLinkRecord':
      return <BlogPostQuickLinks block={block} />;

    case 'MiscQuickLinkRecord':
      return <MiscQuickLinks block={block} />;

    case 'LongReadQuickLinkRecord': // TODO: refactor internals to use QuickLinks component
      return <LongReadQuickLink block={block} />;

    case 'QuotesOrPlanRecord':
      return <QuotesOrPlanCTA block={block} />;

    case 'AnchorLinkRecord':
      return <AnchorLinks block={block} />;

    case 'OmniformRecord':
      return <OmniformBlock block={block} />;

    case 'StartIntakeRecord':
      return <StartIntakeCard block={block} />;

    case 'TrusterRecord':
      return null; // ?

    default:
      return config.isProduction ? <></> : assertUnreachable(type);
  }
}
