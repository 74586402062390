import { Box, Card, Flex, Icon } from '@energiebespaarders/symbols';
import { Small } from '@energiebespaarders/symbols/helpers';
import { ArrowLeft } from '@energiebespaarders/symbols/icons/line';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import styled from 'styled-components';

const OmniformCard = styled(Card)`
  position: relative;
  min-height: 400px;
  z-index: 10;
  transform-origin: center;

  @keyframes grow {
    from {
      transform: scale(0.95);
    }
    to {
      transform: scale(1);
    }
  }

  animation: grow 0.15s;
`;

export const StyledOmniformPrevArrow = styled(Icon).attrs({
  icon: ArrowLeft,
  stroke: 'gray',
  hoverColor: 'grayDark',
})`
  ${mediaMin.md} {
    position: absolute;
    top: ${props => `calc(${props.theme.spacer} * 4)`};
    left: ${props => `calc(${props.theme.spacer} * 4)`};
    padding: 0;
    transition: all 0.2s ease-in-out;

    &:hover {
      left: ${props => `calc(${props.theme.spacer} * 3)`};
    }
  }
`;

const StyledArrowWrapper = styled(Flex)`
  position: absolute;
  top: ${props => `calc(${props.theme.spacer} * 4)`};
  left: ${props => `calc(${props.theme.spacer} * 4)`};
  cursor: pointer;
  line-height: 1;
  color: ${props => props.theme.colors.gray};
  transition: all 0.2s ease-in-out;

  &:hover {
    left: ${props => `calc(${props.theme.spacer} * 3)`};
    color: ${props => props.theme.colors.grayDark};

    & * {
      stroke: ${props => props.theme.colors.grayDark} !important;
    }
  }
`;

interface OmniformPrevArrowProps {
  onClick: () => void;
  text?: string;
}

export const OmniformPrevArrow: React.FC<OmniformPrevArrowProps> = ({ onClick, text }) => {
  return text ? (
    <StyledArrowWrapper alignItems="center" onClick={onClick}>
      <Box>
        <Icon icon={ArrowLeft} stroke="gray" hoverColor="grayDark" verticalAlign="text-bottom" />
      </Box>
      <Box pl={1}>
        <Small>{text}</Small>
      </Box>
    </StyledArrowWrapper>
  ) : (
    <StyledOmniformPrevArrow onClick={onClick} />
  );
};

export default OmniformCard;
