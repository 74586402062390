import { Button } from '@energiebespaarders/symbols';
import { ButtonProps } from '@energiebespaarders/symbols/components/Button';
import { dummy_dummy_content_ButtonRecord } from '~/types/generated/cms/dummy';

interface Props {
  block: dummy_dummy_content_ButtonRecord;
  customStyle?: any;
}

type ButtonType = 'Primary' | 'Secondary';

const getButtonProps = (buttonType: ButtonType) => {
  switch (buttonType) {
    case 'Primary':
      return {
        bgColor: 'yellow',
        color: 'grayBlack',
      };

    case 'Secondary':
      return {
        bgColor: 'purpleDarker',
        inverse: true,
      };
    default:
      return {};
  }
};

const DatoButton: React.FC<Props & ButtonProps> = ({ block, customStyle, ...buttonProps }) => {
  const { buttonType, label, url } = block;

  const variantProps = getButtonProps(buttonType as ButtonType);

  return (
    <Button
      px={5}
      mr={0}
      mb={0}
      style={{ ...customStyle }}
      href={url || undefined}
      label={label || ''}
      {...variantProps}
      {...buttonProps}
    />
  );
};

export default DatoButton;
