import { isUndefined } from '@energiebespaarders/constants';
import { useForm } from '@energiebespaarders/hooks';
import { Errors, isMissing } from '@energiebespaarders/hooks/useForm';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { AnswerInputType, NodeType, OmniformAnswerInput } from '~/types/graphql-global-types';
import { isBetween } from '~/utils/isBetween';
import { FlowNode } from './flows/flow';
import { OmniformFlow } from './flows/omniform-flow';
import {
  AnswerType,
  Omniform,
  OmniformQuestion,
  OmniformResponse,
  OmniformStatement,
  QuestionAnswerType,
} from './types';
import { OmniformAPI } from './useOmniformAPI';
import { resolveVariables } from './utils';

/**
 * FormValues is the type given to the values that {@link useForm} keeps track of while filling in the Omniform.
 * NOTE: this type is technically incorrect, numbers are actually stored as strings. But {@link EncodeAnswer} fixes that.
 */
type FormValues = Record<string, AnswerType<QuestionAnswerType>>;

const InitialValues: Record<QuestionAnswerType, boolean | string | []> = {
  [QuestionAnswerType.Boolean]: '',
  [QuestionAnswerType.String]: '',
  [QuestionAnswerType.Number]: '',
  [QuestionAnswerType.Date]: '',
  // NOTE: these `[] as []` typecasts are necessary because TypeScript assumes that the value `[]` in a const object is a readonly empty array, while the type `[]` is just a regular mutable array.
  [QuestionAnswerType.BooleanArray]: [] as [],
  [QuestionAnswerType.NumberArray]: [] as [],
  [QuestionAnswerType.StringArray]: [] as [],
  [QuestionAnswerType.DateArray]: [] as [],
} as const;

function getInitialValue(answerType: QuestionAnswerType) {
  return InitialValues[answerType];
}

function makeInitialValues(
  questions: OmniformQuestion[] | readonly OmniformQuestion[],
  response: OmniformResponse,
): FormValues {
  const initialValues = {} as FormValues;

  // the initial value for each question is filled with an answer from the response, if available.
  // null or undefined are currently not allowed in useForm, so if no answer is available, a default initial value is used.
  for (const question of questions) {
    const answer = response.answers.find(answer => answer.questionKey === question.key);
    initialValues[question.key] =
      !isUndefined(answer?.answer) && answer?.answeredAt
        ? answer.answer!
        : getInitialValue(question.answerType);
  }

  return initialValues;
}

// ------------------------------------------------------------------------------------------------

const EncodeAnswer: Record<
  QuestionAnswerType,
  (value: AnswerType<QuestionAnswerType>) => AnswerInputType
> = {
  [QuestionAnswerType.Boolean]: value => ({ boolean: value as boolean }),
  [QuestionAnswerType.String]: value => ({ string: value as string }),
  [QuestionAnswerType.Number]: value => ({ float: Number(value) }),
  [QuestionAnswerType.Date]: value => ({ date: value as Date }),
  [QuestionAnswerType.BooleanArray]: value => ({ booleanArray: value as boolean[] }),
  [QuestionAnswerType.StringArray]: value => ({ stringArray: value as string[] }),
  [QuestionAnswerType.NumberArray]: value => ({
    floatArray: (value as string[] | number[]).map(v => Number(v)),
  }),
  [QuestionAnswerType.DateArray]: value => ({ dateArray: value as Date[] }),
} as const;

function encodeAnswerInput<T extends QuestionAnswerType>(
  questionKey: string,
  answerType: T,
  value: AnswerType<T>,
): OmniformAnswerInput {
  const answer = EncodeAnswer[answerType](value);
  return { questionKey, answer };
}

// ------------------------------------------------------------------------------------------------

function makeNode(question: OmniformQuestion, type = NodeType.default): FlowNode<OmniformQuestion> {
  return { id: question.key, type, data: question };
}

// ------------------------------------------------------------------------------------------------

export type WithFormState<T> = T & {
  answer: AnswerType<QuestionAnswerType>;
  touched: boolean;
  error: string;
};

export interface UseOmniform {
  questions: WithFormState<OmniformQuestion>[];
  statements: OmniformStatement[];
  nodes: (WithFormState<OmniformQuestion> | OmniformStatement)[];
  errors: Errors<FormValues>;
  formHasErrors: boolean;
  answer<T extends QuestionAnswerType>(
    questionKey: string,
    answerType: T,
    answer: AnswerType<T>,
  ): void;
  submit(): void;
  submissionAttempted: boolean;
  resetSubmissionAttempted(): void;
}

export default function useOmniform<TFlowData extends Record<string, any>>(
  form: Omniform<OmniformFlow<TFlowData>>,
  response: OmniformResponse,
  api: OmniformAPI<TFlowData>,
  flowData?: TFlowData,
  updateFlowData?: (
    flowData: TFlowData,
    questionKey: string,
    answer: AnswerType<QuestionAnswerType>,
  ) => TFlowData,
  onSubmit?: (data: TFlowData) => void,
  textVariables: Record<string, unknown> = {},
  onAnswer?: (questionKey: string, answer: AnswerType<QuestionAnswerType>, data: TFlowData) => void,
): UseOmniform {
  const initialFlowData: TFlowData = useMemo(
    () => ({
      ...(flowData as TFlowData),
      answers: response.answers.reduce(
        (prev, curr) => ({ ...prev, [curr.questionKey]: curr.answer }),
        {},
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [response.answers],
  );

  const [currentFlowData, setCurrentFlowData] = useState(initialFlowData);

  // Overwrite the current flow data fields when they change in the parent component
  useEffect(() => {
    if (
      flowData &&
      !_.isEqual(_.omit(flowData, 'answers'), _.omit(initialFlowData, 'answers')) &&
      !_.isEqual(_.omit(flowData, 'answers'), _.omit(currentFlowData, 'answers'))
    ) {
      setCurrentFlowData({ ...currentFlowData, ...flowData, answers: currentFlowData.answers });
    }
  }, [currentFlowData, flowData, initialFlowData]);

  const initialNodes =
    form.flow?.reachableNodes(currentFlowData) ??
    form.questions.map(q => makeNode(q, NodeType.start));
  // we will always be displaying all the reachable questions as determined by the Omniform's flow (or just all questions if there is no flow).
  const [currentNodes, setCurrentNodes] = useState(initialNodes);

  const currentQuestions = currentNodes
    .filter(node => node.data.__typename === 'OmniformQuestion')
    .map(node => node.data) as OmniformQuestion[];
  const currentStatements = currentNodes
    .filter(node => node.data.__typename === 'OmniformStatement')
    .map(node => node.data) as OmniformStatement[];

  const {
    formState,
    handleChange,
    submitForm,
    errors,
    submissionAttempted,
    resetSubmissionAttempted,
    formHasErrors,
  } = useForm<FormValues>({
    blockEnterToSubmit: true,

    initialValues: makeInitialValues(form.questions, response),

    validate: (values, errors) => {
      currentQuestions.forEach(question => {
        if (question.isOptional) return;
        if (isMissing(values[question.key])) errors[question.key] = 'Ontbreekt';
        if (
          question.range &&
          question.answerType === QuestionAnswerType.Number &&
          !isBetween(Number(values[question.key]), question.range[0], question.range[1] + 1)
        ) {
          errors[question.key] = 'Niet binnen bereik';
        }
        if (question.answerType === QuestionAnswerType.StringArray) {
          if ((values[question.key] as AnswerType<QuestionAnswerType.StringArray>).length === 0) {
            errors[question.key] = 'Kies er minimaal 1';
          }
        }
      });
      return errors;
    },

    handleSubmit: values => {
      const answers = Object.keys(values).flatMap(questionKey => {
        const question = form.questions.find(q => q.key === questionKey)!;
        const answer = values[questionKey];
        const touched = formState[questionKey].touched;

        // Only submit answers that the user has entered (i.e. form input has been touched).
        // This prevents submitting pre-filled default values.
        if (!touched) return [];
        return [encodeAnswerInput(questionKey, question.answerType, answer)];
      });

      api.submitOmniformResponse(answers);
      onSubmit?.(currentFlowData);
    },
  });

  const addFormStateToQuestion = (
    omniformQuestion: OmniformQuestion,
  ): WithFormState<OmniformQuestion> => ({
    ...omniformQuestion,
    // before returning the questions to the component, we resolve any variables in the question text and description
    question: resolveVariables(omniformQuestion.question, textVariables),
    description: omniformQuestion.description
      ? resolveVariables(omniformQuestion.description, textVariables)
      : null,
    answer: formState[omniformQuestion.key].value,
    touched: formState[omniformQuestion.key].touched,
    error: formState[omniformQuestion.key].error,
  });

  return {
    questions: currentQuestions.map(addFormStateToQuestion),
    statements: currentStatements,
    nodes: currentNodes.map(node => {
      if (node.data.__typename === 'OmniformQuestion') return addFormStateToQuestion(node.data);
      if (node.data.__typename === 'OmniformStatement') return node.data as OmniformStatement;
      throw new Error('Unknown node type');
    }),

    errors,
    formHasErrors,

    submissionAttempted,
    resetSubmissionAttempted,

    /** Call this method to store an answer to this form  */
    answer<T extends QuestionAnswerType = QuestionAnswerType>(
      questionKey: string,
      answerType: T,
      answer: AnswerType<T>,
      skipNetwork?: boolean,
    ): void {
      // apply the answer to the form state
      handleChange({ [questionKey]: answer });

      let lastSeenNode: string | undefined;
      // update the Omniform Flow data and use it to update the current questions
      if (form.flow && flowData && updateFlowData) {
        const newFlowData = updateFlowData(currentFlowData, questionKey, answer);
        const newNodes = form.flow.reachableNodes(newFlowData);

        // find the next (unanswered) node
        const currentNodeIndex = newNodes.findIndex(node => node.data.key === questionKey);
        lastSeenNode = newNodes[currentNodeIndex + 1]?.data.key;

        setCurrentFlowData(newFlowData);
        setCurrentNodes(newNodes);
      }

      if (!skipNetwork) {
        api.saveOmniformResponse(
          [encodeAnswerInput(questionKey, answerType, answer)],
          lastSeenNode,
        );
      }

      onAnswer?.(questionKey, answer, currentFlowData);
    },

    /** Call this method when the user has finished filling in the form to submit their response (calls submitOmniformResponse on the backend) */
    submit(): void {
      submitForm();
    },
  };
}
