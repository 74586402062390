import { isUndefined } from '@energiebespaarders/constants';
import { useIsMobile } from '@energiebespaarders/hooks';
import { Box, Button, Container, Flex, Icon, theme } from '@energiebespaarders/symbols';
import { Medium } from '@energiebespaarders/symbols/helpers';
import { ArrowLeft, Information, Smile } from '@energiebespaarders/symbols/icons/line';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { useKeyPressEvent, useMeasure } from 'react-use';
import { useIntercom } from 'react-use-intercom';
import { AnswerType, OmniformQuestion, QuestionAnswerType } from '~/hooks/omniform/types';
import { WithFormState } from '~/hooks/omniform/useOmniform';
import { QuestionType } from '~/types/graphql-global-types';
import { IntakeTextVariablesContext } from '../CustomerIntake/IntakeTextVariablesContext';
import OmniformCard from './OmniformCard';
import renderQuestionInput from './OmniformInputs';
import OmniformQuestionHelper from './OmniformQuestionHelper';
import { renderInputHelper } from './inputHelpers';

interface QuestionCardProps {
  question: WithFormState<OmniformQuestion>;
  onAnswer: <T extends QuestionAnswerType>(
    questionKey: string,
    answerType: T,
    answer: AnswerType<T>,
    skipNetwork?: boolean,
  ) => void;
  onPrevious?: () => void;
  onNext?: () => void;
  isFinalQuestion: boolean;
}

const QuestionCard: React.FC<QuestionCardProps> = ({
  onAnswer,
  question,
  onPrevious,
  onNext,
  isFinalQuestion,
}) => {
  const router = useRouter();
  const intercom = useIntercom();
  const isMobile = useIsMobile();
  const textVariables = useContext(IntakeTextVariablesContext);

  const isArrayAnswer = [
    QuestionAnswerType.BooleanArray,
    QuestionAnswerType.DateArray,
    QuestionAnswerType.NumberArray,
    QuestionAnswerType.StringArray,
  ].includes(question.answerType);
  const hasError = question.touched && Boolean(question.error);
  const isMissingAnswer =
    !question.isOptional &&
    (isUndefined(question.answer) || (isArrayAnswer && _.isEmpty(question.answer)));
  const isSubmitDisabled = hasError || isMissingAnswer;
  const isInputFocused = document.activeElement?.tagName === 'INPUT';

  useKeyPressEvent('ArrowLeft', isInputFocused ? null : onPrevious);
  useKeyPressEvent('ArrowRight', () => (isSubmitDisabled || isInputFocused ? null : onNext?.()));
  useKeyPressEvent('Enter', () => (isSubmitDisabled ? null : onNext?.()));

  const [measureRef, { width }] = useMeasure<HTMLDivElement>();

  const handleAnswerOnNext = () => {
    if (isSubmitDisabled) return;
    onAnswer(question.key, question.answerType, question.answer, true);
    onNext?.();
    if (isFinalQuestion) void router.push('/intake/indicaties');
  };

  return (
    <OmniformCard px={4} py={isMobile ? 2 : 4} animation="none" id={`omniform-card-${question.id}`}>
      <Flex flexWrap="wrap">
        <Box width={1}>
          <Flex justifyContent="space-between">
            <Icon icon={onPrevious ? ArrowLeft : Smile} stroke="gray" onClick={onPrevious} />
            <Icon
              icon={Information}
              stroke="gray"
              onClick={() =>
                intercom.isOpen
                  ? intercom.hide()
                  : intercom.showNewMessage(
                      `Ik heb een vraag over de intake-vraag "${question.question}", namelijk: `, // TODO: Still intake-specific
                    )
              }
            />
          </Flex>
        </Box>

        <Box width={1} pt={[2, 3, 5]}>
          <Container size="md" textAlign="center">
            <p style={{ fontSize: theme.type.scale[4], letterSpacing: '-0.025em' }}>
              <Medium>{question.question}</Medium>
            </p>
            <p style={{ whiteSpace: 'pre-line' }}>{question.description}</p>
            {question.helpContentCMSId && (
              <OmniformQuestionHelper datoCmsKey={question.helpContentCMSId} />
            )}
          </Container>
        </Box>

        <Box width={1} pt={[2, 2, 5]} px={[0, 2, 5, 10]} ref={measureRef}>
          <Container
            size={
              question.type === QuestionType.radio || question.type === QuestionType.checkbox
                ? 'fluid'
                : 'sm'
            }
          >
            {renderQuestionInput(question, onAnswer, { hideLabels: true, inputWidth: width })}
            {renderInputHelper(question, onAnswer, textVariables)}{' '}
          </Container>
        </Box>

        <Box width={1} textAlign="center" py={[5, 5, 10]}>
          <Button
            bgColor="yellow"
            color="grayBlack"
            onClick={handleAnswerOnNext}
            disabled={isSubmitDisabled}
            type={isFinalQuestion ? 'submit' : 'button'}
            style={{ borderRadius: 3 }}
            py={2}
            px={10}
            mr={0}
            mb={0}
          >
            {isFinalQuestion ? 'Voltooien →' : 'Volgende →'}
          </Button>
        </Box>
      </Flex>
    </OmniformCard>
  );
};

export default QuestionCard;
