/* eslint-disable jsx-a11y/alt-text */
import { useIsMobile } from '@energiebespaarders/hooks';
import { Box, Container, Flex } from '@energiebespaarders/symbols';
import { Heading } from '@energiebespaarders/symbols/helpers';
import styled from 'styled-components';
import { fetchCampaignBySlug_allCampaigns_container } from '~/types/generated/cms/fetchCampaignBySlug';
import { BlockRenderer, CMSBlock } from '../DatoCms/BlockRenderer';

const StyledCampaignContent = styled(Box)`
  & img {
    border-radius: 6px;
  }
`;

interface Props {
  block: fetchCampaignBySlug_allCampaigns_container;
}

const CampaignContainer: React.FC<Props> = ({ block }) => {
  const isMobile = useIsMobile();
  const columnWidth = block.campaignContainerContent.length > 1 ? [1, 1, 1 / 2] : 1;
  const getFlexDirection = () => {
    if (!isMobile) return 'row';
    let direction = 'row';
    if (block.campaignContainerContent[0].__typename === 'StructuredTextBlockRecord') {
      direction = 'column-reverse';
    }
    return direction;
  };
  return (
    <Flex
      width={1}
      pt={[8, 10]}
      pb={[8, 10, '90px']}
      style={{ backgroundColor: block.bgColor?.hex || '' }}
      px={[2, 2, 3, 3, 0]}
    >
      <Container size="xl">
        {block.heading && (
          <Heading heading="h2" my={0} style={{ lineHeight: '1.25' }}>
            {block.heading}
          </Heading>
        )}
        <Flex
          mx={[-3, -3, -5]}
          flexWrap="wrap"
          alignItems="center"
          flexDirection={getFlexDirection()}
        >
          {block.campaignContainerContent.map((item, index) => (
            <StyledCampaignContent
              key={`${item.id}-${index}`}
              mb={index !== 2 ? [4, 4, 0] : 0}
              px={[3, 3, 5]}
              width={columnWidth}
            >
              <BlockRenderer block={item as unknown as CMSBlock} />
            </StyledCampaignContent>
          ))}
        </Flex>
      </Container>
    </Flex>
  );
};

export default CampaignContainer;
