import { gql, NetworkStatus, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import {
  customerIntakeByHouse,
  customerIntakeByHouseVariables,
  customerIntakeByHouse_customerIntakeByHouse,
} from '~/types/generated/customerIntakeByHouse';

export const GET_CUSTOMER_INTAKE_INFO = gql`
  query customerIntakeByHouse($houseId: ID!) {
    customerIntakeByHouse(houseId: $houseId) {
      submittedOn
      bucket
      canPlanOwnVisit
      interests
    }
  }
`;

type ContextValue = [
  customerIntakeByHouse_customerIntakeByHouse | undefined,
  (info?: customerIntakeByHouse_customerIntakeByHouse) => void,
  boolean,
  (pollInterval: number) => void,
  () => void,
  NetworkStatus | null,
];
export const CustomerIntakeInfoContext = React.createContext<ContextValue>([
  undefined,
  () => null,
  false,
  () => null,
  () => null,
  null,
]);

export const CustomerIntakeInfoProvider: React.FC = ({ children }) => {
  const { activeHouseId } = useActiveHouseId();
  const [customerIntakeInfo, setCustomerIntakeInfo] = useState<
    customerIntakeByHouse_customerIntakeByHouse | undefined
  >();

  const { loading, startPolling, stopPolling, networkStatus } = useQuery<
    customerIntakeByHouse,
    customerIntakeByHouseVariables
  >(GET_CUSTOMER_INTAKE_INFO, {
    variables: { houseId: activeHouseId },
    skip: !activeHouseId,
    notifyOnNetworkStatusChange: true,
    onCompleted: data =>
      data.customerIntakeByHouse && setCustomerIntakeInfo(data.customerIntakeByHouse),
  });

  // TODO: Would be nice to have the specific exit scenario here as well, should be returned with `customerIntakeInfo`

  const handleSetCustomerIntakeInfo = (info?: customerIntakeByHouse_customerIntakeByHouse) => {
    setCustomerIntakeInfo(info);
  };

  const contextValue: ContextValue = [
    customerIntakeInfo,
    handleSetCustomerIntakeInfo,
    loading,
    startPolling,
    stopPolling,
    networkStatus,
  ];

  return (
    <CustomerIntakeInfoContext.Provider value={contextValue}>
      {children}
    </CustomerIntakeInfoContext.Provider>
  );
};
