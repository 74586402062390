import { Steps } from '@energiebespaarders/symbols/icons/line';
import { dummy_dummy_content_StepperRecord } from '~/types/generated/cms/dummy';
import Stepper, { IStep, StepperProps } from '../Stepper';

interface Props {
  block: dummy_dummy_content_StepperRecord;
}

const DatoStepper: React.FC<Props> = ({ block }) => {
  const steps: IStep[] = block.steps.map(s => ({
    id: s.id,
    title: s.title!,
    text: s.text!,
    icon: Steps,
  }));

  const stepperProps: StepperProps = {
    title: block.title!,
    containerSize: 'lg',
    steps,
  };

  return <Stepper {...stepperProps} />;
};

export default DatoStepper;
