// libraries
import { Flex, Spinner } from '@energiebespaarders/symbols';
import React from 'react';
import BackgroundWrapper from './BackgroundWrapper';

export const PageLoading: React.FC<{
  /** override bg gradient? */
  bgColor?: string;
  fillViewportHeight?: boolean;
}> = ({ bgColor, fillViewportHeight = true }) => {
  return (
    <BackgroundWrapper bgColor={bgColor} fillViewportHeight={fillViewportHeight}>
      <Flex height="100%" flexWrap="wrap" alignItems="center" justifyContent="center" py={10}>
        <Spinner />
      </Flex>
    </BackgroundWrapper>
  );
};

export default PageLoading;
