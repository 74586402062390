import { Box } from '@energiebespaarders/symbols';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { getFaqItemsbyTopicId_allFaqItems } from '~/types/generated/cms/getFaqItemsbyTopicId';
import TypedStructuredText from '../DatoCms/TypedStructuredText';

const FaqItem: React.FC<{ item: getFaqItemsbyTopicId_allFaqItems }> = ({ item }) => {
  const isMobile = useIsMobile();

  return (
    <Box mb={10}>
      <h2 style={{ fontSize: 32, lineHeight: isMobile ? 1.2 : 1.5 }}>{item.question}</h2>

      <TypedStructuredText data={item.answer} />
    </Box>
  );
};
export default FaqItem;
