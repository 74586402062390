import { useQuery } from '@apollo/client';
import { Modal, TextLink, theme } from '@energiebespaarders/symbols';
import { useState } from 'react';
import styled from 'styled-components';
import {
  getOmniformHelpById,
  getOmniformHelpByIdVariables,
  getOmniformHelpById_omniformHelp,
} from '~/types/generated/cms/getOmniformHelpById';
import { getDatoClient } from 'src/datoCMS/datoCmsClient';
import { GET_OMNIFORM_HELP_BY_ID } from 'src/datoCMS/queries';
import TypedStructuredText, { CMSStructuredTextData } from '../DatoCms/TypedStructuredText';

const HideWhileLoading = styled.div<{ $hide: boolean }>`
  opacity: ${x => (x.$hide ? 0 : 1)};
  transition: opacity 0.15s;
`;

interface OmniformHelpModalProps {
  omniformHelp: getOmniformHelpById_omniformHelp;
  isOpen: boolean;
  onClose: () => void;
}

const OmniformHelpModal: React.FC<OmniformHelpModalProps> = ({ isOpen, onClose, omniformHelp }) => {
  const { title, helpContent } = omniformHelp;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} title={title!} contentPadding={4}>
      <TypedStructuredText data={helpContent as unknown as CMSStructuredTextData} />
    </Modal>
  );
};

interface OmniformQuestionHelperProps {
  datoCmsKey: string;
}

const OmniformQuestionHelper: React.FC<OmniformQuestionHelperProps> = ({ datoCmsKey }) => {
  const preview = false;
  const client = getDatoClient(preview);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const { data, loading } = useQuery<getOmniformHelpById, getOmniformHelpByIdVariables>(
    GET_OMNIFORM_HELP_BY_ID,
    {
      client,
      variables: { recordId: datoCmsKey },
      onError: error => console.error('Could not fetch help for record ID' + datoCmsKey, error),
    },
  );

  if (!data?.omniformHelp) return <br />; // Keep the whitespace for the help link while loading

  return (
    <HideWhileLoading $hide={loading}>
      <TextLink
        style={{
          whiteSpace: 'pre-line',
          textDecoration: 'underline',
          color: theme.colors.grayDark,
        }}
        onClick={() => setIsHelpModalOpen(true)}
      >
        {data?.omniformHelp?.title}
      </TextLink>
      <OmniformHelpModal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        omniformHelp={data.omniformHelp!}
      />
    </HideWhileLoading>
  );
};

export default OmniformQuestionHelper;
