import dayjs from 'dayjs';
import { dateStringFormatters } from '~/utils/constants';
import { resolve } from './flows/flow-condition';

function toString(value: unknown): string {
  if (typeof value === 'undefined') {
    return 'undefined';
  }
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    typeof value === 'bigint'
  ) {
    return value.toString();
  }

  if (value instanceof Date) {
    return dayjs(value).format(dateStringFormatters.full);
  }

  return JSON.stringify(value);
}

/**
 * Replaces all variables in a string with their values from a data object.
 * Variables must be in the form of `${variableName}` or `${object.path.to.variable}`.
 *
 * @param text the text in which to replace variables
 * @param data the data object from which to resolve the variables
 * @returns the text with all its variables resolved from the data object.
 */
export function resolveVariables(text: string, data: Record<string, unknown>): string {
  let resolvedText = text;

  // this regex matches any JS-valid variable name or object path within ${}, e.g. ${name}, ${age} ${address.city}
  const variableRegex = /\${([a-zA-Z_$][0-9a-zA-Z_$.]*)}/g;
  for (const variableRef of text.matchAll(variableRegex)) {
    const variablePath = variableRef[1];
    const variableValue = resolve(data, variablePath);
    resolvedText = resolvedText.replace(variableRef[0], toString(variableValue));
  }

  return resolvedText;
}
