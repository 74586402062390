import { Box, Card, Flex, Icon } from '@energiebespaarders/symbols';
import { Bold, Heading, Small } from '@energiebespaarders/symbols/helpers';
import { CaretLeft, CaretRight } from '@energiebespaarders/symbols/icons/line';
import { useState } from 'react';
import { Image } from 'react-datocms';
import styled from 'styled-components';
import { dummy_dummy_content_ComponentCustomerFeedbackRecord_customers } from '~/types/generated/cms/dummy';
import HybridLink from '../HybridLink';

interface Props {
  customers: ReadonlyArray<dummy_dummy_content_ComponentCustomerFeedbackRecord_customers>;
}

const StyledHybridLink = styled(HybridLink)`
  color: black;
  opacity: 50%;

  &:hover {
    opacity: 80%;
  }
`;

const StyledImage = styled(Image)`
  margin-right: 12px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
`;

const CustomerFeedbackCarousel: React.FC<Props> = ({ customers }) => {
  const [nextPage, setNextPage] = useState(false);
  // TODO: use amount of entered records in Dato OR require exactly 6 in Dato
  const renderedCustomers =
    customers.length > 3 && nextPage ? customers.slice(3, 6) : customers.slice(0, 3);

  return (
    <Flex flexDirection="column" alignItems="center">
      <Heading mb={4} style={{ textAlign: 'center' }} heading="h2">
        Dit zeggen onze klanten
      </Heading>
      <Flex flexWrap="wrap" flexDirection="row" mx={-2} mb={2}>
        {renderedCustomers.map(c => {
          return (
            <Flex key={c.id} width={[1, 1, 1 / 3]} px={2}>
              <Flex flexDirection="column" p={1}>
                <Card mb={3}>
                  <Flex alignItems="center" style={{ minHeight: 120, textAlign: 'center' }}>
                    <Small>
                      <p>{c.feedback}</p>
                    </Small>
                  </Flex>
                </Card>

                <Flex flexDirection="row">
                  <StyledImage data={c.image!.responsiveImage!} />
                  <Flex flexDirection="column">
                    <Box>
                      <Bold>{c.name}</Bold>
                    </Box>
                    <Box style={{ lineHeight: '24px', height: 70 }}>
                      <Small>{c.product}</Small>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      {customers.length > 3 && (
        <>
          <Flex justifyContent="center" mb={4}>
            <Icon
              onClick={() => setNextPage(!nextPage)}
              style={{ cursor: 'pointer' }}
              icon={CaretLeft}
            />
            <Icon
              onClick={() => setNextPage(!nextPage)}
              style={{ cursor: 'pointer' }}
              ml={4}
              icon={CaretRight}
            />
          </Flex>
        </>
      )}
      <Small>
        <StyledHybridLink to="/klanten">Bekijk meer verhalen van onze klanten →</StyledHybridLink>
      </Small>
    </Flex>
  );
};
export default CustomerFeedbackCarousel;
