import type { Condition, FlowCondition, Value, ValueCondition } from './flow-condition';

export abstract class BaseError extends Error {
  constructor(message: string) {
    super(message);
  }

  withCause<TCause extends Error>(cause: TCause): this & WithCause<TCause> {
    const message = this.message + ': ' + cause.message;
    const stack = cause.stack
      ? `${this.stack}\nCaused by\n${cause.stack}`
      : cause.message
      ? `${this.stack}\nCaused by: ${cause.message}`
      : this.stack;
    return Object.assign(this, { cause, message, stack });
  }

  withRequest<TRequest>(request: TRequest): this & WithRequest<TRequest> {
    return Object.assign(this, { request });
  }
}

/**
 * WithCause is a marker interface for an error that also specifies a cause, which is usually another error.
 * Every error that extends BaseError has a `withCause` method which returns itself & WithCause.
 */
export interface WithCause<TCause> extends Error {
  cause: TCause;
}

/**
 * WithRequest is a marker interface for an error that occurred as a result of sending a request.
 * Every error that extends BaseError has a `withRequest` method which returns itself & WithRequest.
 */
export interface WithRequest<TRequest> extends Error {
  request: TRequest;
}

// Warning! Everything below this line is a copy of `src/omniform/flows/errors.ts` from GQL
// ------------------------------------------------------------------------------------------------

export class InvalidFlowConditionError<T extends Record<string, any>> extends BaseError {
  constructor(message: string, condition: FlowCondition<T> | Condition<T>) {
    const msg = `${message}, but got: ${JSON.stringify(condition, null, 2)}`;
    super(msg);
  }
}

export class EvaluateFlowConditionError<T extends Record<string, any>> extends BaseError {
  constructor(key: string, condition: FlowCondition<T> | Condition<T>) {
    const msg = `cannot evaluate condition ${JSON.stringify(condition, null, 2)} on key "${key}"`;
    super(msg);
  }
}

export class EvaluateConditionError<T> extends BaseError {
  constructor(condition: ValueCondition<Value> | Condition<T>, value: T) {
    const msg = `cannot evaluate condition ${JSON.stringify(condition)} on value ${JSON.stringify(value)}`; // eslint-disable-line prettier/prettier
    super(msg);
  }
}

export class UnresolvedVariableError extends BaseError {
  constructor(data: Record<string, any>, path: string | number | symbol) {
    super(`cannot resolve variable "${String(path)}" on ${JSON.stringify(data)}`);
  }
}
