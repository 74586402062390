import { ReactElement, useMemo } from 'react';
import useOmniformAPI from '~/hooks/omniform/useOmniformAPI';
import { useMe } from '~/hooks/useMe';
import PageLoading from '../PageLoading';
import { OmniformContent, OmniformSharedProps } from './OmniformContent';

export type OmniformProps<TFlowData extends Record<string, any>> = {
  formKey: string;
  formRevision?: number;
  textVariables?: Record<string, unknown>;
} & OmniformSharedProps<TFlowData>;

/**
 * This Omniform component handles fetching an Omniform by form key + revision and starts a response as well.
 * Rendering of the actual content happens in {@link OmniformContent}
 */
export function Omniform<TFlowData extends Record<string, any>>({
  formKey,
  formRevision,
  initialFlowData,
  ...rest
}: OmniformProps<TFlowData>): ReactElement {
  const { me } = useMe();
  const { form, response, api } = useOmniformAPI<TFlowData>(formKey, formRevision);
  const { loading, error } = api.getOmniform();
  const { loading: responseLoading, error: responseError } = useMemo(() => {
    if (form && me && !response) {
      return api.startOmniformResponse(me, { ...initialFlowData, answers: undefined });
    }
    return { loading: false };
  }, [api, form, me, response, initialFlowData]);

  if (loading) return <PageLoading />;
  if (error || responseError || !form) {
    return <div>Er is iets misgegaan bij het ophalen van het formulier.</div>;
  }

  return !response || responseLoading || loading || error || !me ? (
    <PageLoading />
  ) : (
    <OmniformContent
      form={form}
      api={api}
      response={response}
      initialFlowData={initialFlowData}
      {...rest}
    />
  );
}

export default Omniform;
