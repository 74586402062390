import { dummy_dummy_content_ProductPageContentRecord } from '~/types/generated/cms/dummy';
import { BlockRendererOverrides } from './BlockRenderer';
import ContentLayout from './ContentLayout';

interface Props {
  block: dummy_dummy_content_ProductPageContentRecord;
  overrides?: BlockRendererOverrides;
}

const ProductPageContent: React.FC<Props> = ({ block, overrides }) => {
  return <ContentLayout block={block} overrides={overrides} />;
};

export default ProductPageContent;
