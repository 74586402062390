import { ImageModal } from '@energiebespaarders/symbols';
import { useIsTablet } from '@energiebespaarders/symbols/hooks';
import { useState } from 'react';
import { dummy_dummy_content_ImageBlockRecord } from '~/types/generated/cms/dummy';
import { DatoImageWrapper, StyledDatoImage } from '../FAQ/Topic';

interface Props {
  block: dummy_dummy_content_ImageBlockRecord;
}

const ImageBlock: React.FC<Props> = ({ block }) => {
  const isTablet = useIsTablet();
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <DatoImageWrapper
        $height={isTablet ? '150px' : '300px'}
        $rounded
        onClick={() => setOpen(true)}
      >
        <StyledDatoImage data={block.image!.responsiveImage!} />
      </DatoImageWrapper>

      {isOpen && (
        <ImageModal
          alt={block.image?.alt || block.image?.title || block.image?.filename}
          closeModal={() => setOpen(false)}
          src={block.image!.url || block.image!.responsiveImage?.src}
          isOpen={isOpen}
          // Needs type-cast, will be supported in next Symbols version
          {...({ srcSet: block.image!.responsiveImage?.srcSet } as any)}
        />
      )}
    </>
  );
};

export default ImageBlock;
