import { ApolloError, ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
  submitOmniformResponse,
  submitOmniformResponseVariables,
} from '~/types/generated/submitOmniformResponse';
import { getOmniform, getOmniformVariables } from '../../types/generated/getOmniform';
import { OmniformAnswerInput } from '../../types/graphql-global-types';
import { decodeOmniform, decodeOmniformResponse, decodeUserType } from './decoders';
import { OmniformNotFoundError, OmniformResponseNotFoundError } from './errors';
import { OmniformFlow } from './flows/omniform-flow';
import {
  GET_OMNIFORM,
  SAVE_OMNIFORM_RESPONSE,
  START_OMNIFORM_RESPONSE,
  SUBMIT_OMNIFORM_RESPONSE,
} from './gql';
import type {
  startOmniformResponse,
  startOmniformResponseVariables,
} from '../../types/generated/startOmniformResponse';
import type {
  saveOmniformResponse,
  saveOmniformResponseVariables,
} from '../../types/generated/saveOmniformResponse';
import type { Omniform, OmniformResponder, OmniformResponse } from './types';

export interface OmniformAPI<TFlowData extends Record<string, any>> {
  getOmniform(): {
    form?: Omniform<OmniformFlow<TFlowData>>;
    loading: boolean;
    error?: ApolloError;
  };

  refetchOmniform(): Promise<ApolloQueryResult<getOmniform>>;

  startOmniformResponse(
    responder: OmniformResponder,
    context: Record<string, unknown>,
  ): {
    response?: OmniformResponse;
    loading: boolean;
    error?: ApolloError;
  };

  saveOmniformResponse(
    answers: OmniformAnswerInput[],
    lastSeenNode?: string,
  ): {
    response?: OmniformResponse;
    loading: boolean;
    error?: ApolloError;
  };

  submitOmniformResponse(answers: OmniformAnswerInput[]): {
    response?: OmniformResponse;
    loading: boolean;
    error?: ApolloError;
  };
}

// -------------------------------------------------------------------------------------------------

export default function useOmniformAPI<TFlowData extends Record<string, any>>(
  formKey: string,
  formRevision?: number,
): {
  form: Omniform<OmniformFlow<TFlowData>> | undefined;
  response: OmniformResponse | undefined;
  api: OmniformAPI<TFlowData>;
} {
  const getOmniformResult = useQuery<getOmniform, getOmniformVariables>(GET_OMNIFORM, {
    variables: { key: formKey, revision: formRevision },
  });
  if (getOmniformResult.error)
    console.error('Omniform - Error retrieving form:', getOmniformResult.error);

  const [startOmniformResponse, startOmniformResponseResult] = useMutation<
    startOmniformResponse,
    startOmniformResponseVariables
  >(START_OMNIFORM_RESPONSE, {
    fetchPolicy: 'network-only',
  });

  const [saveOmniformResponse, saveOmniformResponseResult] = useMutation<
    saveOmniformResponse,
    saveOmniformResponseVariables
  >(SAVE_OMNIFORM_RESPONSE);

  const [submitOmniformResponse, submitOmniformResponseResult] = useMutation<
    submitOmniformResponse,
    submitOmniformResponseVariables
  >(SUBMIT_OMNIFORM_RESPONSE);

  const form = useMemo(
    () => decodeOmniform<TFlowData>(getOmniformResult.data?.getOmniform),
    [getOmniformResult.data?.getOmniform],
  );
  const response = useMemo(
    () =>
      decodeOmniformResponse(
        startOmniformResponseResult.data?.startOmniformResponse ||
          saveOmniformResponseResult.data?.saveOmniformResponse ||
          submitOmniformResponseResult.data?.submitOmniformResponse,
      ),
    [
      startOmniformResponseResult.data?.startOmniformResponse,
      saveOmniformResponseResult.data?.saveOmniformResponse,
      submitOmniformResponseResult.data?.submitOmniformResponse,
    ],
  );

  const api: OmniformAPI<TFlowData> = {
    getOmniform() {
      const { loading, error } = getOmniformResult;
      return { form, loading, error };
    },

    refetchOmniform() {
      return getOmniformResult.refetch();
    },

    startOmniformResponse(responder: OmniformResponder, context: Record<string, any> = {}) {
      if (!form) throw new OmniformNotFoundError(formKey, formRevision);

      const { data, loading, error } = startOmniformResponseResult;
      if (!loading && !error) {
        startOmniformResponse({
          variables: {
            key: form.key,
            responderId: responder.id,
            responderType: decodeUserType(responder.__typename),
            context,
          },
        }).catch(err => console.error('Omniform - Error starting response:', err));
      }

      return { response: decodeOmniformResponse(data?.startOmniformResponse), loading, error };
    },

    saveOmniformResponse(answers: OmniformAnswerInput[], lastSeenNode?: string) {
      if (!form) throw new OmniformNotFoundError(formKey, formRevision);
      if (!response) throw new OmniformResponseNotFoundError(formKey, formRevision);

      const { data, loading, error } = saveOmniformResponseResult;
      if (!loading && !error) {
        saveOmniformResponse({
          variables: { responseId: response.id, answers, lastSeenNode },
        }).catch(err => console.error('Omniform - Error saving response:', err));
      }
      return { response: decodeOmniformResponse(data?.saveOmniformResponse), loading, error };
    },

    submitOmniformResponse(answers: OmniformAnswerInput[]) {
      if (!form) throw new OmniformNotFoundError(formKey, formRevision);
      if (!response) throw new OmniformResponseNotFoundError(formKey, formRevision);

      const { data, loading, error } = submitOmniformResponseResult;
      if (!loading) {
        // NOTE: checking for errors here blocks any chance of retrying the submission.
        submitOmniformResponse({
          variables: { responseId: response.id, answers },
        }).catch(err => console.error('Omniform - Error submitting response:', err));
      }
      return { response: decodeOmniformResponse(data?.submitOmniformResponse), loading, error };
    },
  };

  return { form, response, api };
}
