import { useIsMobile } from '@energiebespaarders/hooks';
import { Box, Flex, Image, theme } from '@energiebespaarders/symbols';
import { Book, Small } from '@energiebespaarders/symbols/helpers';
import { useCallback } from 'react';
import styled from 'styled-components';
import { AnswerType, InputOptionValue, QuestionAnswerType } from '~/hooks/omniform/types';
import { OmniformInputBaseProps } from '../Omniform/types';
import { QKeyGeneral, QKeyRoof } from './types';

export const StyledOption = styled(Box)<{ $isSelected: boolean }>`
  text-align: center;
  cursor: pointer;
  width: 100%;
  border: 1px solid ${x => (x.$isSelected ? x.theme.colors.green : x.theme.colors.grayLight)};
  border-radius: 6px;
  background: ${x => (x.$isSelected ? x.theme.colors.greenSlate : 'white')};
  word-break: initial;

  &:hover {
    border-color: ${x => (x.$isSelected ? x.theme.colors.green : x.theme.colors.greenDark)};
  }
`;

const HouseTypeImages: Record<number, string> = {
  1: 'https://www.datocms-assets.com/50550/1712841964-house_detached.png',
  2: 'https://www.datocms-assets.com/50550/1712841968-house_duplex.png',
  3: 'https://www.datocms-assets.com/50550/1712841960-house_corner.png',
  4: 'https://www.datocms-assets.com/50550/1712841973-house_row.png',
  5: 'https://www.datocms-assets.com/50550/1712841956-house_apartment.png',
};

const HouseTypeInput = ({
  question,
  onAnswer,
}: OmniformInputBaseProps<QuestionAnswerType.Number>) => {
  const isMobile = useIsMobile();

  if (question.key !== QKeyGeneral.HouseType) {
    console.error('Wrong input component for this question');
    return null;
  }

  const imageSize = isMobile ? 90 : 120;
  return (
    <Flex mx="-3px" flexWrap="wrap">
      {(question.options || []).map((option, _, self) => (
        <Box
          key={`houseType-${option.value}`}
          width={[1 / 2, 1 / 2, 1 / 4]}
          onClick={() =>
            onAnswer(
              question.key,
              question.answerType as QuestionAnswerType.Number,
              option.value as number,
            )
          }
          style={{ display: 'flex', flexGrow: 1 }}
          px="3px"
          pb={self.indexOf(option) < self.length - 1 ? 1 : 0}
        >
          <StyledOption p={2} $isSelected={question.answer === option.value}>
            <Image
              src={`${HouseTypeImages[option.value as number]}?w=${imageSize}&fit=scale`}
              alt={option.label}
              mb={1}
            />
            <br />
            <Small>
              <Book>{option.label}</Book>
            </Small>
          </StyledOption>
        </Box>
      ))}
    </Flex>
  );
};

const HouseRoofingMaterialImages: Record<string, string> = {
  tiles: 'https://www.datocms-assets.com/50550/1712831217-tiles.png',
  zinc: 'https://www.datocms-assets.com/50550/1712831211-zinc.png',
  thatch: 'https://www.datocms-assets.com/50550/1712831113-thatch.png',
  slates: 'https://www.datocms-assets.com/50550/1712831108-slates.png',
  bitumen: 'https://www.datocms-assets.com/50550/1712831096-bitumen.png',
  corrugatedSheet: 'https://www.datocms-assets.com/50550/1712831100-corrugatedsheet.png',
  sedum: 'https://www.datocms-assets.com/50550/1712831104-sedum.png',
};

const HouseRoofingMaterialInput = ({
  question,
  onAnswer,
}: OmniformInputBaseProps<QuestionAnswerType.StringArray>) => {
  const isMobile = useIsMobile();
  const currentAnswer = question.answer as string[];
  const handleChange = useCallback(
    (value: InputOptionValue<QuestionAnswerType.String>) => {
      const nextCheckedValues = [...currentAnswer] as AnswerType<QuestionAnswerType.StringArray>;
      if (currentAnswer.includes(value)) {
        nextCheckedValues.splice(currentAnswer.indexOf(value), 1);
      } else {
        nextCheckedValues.push(value);
      }
      onAnswer(
        question.key,
        question.answerType as QuestionAnswerType.StringArray,
        nextCheckedValues,
        !question.isOptional && nextCheckedValues.length === 0,
      );
    },
    [currentAnswer, onAnswer, question.answerType, question.isOptional, question.key],
  );

  if (question.key !== QKeyRoof.HouseRoofingMaterial) {
    console.error('Wrong input component for this question');
    return null;
  }

  const imageSize = isMobile ? 60 : 100;
  return (
    <Flex mx="-3px" flexWrap="wrap">
      {(question.options || []).map((option, _, self) => (
        <Box
          key={`roofType-${option.value}`}
          width={[1 / 3, 1 / 3, 1 / self.length]}
          onClick={() => handleChange(option.value as string)}
          style={{ display: 'flex' }}
          px="3px"
          pb={self.indexOf(option) < self.length - 1 ? 1 : 0}
        >
          <StyledOption
            p={isMobile ? 1 : 2}
            $isSelected={currentAnswer.includes(option.value as string)}
          >
            {HouseRoofingMaterialImages[option.value as string] ? (
              <Image
                src={`${
                  HouseRoofingMaterialImages[option.value as string]
                }?w=${imageSize}&h=${imageSize}&fit=crop`}
                alt={option.label}
                radius={`${imageSize}px`}
                mb={1}
              />
            ) : (
              <Box
                width={imageSize}
                height={imageSize}
                style={{
                  background: theme.colors.mint,
                  display: 'inline-block',
                  borderRadius: imageSize,
                }}
              />
            )}
            <br />
            <Small>
              <Book>{option.label}</Book>
            </Small>
          </StyledOption>
        </Box>
      ))}
    </Flex>
  );
};

const CustomOmniformInputs: Partial<
  Record<string, (props: OmniformInputBaseProps<any>) => JSX.Element | null>
> = {
  [QKeyGeneral.HouseType]: HouseTypeInput,
  [QKeyRoof.HouseRoofingMaterial]: HouseRoofingMaterialInput,
};

export default CustomOmniformInputs;
