import React from 'react';
import { IntakeTextVariables } from './types';

// type ContextValue = [IntakeTextVariables, (textVariables?: IntakeTextVariables) => void];
export const IntakeTextVariablesContext = React.createContext<IntakeTextVariables>({});

export const IntakeTextVariablesProvider: React.FC<{ textVariables: IntakeTextVariables }> = ({
  children,
  textVariables,
}) => (
  <IntakeTextVariablesContext.Provider value={textVariables}>
    {children}
  </IntakeTextVariablesContext.Provider>
);
