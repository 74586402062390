import {
  dummy_dummy_content_LongReadQuickLinkRecord,
  dummy_dummy_content_LongReadQuickLinkRecord_longReads,
} from '~/types/generated/cms/dummy';
import { IQuickLinkItem } from '../DatoCms/QuickLinkItem';
import QuickLinks from '../DatoCms/QuickLinks';

interface Props {
  block: dummy_dummy_content_LongReadQuickLinkRecord;
}

const constructLongReadSlug = (block: dummy_dummy_content_LongReadQuickLinkRecord_longReads) => {
  const parent = block.product?.parentSolution?.slug;
  const product = block.product?.slug;

  return `/woning-verduurzamen/product${parent ? `/${parent}/` : '/'}${product}/${block.slug}`;
};

const LongReadQuickLink: React.FC<Props> = ({ block }) => {
  const longReads: IQuickLinkItem[] = block.longReads.map(b => ({
    id: b.id,
    responsiveImageData: b.image!.responsiveImage!,
    heading: b.title!,
    text: b.description!,
    to: constructLongReadSlug(b),
  }));

  return <QuickLinks title={block.title!} items={longReads} />;
};

export default LongReadQuickLink;
