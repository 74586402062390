import { delimit } from '@energiebespaarders/constants';
import { useIsMobile } from '@energiebespaarders/hooks';
import { Box, Flex, Icon, theme } from '@energiebespaarders/symbols';
import { Check, Euro } from '@energiebespaarders/symbols/icons/line';
import { Lightning, ThumbsUp } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Image } from 'react-datocms';
import styled from 'styled-components';
import useMediaQuery from '~/hooks/useMediaQuery';
import { dummy_dummy_content_TestimonialBlockRecord } from '~/types/generated/cms/dummy';
import BlockContainer from '../DatoCms/BlockContainer';
import ListWithIcon, { ListItem } from '../DatoCms/ListWithicon';
import SimplePagination from '../DatoCms/SimplePagination';
import DatoButton from './DatoButton';

interface Props {
  block: dummy_dummy_content_TestimonialBlockRecord;
}

const LayoutContainer = styled(Flex)`
  em {
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    font-size: ${theme.type.scale[3]};
  }
`;

const TestimonialContent = styled(Flex)`
  max-width: 600px;
`;

const MobileImage = styled(Image)`
  width: 100%;
  height: 350px;
  border-radius: 10px;
  picture img {
    object-fit: cover;
  }
`;

const DesktopImage = styled(Image)`
  border-radius: 10px;
  margin-top: -60px;
  width: 100%;
  height: 550px;

  picture img {
    object-fit: cover;
  }
`;

const Testimonial: React.FC<Props> = ({ block }) => {
  const [index, setIndex] = useState(1);

  const isSmallMobile = useMediaQuery('sm');
  const isMobile = useIsMobile();

  const currentTestimonial = block.testimonials[index - 1];
  const testimonialDetails: ListItem[] = [
    {
      key: 'kwhSaved',
      text: `${delimit(currentTestimonial.kwhSaved, 0)} kWh bespaard`,
      icon: <Icon fill="grayDarker" icon={Lightning} />,
    },
    {
      key: 'moneySaved',
      text: `€${delimit(currentTestimonial.moneySaved, 0)} bespaard`,
      icon: <Icon icon={Euro} />,
    },
    {
      key: 'rating',
      text: `Tevredenheidscijfer ${currentTestimonial.rating}`,
      icon: <Icon icon={ThumbsUp} fill="grayDarker" />,
    },
  ];

  const testimonialSolutions: ListItem[] = currentTestimonial.testimonialSolutions.map(s => ({
    key: s.id,
    icon: <Icon icon={Check} />,
    text: s.name!,
  }));

  const handleSetIndex = (direction: '+' | '-') => {
    if (direction === '+') {
      index === block.testimonials.length ? setIndex(1) : setIndex(index + 1);
    }

    if (direction === '-') {
      index === 1 ? setIndex(block.testimonials.length) : setIndex(index - 1);
    }
  };

  return (
    <BlockContainer backgroundColor={theme.colors.grayLight} mt={isSmallMobile ? 0 : '100px'}>
      <LayoutContainer>
        {!isSmallMobile && (
          <Box style={{ position: 'relative', width: 400 }}>
            <Box style={{ position: 'absolute', bottom: 0 }}>
              <DesktopImage data={currentTestimonial.image!.responsiveImage!} />
            </Box>
          </Box>
        )}

        <TestimonialContent
          justifyContent="flex-end"
          flexDirection="column"
          ml={isSmallMobile ? 0 : isMobile ? 4 : 8}
          width={1}
        >
          {isSmallMobile && (
            <Box mb={4}>
              <MobileImage data={currentTestimonial.image!.responsiveImage!} objectFit="cover" />
            </Box>
          )}

          {block.testimonials.length > 1 && (
            <SimplePagination
              handleNext={() => handleSetIndex('+')}
              handlePrev={() => handleSetIndex('-')}
              currentPage={index}
              totalPages={block.testimonials.length}
              invert={isSmallMobile}
            />
          )}

          <Box mb={4}>
            <em>{currentTestimonial.quote}</em>
          </Box>

          <Box mb={4}>
            <Box>
              {currentTestimonial.name} uit {currentTestimonial.city}
            </Box>
            <Box>
              Energiebespaarders sinds {dayjs(currentTestimonial.date).format('DD MMMM YYYY')}
            </Box>
          </Box>

          <Flex flexWrap="wrap" justifyContent="space-between">
            <ListWithIcon title={'Details'} items={testimonialDetails} />
            <ListWithIcon title={'Afgenomen producten'} items={testimonialSolutions} />
          </Flex>

          <Box>
            <DatoButton block={currentTestimonial.button[0]} />
          </Box>
        </TestimonialContent>
      </LayoutContainer>
    </BlockContainer>
  );
};

export default Testimonial;
